@import '../../../assets/scss/variables.scss';

.guest-modal{
    background: #00000080;  
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    height: 100vh;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-family: $inter-font;
    .guestmodal-inner {
        background: $white;
        padding: 1.5rem;
        border-radius: 0.8rem;
        display: flex;
        width: 100%;
        max-width: 90vw;
        margin-bottom: 5rem;
        .left-icon {
            margin-right: 1rem;
            width: 50px;
            svg {
                width: 48px;
            }
        }
        h2{
            font-weight: 600;
            color: #243B53;
            margin-bottom: 0.4rem;
            font-size: 1.5rem;
            span {
                font-weight: 700;
            }
        }
        p {
            font-size: 14px;
            color: #486581;
            font-weight: 500;
            margin-bottom: 1.3rem;
        }
        button.button.ui {
            border: 1px solid #2166EE !important;
            color: #2166EE !important;
            border-radius: 6px;
            box-shadow: none !important;
            font-size: 14px;
            font-weight: 600;
            font-family: $inter-font;
            height: 42px;
        }
    }
} 
