@import '../../assets/scss/variables.scss';

.event-list-wrapper{
    padding-top: 20px;
    font-family: $inter-font;
    .pointing.secondary {
        &.menu {
            border-bottom:1px solid #DBE2EB;
            overflow-x: auto;
            align-items: flex-end;
            overflow-y: hidden;
            &::-webkit-scrollbar {
                width: 0;
                height: 0;
            }
            .item {
                color: #334E68;
                font-weight: 500;
                font-size: 16px !important;
                font-family: $inter-font;
                padding: 12px 0 !important;
                margin-right: 2.5rem !important;
                @media screen and (max-width:767px) {
                    margin-right: 1rem !important;
                    font-size: 15px !important;
                }
                @media screen and (max-width:640px) {
                    font-size: 14px !important;
                }
                &.active {
                    font-weight: 600 !important;
                    font-size: 16px !important;
                    color: #2166EE !important;
                    border-color: #2166EE;
                    @media screen and (max-width:767px) {
                        font-size: 15px !important;
                    }
                    @media screen and (max-width:640px) {
                        font-size: 14px !important;
                    }
                    &:hover {
                        border-color: #2166EE;
                    }
                }
            }
        }
    }
    .tab {
        border: 0;
        padding: 0.3rem 0 0 0;
        box-shadow: none;
    }
    .event-list-upper{
        margin-bottom: 1.3rem;
        .back-link{
            color: #1A1642;
            font-size: 17px;
            font-weight: 700;
            .icon{
                fill: #1A1642;
                margin-right: 4px;
                transition: all ease-in 0.1s;
            }
            &:hover {
                .icon {
                    transform: translateX(-3px);
                }
            }
        }
    }
    .heading-text {
        font-size: 16px;
        font-weight: 500;
        color: #334E68;
        font-family: $inter-font;
        margin-bottom: 1.5rem;
        margin-top: 0.8rem;
    }
    .event-lists{
        list-style-type: none;
        padding-left: 0;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;
        @media screen and (max-width: 991px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media screen and (max-width: 640px) {
            grid-template-columns: repeat(1, 1fr);
        }
        li{
            a{
                .card{
                    border-radius: 14px;
                    background: $white;
                    padding: 0;
                    height: 100%;
                    border: 1px solid #DBE2EB;
                    .event-upper{
                        background: #E9EEF3;
                        min-height: 168px;
                        max-height: 168px;
                        border-radius: 14px 14px 0 0;
                        overflow: hidden;
                        display: flex;
                        align-items: center;
                        img{
                            max-height: 100%;
                            object-fit: cover;
                        }
                    }
                    .event-bottom{
                        padding: 15px;
                        border-width: 0 1px 1px 1px;
                        border-radius: 0 0 14px 14px;
                        .tag-list {
                            margin-bottom: 12px;
                            a {
                                background: #F3F7FF;
                                border-radius: 6px;
                                padding: 4px 6px;
                                color: #5A8FF8;
                                font-weight: 600;
                                font-size: 12px;
                            }
                        }
                        ul {
                            padding-left: 0;
                            li{
                                color: #486581;
                                font-size: 14px;
                                font-weight: 500;
                                margin-bottom: 7px;
                                display: flex;
                                align-items: center;
                                &:last-child{
                                    margin-bottom: 0;
                                }
                                .icon{
                                    margin-right: 10px;
                                    fill: #486581;
                                    width: 16px;
                                    height: 16px;
                                }
                            }
                        }
                        p{
                            color: #243B53;
                            font-size: 17px;
                            font-weight: 700;
                            margin-bottom: 1.4rem;
                            min-height: 46px;
                        }
                    }
                }
                &.event-link-tag {
                    display: block;
                    width: 100%;
                }
            }
        }
    }   
    .more-event{
        padding: 2.5rem 0 1rem 0;
        button{
            &.ui.button{
                color: #2166EE !important;
                border:1px solid #2166EE !important;
                box-shadow: none !important;
                font-weight: 600;
                height: 50px;
                border-radius: 6px;
                padding: 0 20px;
                font-family: $inter-font;
            }
        }
    } 
}