@import '../../../../assets/scss//variables.scss';

.overlay-bar-create {
    position: fixed;
    height: calc(100vh - 57px);
    width: 100vw;
    right: 0;
    top: 0;
    z-index: 9;
    background: #00000080;
    transition: all ease-in 0.2s;
}

.createpopup-bottom {
    position: absolute;
    bottom: 65px;
    z-index: 10;
    margin: auto;
    text-align: center;
    width: 100%;
    .createpopup-bottom-inner {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        background: $white;
        padding: 1rem;
        border-radius: 14px;
        max-width: 360px;
        width: 90%;
        margin: auto;
        button {
            color: #243B53;
            background: none;
            border: 0;
            text-align: center;
            font-family: $inter-font;
            font-weight: 600;
            .icon {
                fill: #243B53;
                display: block;
                width: 25px;
                margin: auto;
                margin-bottom: 10px;
            }
        }
    }
}