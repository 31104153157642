@import '../../assets/scss/variables.scss';

.profile-mainwrapper {
    background: #fbfbf8;
    min-height: calc(100vh - 60px);
    margin-top: 60px;
    .container {
        padding-left: 0;
        padding-right: 0;
        max-width: 1212px !important;        
    }
    .profile-mainwrapper-inner {
        display: flex;
        .leftbar {
            max-width: 252px;
            width: 100% !important;
            padding: 0 0 30px !important;
            position: relative;
            z-index: 1;
            @media only screen and (max-width:767px){
                display: none;
            }
            .left-content{
                position: fixed;
                max-width: calc(252px - 10px);
                width: 100% !important;
            }
            .user-name {
                display: flex;
                font-size: 13px;
                line-height: 1.4rem;
                align-items: center;
                img {
                    width: 42px;
                    height: 42px;
                    margin-right: 10px;
                }
            }
            .listsidebar-menu {
                padding-left: 50px;
                a {
                    color: black;
                    padding: 6px 10px;
                }
            }
        }
        .middle-section {
            background: #fff;
            width: 100%;
            min-height: calc(100vh - 70px);
            border-left: 1px solid #ccc;
            border-right: 1px solid #ccc;
            border-top: 1px solid #ccc;
            padding: 2rem;
            margin-top: 10px;
            max-width: 665px;
        }
        .rightbar {
            padding-left: 10px;
            max-width: 252px;
            width: 100% !important;
            position: relative;
            z-index: 1;
            @media only screen and (max-width:767px){
                display: none;
            }
            .right-content{
                margin-top: 10px;
                position: fixed;
                max-width: calc(252px - 10px);
                width: 100% !important;
            }
            .card {
                .content {
                    .header {
                        font-size: 1.1rem;
                    }
                    .feed {
                        .event {
                            .content {
                                margin-top: 0;
                                h3 {
                                    font-weight: 600;
                                    color: black;
                                    margin-bottom: 0;
                                }
                                .summary {
                                    font-weight: normal;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.industry-header {
    h1 {
        color: black;
        font-weight: 600;
        font-size: 2.5rem;
        margin-bottom: 20px;
    }
    p {
        font-size: 1.2rem;
    }
}

.industry-select {
    padding-top: 30px;
    .ui.select-dropdown {
        background-color: #fff !important;
        background-image: none !important;
        border: 1px solid #ccc !important;
        min-height: 45px !important;
        line-height: 45px !important;
        padding: 0 10px !important;
        font-size: 1rem !important;
        border-radius: 4px !important;
        width: 100%;
        height: auto;
        .default {
            .text {
                line-height: 45px;
                &:after {
                    top: 7px;
                    right: 24px;
                }
            }
        }
    }
}

.networkfeed-productslist {
    display: flex;
    flex-flow: wrap;
    grid-template-columns: repeat(5, 1fr);
    list-style-type: none;
    padding-left: 0;
    grid-gap: 10px;
    li {
        cursor: pointer;
        border: 1px solid #ccc;
        text-align: center;
        height: 32px;
        line-height: 32px;
        border-radius: 20px;
        padding-left: 8px;
        padding-right: 8px;
        color: #ccc;
        font-size: 12px;
        text-transform: uppercase;
        transition: all ease-in-out 0.1s;
        &:hover {
            border-color: $primary-color;
            color: $primary-color;
        }
    }
    li.selected {
        border-color: $primary-color;
        color: $primary-color;
    }
}

.feedmain-view{
    .ui{
        &.stuck-container{
            .custom-tab {
                .secondary.menu{
                    position: fixed;
                    top: 40px;
                    background: #fff;
                    width: 100%;
                    z-index: 2;                
                }
            }
        }
    }
}

article {
    .article-body {
        .ui.horizontal.list{
            display: block;        
        }
    }
}

.comments-box {
    &.comments-box-feed{
        padding-bottom: 0 !important;
        max-width: 100% !important;
        width: 100%;
        padding: 22px 15px 0 15px !important;
        .comment{
            display: flex;
            width: 100%;
            .avatar{
                width: 50px;
                height: 50px;
                min-height: 50px;
                min-width: 50px;
                border-radius: 50px;
                margin-right: 12px !important;
                background: #BCEDFD;
                color: #1E6F8A;
                border: 0 !important;
                img {
                    width: 100%;
                    height: 100%;
                    min-height: 100%;
                    object-fit: cover;
                }
            }
            .comment-initials-img {
                width: 50px;
                height: 50px;
                min-height: 50px;
                min-width: 50px;
                border-radius: 50px;
                margin-right: 12px !important;
                background: #BCEDFD;
                color: #1E6F8A;
                border: 0 !important;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 20px;
                font-weight: 500;
            }
            .content{
                width: 100%;
                margin-left: 0 !important;
                .fields{
                    padding-right: 0;
                    flex-direction: column;
                    .ui.input{
                        input{
                            border: 1px solid #DBE2EB !important;
                            border-radius: 4px !important;
                            height: 50px;
                            background: #F6F8FC;
                            border-radius: 8px !important;
                            font-weight: 500;
                            font-family: $inter-font;
                            &::placeholder {
                                font-weight: 500;
                                color: #829AB1;
                                opacity: 1;
                            }
                            &::-webkit-placeholder {
                                font-weight: 500;
                                color: #829AB1;
                                opacity: 1;
                            }
                        }
                    }
                    .field{
                        margin-right: 0 !important;
                        padding-right: 0 !important;
                        text-align: right;
                        .primary.button{
                            margin-top: 15px;
                            height: 30px;
                            line-height: 30px;
                            border-radius: 3px !important;
                            padding: 0 18px !important;
                        }
                    }
                }
            }
        }
    }
}

.comment{
    &.commented-list{
        padding-top: 0 !important;
        margin-bottom: 42px !important;
        &:last-child {
            margin-bottom: 25px !important;
        }
        .comment-initials-img{
            font-size: $font-size-base;
            font-weight: 500;
        }
        .comment-rightdtl{
            padding: 12px;
            width: 100%;
            border: 1px solid #DBE2EB !important;
            background: #F6F8FC;
            border-radius: 8px !important;
            font-family: $inter-font;
            position: relative;
            .comment-likes {
                position: absolute;
                bottom: -26px;
                left: 12px;
                a {
                    display: flex;
                    align-items: center;
                    line-height: 1;
                    font-size: 15px;
                    font-weight: 600;
                    color: #486581;
                    .icon {
                        margin: 0 4px 0 6px;
                    }
                    .counts {
                        font-size: 12px;
                        font-weight: 500;
                    }
                }
            }
            .commenter-name{
                font-weight: 600;
                font-size: 16px;
                font-family: $inter-font;
                color: #243B53;
            }
            .dasignation {
                color: #829AB1;
                font-size: 14px;
                font-weight: 500;
                margin-top: 3px;
                font-family: $inter-font;
            }
            .comment-datetime{
                font-size: $font-size-sm + 2;
                position: absolute;
                right: 15px;
                top: 15px;
                color: #486581;
                font-family: $inter-font;
                @media screen and (max-width:767px) {
                    position: initial;
                    margin-top: 8px;
                }
            }
            .comment-text{
                font-size: $font-size-base;
                margin-top: 4px;
                font-family: $inter-font;
                color: #486581;
                font-size: 14px;
                font-weight: 500;
                margin-top: 15px;
            }
        }
    }
}

.loading-loader{
    width: 100%;
}
.event-head-right {

    .toogle-icon{
        width: 15px;
        text-align: center;
        .ellipsis-action{
            width: 4px;
            fill: #334E68;
            &:hover{
                fill: rgba(#334E68, 0.7);
            }
        }
    }

.feed-editdelete-action{
    
            .item {
                color: #334E68 !important;
                font-size: 14px !important;
                font-weight: 600 !important;
                border-bottom: 1px solid #DBE2EB !important;
                padding: 14px !important;
                display: flex;
                align-items: center;
                width: 100%;

                .text {
                    display: flex;
                    align-items: center;
                }
    
                &:last-child {
                    border-bottom: 0;
                }

                .icon {
                    margin-right: 5px;
                }

                &.dropDownThreeDots {
                    &:hover {
                        color: $primary-color !important;
                        .icon {
                            fill: $primary-color;
                        }
                    }                
                }
    
            }
    }
}


.ui{
    &.button {
        &.tagremove-btn{
            width: 32px;
            height: 32px;
            padding: 0;
        }
    }
}

.ui.popup{
    &.sharepost-popup-wrapper{
        width: 392px;
        max-width: 100%;
        background: #FFFFFF;
        border: 1px solid #D9D9D9;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        padding: 15px;
        @media screen and (max-width:480px) {
            max-width: 300px;
        }
        .sharepost-popup{
            .share-header{
                display: flex;
                align-items: center;
                justify-content: space-between;
                h2{
                    margin-bottom: 0;
                    color: #1A1642;
                    font-weight: 700;
                    font-size: 19px;
                }
                a{
                    background: #FDF1F1;
                    width: 22px;
                    height: 22px;
                    border-radius: 22px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: all ease-in 0.1s;
                    i {
                        color: #C9545A;
                        font-weight: bold;
                        font-size: 9px;
                        transition: all ease-in 0.1s;
                    }
                    &:hover {
                        background: #C9545A;
                        i{
                            color: $white;
                        }
                    }
                }
            }
            .share-body{
                .post-link-row{
                    margin-top: 16px;
                    div.input{  
                        background: #FFFFFF;
                        border: 1px solid #DBE2EB;
                        border-radius: 6px;
                        width: 100%;
                        height: 42px;
                        .label{
                            margin: 0 !important;
                            border: 0;
                            text-align: center;
                            .icon {
                                width: 15px !important;
                                fill: #334E68;
                                margin: auto;
                            }
                            &.copy-btn{
                                background: #F6F8FC;
                                border-radius: 5px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                color: #334E68;
                                font-size: 14px;
                                cursor: pointer;
                                padding: 0 16px;
                                &:hover {
                                    background: #eaf1ff;
                                }
                                .icon {
                                    margin-left: 8px;
                                    margin-right: 0;
                                }
                            }
                        }
                        input {
                            border: 0;
                            color: #A3B2C6;
                            font-size: 14px;
                            padding-left: 2px;
                        }
                    }
                }
                .social-btn-row{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 16px;
                    .ui.button{
                        border-radius: 6px;
                        height: 40px;
                        color: $white;
                        display: flex;
                        align-items: center;
                        width: 100%;
                        margin-right: 10px;
                        font-weight: 600;
                        font-size: 14px;
                        @media screen and (max-width:480px) {
                            padding: 0 12px;
                            font-size: 13px;
                            margin-right: 6px;
                        }
                        &:last-child {
                            margin-right: 0;
                        }
                        .icon {
                            fill: $white;
                            opacity: 1;
                            margin-right: 7px;
                            width: 18px;
                            height: 18px;
                            @media screen and (max-width:480px) {
                                width: 14px;
                                height: 14px;
                                margin-right: 5px;
                            }
                        }
                        &.fb-btn{
                            background: #1977F3;
                        }
                        &.twitter-btn{
                            background: #1DA1F3;
                        }
                        &.linkedin-btn{
                            background: #0274B3;
                        }
                    }
                }
            }
        }
    }
}

.video-progress-wrapper{
    padding: 5px 18px 10px 18px;
    .title{
        font-size: 14px;
        color: #1A1642;
        font-weight: 600;
    }
    .progress{
        height: 8px;
        border-radius: 8px;
        margin-bottom: 8px;
        margin-top: 6px;
        background: #E9EEF3;
        .bar{
            font-size: 0;
            height: 8px;
            background: #2699FB;
        }
    }
    p{
        color: #707070;
        font-size: 12px;
    }
}



.modal.ui.tagscp-modal {
    font-family: $inter-font;
    border-radius: 12px !important;
    @media screen and (max-width:576px) {
      min-height: 60vh;
      margin: 0 !important;
      width: 100vw;
      position: fixed !important;
      top: auto;
      right: 0;
      left: 0;
      bottom: 0;
      border-radius: 12px 12px 0 0 !important;
      margin-top: auto !important;
      margin-bottom: 0 !important;
    }
    .content {
      border-radius: 12px !important;
      @media screen and (max-width:576px) {
        border-radius: 12px 12px 0 0 !important;
        max-height: calc(100vh - 80px);
      }
    }
    .addproduct-btn {
      height: 46px;
      padding: 0 28px;
      font-size: 15px;
      font-weight: 600;
    }
    .header {
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      border-radius: 12px 12px 0 0 !important;
      @media screen and (max-width:576px) {
        border-radius: 12px 12px 0 0 !important;
        padding: 1.2rem 1.2rem!important;
      }
      .closeicon-header {
        cursor: pointer;
        .icon {
          fill: #334E68;
          width: 12px;
        }
      }
      .backicon-header {
        display: flex;
        align-items: center;
        .icon {
          width: 15x;
          height: 15px;
          margin-right: 6px;
          fill: #334E68;
        }
      }
      h3{
        margin: 0;
        font-size: 18px;
        font-weight: 700;
        font-family: $inter-font;
        color: #243B53;
        font-size: 18px;
        font-weight: 700;
      }
    }
    .ui.form {
      @media screen and (max-width:576px) {
        display: flex;
        flex-direction: column;
      }
      .field {
        label {
          font-size: 16px;
          font-weight: 600;
          color: #243B53;
          font-family: $inter-font;
          margin-bottom: 4px;
          display: block;
          small {
            color: #A3B2C6;
            font-size: 13px;
            font-weight: 500;
          }
        }
        textarea {
          min-height: 48px;
          border: 1px solid #DBE2EB;
          border-radius: 6px;
          font-family: $inter-font;
          font-weight: 500;
          font-size: 14px;
          &::placeholder {
            font-weight: 500;
            font-size: 14px;
            color: #A3B2C6;
          }
          &::-webkit-placeholder {
            font-weight: 500;
            font-size: 14px;
            color: #A3B2C6;
          }
          &::-moz-placeholder {
            font-weight: 500;
            font-size: 14px;
            color: #A3B2C6;
          }
        }
        input {
          min-height: 48px;
          border: 1px solid #DBE2EB;
          border-radius: 6px;
          font-family: $inter-font;
          font-weight: 500;
          font-size: 14px;
          &::placeholder {
            font-weight: 500;
            font-size: 14px;
            color: #A3B2C6;
          }
          &::-webkit-placeholder {
            font-weight: 500;
            font-size: 14px;
            color: #A3B2C6;
          }
          &::-moz-placeholder {
            font-weight: 500;
            font-size: 14px;
            color: #A3B2C6;
          }
        }
        .selection.dropdown {
          min-height: 48px;
          border: 1px solid #DBE2EB;
          border-radius: 6px;
          padding: 0 32px 0 16px;
          color: #486581;
          background-image: none;
          .pil-item-row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-height: 48px;
          }
          .item {
            padding: 2px 8px !important;
          }
          & > .text {
            font-family: $inter-font;
            font-size: 14px;
            font-weight: 500;
            min-height: 48px;
            line-height: 48px;
            color: #486581;
            .right-col {
              display: none;
            }
            &::after {
              background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxMCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAgNkw1IDBMMTAgNkgwWiIgZmlsbD0iI0EzQjJDNiIvPgo8cGF0aCBkPSJNMCA4TDUgMTRMMTAgOEgwWiIgZmlsbD0iI0EzQjJDNiIvPgo8L3N2Zz4=");
              content: "";
              background-size: 10px;
              background-repeat: no-repeat;
              width: 10px;
              height: 15px;
              top: 50%;
              transform: translateY(-50%);
              right: -18px !important;
            }
          }
        }
      }
    }
    .ui.button {
        font-family: $inter-font;
        font-size: 16px;
        height: 46px;
        padding: 0 28px;
        font-weight: 600;
        border-radius: 6px;
    }
    .tags-selected-list {
        margin-bottom: 10px;
        max-height: calc(100vh - 340px);
        overflow-y: auto;
        .item {
            padding: 5px 0;
            font-size: 16px;
            color: #243B53;
            font-weight: 600;
            align-items: center;
            display: flex;
            width: 100%;
            justify-content: space-between;
            &:hover {
                padding-left: 5px;
            }
        }
        .tagremove-btn {
            background: #EB3636;
            margin-left: auto;
            cursor: pointer;
            width: 25px;
            height: 25px;
            padding: 0;
            border-radius: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            .icon {
                fill: $white;
                width: 10px;
                height: 10px;
                opacity: 1;
                margin: auto;
            }
        }
    }
  }
  
  .modals.dimmer {
    @media screen and (max-width:576px) {
      padding: 0 !important;
      overflow: hidden !important;
    }
  }