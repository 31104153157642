@import '../../../assets/scss/variables.scss';

.left-guest-slider {
    background: $white;
    border: 1px solid #DBE2EB;
}

.leftguest-slider{
    padding: 15px;
    height: 100%;
    border: 0;
    font-family: $inter-font;
    .card{
        height: 100%;
       .card-inner{
           height: 100%;
          .card-image{
            img{
                width: 100%;
            }
          } 
          .card-bottom{
            padding-top: 0;
            margin-top: auto;
            font-family: $inter-font;
            b{
                color: $secondary-color;
                font-weight: 600;
                word-wrap: break-word;
                font-size: 15px;
                margin-bottom: 6px;
                display: block;
            }
            p{
                color: $secondary-color;
                font-size: 13px;
            }
            .button{
                min-width: 85px;
                height: 32px;
                border: 1px solid #2166EE;
                color: #2166EE;
                background: $white;
                font-size: 13px;
                font-weight: 600;
                padding: 6px 8px;
                font-family: $inter-font;
            }
          }
       } 
    }
}

.guestscroll-left{
    max-width: calc(252px - 10px);
    width: 100% !important;
    & > div {
        overflow-x: hidden !important;
    }
}

.left-guest-slider{
    margin-bottom: 15px;
    margin-right: 0;
    border: 1px solid $border-color-base;
    border-radius: 8px;
    margin-top: 10px;
    .slick-slide{
        overflow: hidden;
    }
    .slick-dots{
        bottom: 18px;
        position: absolute;
        right: 6px;
        text-align: right;
        width: auto;
        li{
            margin: 0;
            height: auto;
            width: 12px;
            button{
                width: 7px;
                height: 4px;
                border-radius: 5px;
                background: #E5F2FE;
                padding: 0;
                &::before{
                    display: none;
                }
            }
            &.slick-active {
                button {
                    background: #2166EE;
                }
            }
        }
    }
}

.snapshot-slider{
    border: 2px solid #999;
    border-radius: 8px;
    .slick-dots{
        bottom: 6px;
        li{
            margin: 0;
            width: 16px;
            button{
                width: 16px;
                padding: 0;
                height: 24px;
                line-height: 24px;
                &::before{
                    font-size: 9px;
                    opacity: 0.5;
                }
            }
            &.slick-active{
                button{
                    &::before{
                        color: $primary-color;
                        opacity: 1;
                    }
                }
            }
        }
    }

    .slick-slider{
        margin: 0;
    }
    .snapshot-slider-head{
        background: #e3e3e3;
        border-radius: 8px 8px 0 0;
        padding: 5px;
        height: 25px;
        border-bottom: 2px solid #999;
        display: flex;
        align-items: center;
        .icon{
            width: 8px;
            height: 30px;
            transform: rotate(90deg);
            fill: $white;
            stroke-width: 1px;
            stroke: #999;
            margin-left: 15px;
            opacity: 1;
        }
    }
}