@import '../../assets/scss/variables.scss';

.addevent-card{
    background: #242155;
    color: #ffffff;
    border-radius: 8px;
    padding: 15px;
    font-family: $inter-font;
    background-image: url('../../assets/images/add-event-bg.png');
    background-repeat: no-repeat;
    background-size: 100%;
    p{
        line-height: 1.6;
        font-size: 14px;
        font-weight: 500;
    }
    .ui.button{
        color: #242155;
        background: #ffffff;
        font-weight: 700;
        font-size: 14px;
        font-family: $inter-font;
        border-radius: 6px;
        height: 32px;
    }
}

.viewallevent-bottom {
    font-size: 12px;
    font-family: $inter-font;
    font-weight: 500;
    color: #334E68;
    line-height: 1.3;
    margin-top: 15px;
    a {
        font-size: 14px;
        color: #2166EE;
        font-weight: 600;
        display: block;
        margin-top: 6px;
        font-family: $inter-font;
    }
}

.upcomming-events-list{
    margin-top: 5px;
    overflow: hidden;
    .upcomming-events-head{
        font-size: 15px;
        font-weight: 600;
        padding-left: 0;
        padding-right: 0;
        color: #1A1642;
        border: 0;
        padding-top: 20px;
    }
    .list{
        border: 1px solid #DBE2EB;
        border-radius: 5px;
        margin-top: 0;
        background: #ffffff;
        .card{
            background: none;
            .header{
                color: #1A1642;
            }
        }
    }
}

.righteventlist-bottom{
    margin-top: 1rem;
    p{
        color: #1A1642;
        font-size: 12px;
        margin-bottom: 5px;
    }
    a{
        color: #2699FB;
        i{
            font-size: 10px;
            margin-left: 3px;
        }
    }
}

.upcomming-event-item {
    border-bottom: 1px solid #DBE2EB;
    padding: 14px;
    width: 100%;
    .card {
        padding: 12px !important;
    }
    &:first-child {
        .card {
            border-top: 0;
        }
    }
    .list-inner {
        display: flex;
        width: 100%;
        .left-img {
            width: 48px;
            min-width: 48px;
            height: 36px;
            border-radius: 6px;
            overflow: hidden;
            background: #DBE2EB;
            margin-right: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            .image {
                display: flex;
                align-items: center;
                justify-content: center;
            }
            img {
                width: 100%;
                height: 100%;
            }
        }
        .dtl-right {
            width: 100%;
            .upper-dtl {
                padding-left: 56px;
                margin-bottom: 5px;
                p {
                    margin-bottom: 0;
                    font-family: $inter-font;
                }
                h3 {
                    margin-bottom: 6px;
                    margin-top: 2px;
                    color: #243B53;
                    font-weight: 600;
                    font-family: $inter-font;
                    font-size: 14px;
                }
                .date {
                    color: #486581;
                    font-size: 12px;
                    font-weight: 600;
                    font-family: $inter-font;
                }
                .location {
                    color: #486581;
                    font-size: 12px;
                    font-weight: 500;
                    font-family: $inter-font;
                    word-break: break-all;
                }
            }
            .details-info {
                font-size: 12px;
                font-weight: 500;
                color: #486581;
                line-height: 1.4;
                font-family: $inter-font;
                .view-event-link {
                    font-weight: 600;
                    color: #2166EE;
                    margin-left: 6px;
                    font-family: $inter-font;
                    font-size: 14px;
                }
            }
        }
    }
}