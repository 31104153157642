@import '../../assets/scss/variables.scss';

.aboutproduct-section{
    .aboutsection-row{
        @media screen and (max-width:767px){
            flex-direction: column-reverse;
        }
    }
    &.industry-about{
        padding-top: 60px;
    }
    .products-images{
        ul{
            display: grid;
            grid-gap: 18px;
            grid-template: repeat(1, 1fr)/repeat(3, 1fr);
            list-style-type: none;
            padding-left: 0;
            padding-right: 50px;
            @media screen and (max-width:767px){
                padding-right: 0;
                grid-gap: 5px;
            }
            @media screen and (max-width:640px){
                grid-template: repeat(1, 1fr)/repeat(2, 1fr);
            }
            li{
                max-height: 180px;
                img{
                    width: 100%;
                    @media screen and (max-width:640px){
                        border-radius: 10px;
                    }
                }
            }
        }
    }
    .products-text{
        @media screen and (max-width:576px){
            padding: 0 50px;
        }
        h2{
            color: $secondary-color;
            font-size: 3rem;
            font-weight: 500;
            max-width: 480px;
            line-height: 4rem;
            margin-bottom: 20px;
            @media screen and (max-width:767px){
                font-size: 2rem;
                line-height: 2.8rem;
            }
        }
        p{
            color: $secondary-color;
            font-size: 1.3rem;
            margin-bottom: 30px;
            font-family: Arial;
            @media screen and (max-width:767px){
                font-size: 1rem;
                line-height: 24px;
            }
        }
    }
}

.companylogos{
    ul{
        display: grid;
        grid-gap: 25px;
        grid-template: repeat(1, 1fr)/repeat(4, 1fr);
        list-style-type: none;
        padding-left: 0;
        @media screen and (max-width:767px){
            grid-template: repeat(1, 1fr)/repeat(2, 1fr);
        }
        li{
            text-align: center;
            img{
                max-height: 65px;
            }
        }
    }
}

.product-banner-section{
    min-height: calc(85vh - 60px);
    position: relative;
    @media screen and (max-width:991px){
        min-height: calc(85vh - 280px);
    }
    @media screen and (max-width:767px){
        display: flex;
        flex-direction: column-reverse;
        padding-top: 40px;
        padding-bottom: 30px;
    }
    @media screen and (max-width:576px){
        padding-top: 10px;
    }
    .row{
        min-height: calc(85vh - 30px); 
        @media screen and (max-width:991px){
            min-height: calc(85vh - 280px);
        }
    }
    .banner-row{
        @media screen and (max-width:576px){
            flex-direction: column-reverse;
        }
    }
    h1{
        font-size: 4.2rem;
        font-weight: bold;
        color: black;
        @media screen and (max-width:767px){
            font-size: 2.5rem;
        }
        @media screen and (max-width:576px){
            font-size: 2.1rem;
        }
    }
    p{
        @media screen and (max-width:576px){
            font-size: 1.1rem;
            margin-top: 10px !important;
        }
    }
    .product-banner{
        @media screen and (max-width:1366px){
            width: 100%;
        }
        @media screen and (max-width:576px){
            margin: 20px auto;
            width: 90%;
        }
    }
    .button-section{
        @media screen and (max-width:576px){
            margin-top: 15px !important;
            flex-direction: column-reverse;
            display: flex;
        }
    }
    .button {
        @media screen and (max-width:767px){
            width: 100%;
            margin-bottom: 10px;
        }
    }
}

.upcoming-section{
    @media screen and (max-width:576px){
        .explore-btn{
            width: 100%;
        }
    }
}

.upcoming-events{
    .cards{
        .card{
            box-shadow: 2px 4px 11px rgba(0,0,0,0.1);
            border-radius: 0;
            color: $secondary-color;
            font-family: 'Arial';
            @media screen and (max-width:767px){
                width: calc(50% - 2em) !important;
            }
            @media screen and (max-width:560px){
                width: calc(100%) !important;
            }
            .image{
                background: none;
                border: 0;
            }
            .eventcardmain-image{
                width: 100%;
            }
            .header{
                color: $secondary-color;
                font-size: 1.5rem;
                margin-bottom: 10px;
                font-weight: 600;
                font-family: 'Arial';
            }
            .content{
                border: 0 !important;
                padding: 1.5em 2em;   
                .description{
                    color: $secondary-color;
                    font-size: 13px;
                    margin-bottom: 15px;
                }
            }
            a{
                color: $secondary-color !important;
                font-weight: 600;
                text-transform: uppercase;
            }
        }
    }
}