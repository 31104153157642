/* .wrapper {
   #froala-editor {
      .fr-box.fr-basic .fr-element {
         padding: 0px !important;
      }

      .fr-second-toolbar {
         display: none !important;
      }

      .paragraph-format {
         font-size: 19px;
         padding: 8px;
         color: #333333 !important;
         font-weight: 600;
         margin-right: 3px;
      }

   }
} */

.readonly {

   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;

}