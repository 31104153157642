@import '../../../assets/scss/variables.scss';

.lwo-body {
    background: #19163F;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../../../assets/images/login-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    .lwo-card {
        width: 100%;
        background: $white;
        padding: 3.5rem 4.5rem 5rem 4.5rem;
        border-radius: 20px;
        max-width: 492px;
        position: relative;
        @media screen and (max-width:576px) {
            border-radius: 0;
            min-width: 100vw;
            min-height: 100vh;
            padding: 2.5rem 2rem 2rem;
        }
        .lwo-card-inner {
            font-family: $inter-font;
            .back-btn {
                background: none;
                border: 0;
                display: flex;
                align-items: center;
                position: absolute;
                left: 18px;
                top: 25px;
                font-family: $inter-font;
                color: #243B53;
                @media screen and (max-width:576px) {
                    position: inherit;
                    padding: 10px 0;
                }
                &:hover {
                    color: #2166EE;
                    .icon {
                        fill: #2166EE;
                    }
                }
            }
            .upper-head {
                margin-bottom: 35px;
                text-align: center;
                img {
                    width: 65px;
                }
                .sentemail {
                    span {
                        background: #FFF9DC;
                        color: #486581;
                        font-weight: 600;
                        display: inline-block;
                        padding: 0 2px;
                    }
                    button {
                        color: #2166EE;
                        background: none;
                        border: 0;
                        font-family: $inter-font;
                        font-weight: 600;
                        cursor: pointer;
                    }
                }
            }
            h1{
                text-align: center;
                color: #243B53;
                font-size: 24px;
                font-weight: 700;
                font-family: $inter-font;
            }
            p{
                font-size: 14px;
                color: #829AB1;
                font-weight: 500;
                font-family: $inter-font;
            }
            .form-item {
                width: 100%;
                label {
                    display: block;
                    color: #A3B2C6;
                    font-size: 12px;
                    font-weight: 500;
                    font-family: $inter-font;
                    margin-bottom: 10px;
                }
                .input {
                    width: 100%;
                    input {
                        height: 52px;
                        border-radius: 12px;
                        font-family: $inter-font;
                        border: 1px solid #DBE2EB;
                        color: #243B53;
                        font-size: 14px;
                        font-weight: 600;
                        &::placeholder {
                            color: #829AB1;
                            font-weight: 500;
                        }
                        &::-webkit-placeholder {
                            color: #829AB1;
                            font-weight: 500;
                        }
                        &::-moz-placeholder {
                            color: #829AB1;
                            font-weight: 500;
                        }
                    }
                }
                .otp-input {
                    display: grid;
                    grid-template-columns: repeat(6, 1fr);
                    width: 100%;
                    grid-gap: 10px;
                    input {
                        height: 52px;
                        border-radius: 12px;
                        font-family: $inter-font;
                        border: 1px solid #DBE2EB;
                        width: 100% !important;
                        color: #243B53;
                        font-size: 21px;
                        font-weight: 700;
                        @media screen and (max-width:576px) {
                            height: 48px;
                            font-size: 19px;
                        }
                        &:focus {
                            border-color: #2166EE;
                            outline: none;
                        }
                    }
                }
            }
            .resend-row {
                text-align: center;
                margin-top: 15px;
                display: flex;
                justify-content: space-evenly;
                p {
                    font-weight: 400;
                    color: #829AB1;
                }
                b {
                    color: #486581;
                    font-weight: 600;
                }
                button {
                    color: #2166EE;
                    background: none;
                    border: 0;
                    font-family: $inter-font;
                    font-weight: 600;
                    cursor: pointer;
                }
            }
            .submit-btn {
                width: 100%;
                height: 52px;
                border-radius: 12px;
                font-size: 14px;
                font-weight: 600;
                font-family: $inter-font;
                margin-top: 22px;
                &[disabled] {
                    background: #b9c0c9;
                }
            }
            .ageee-text {
                text-align: center;
                margin-top: 7rem;
                font-size: 12px;
                color: #829AB1;
                @media screen and (max-width:576px) {
                    margin-top: 3.5rem;
                }
                b {
                    color: #486581;
                    font-weight: 500;
                }
            }
            .bottom-action {
                text-align: center;
                margin-top: 15px;
                .button {
                    background: none;
                    color: #829AB1;
                    border: 0;
                    font-weight: 500;
                    font-family: $inter-font;
                }
            }
        }
    }
}