@import '../../../../assets/scss//variables.scss';

.menu {
    &.footer-menu {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background: $white !important;
        z-index: 9999;
        box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.07);
        border: 0;
        width: 100vw;
        border: 0 !important;
        margin-bottom: 0;
        .notify{
            transform: translateX(3px) translateY(-22px);
            position: relative;
            .heartbit {
                border-color: #19153F;
            }
        }
        .item {
            color: #829AB1;
            padding: 10px 5px;
            font-family: $inter-font;
            font-size: 10px;
            display: flex;
            flex-direction: column;
            width: 20%;
            border: 0;
            background: none !important;
            &::before {
                height: 2px;
                background: $white;
                border-radius: 2px;
                width: 50%;
                left: auto;
                right: auto;
                margin: auto;
                position: absolute;
                top: 0;
            }
            .icon {
                height: 22px;
                width: 20px;
                fill: #829AB1;
                margin-bottom: 5px;
            }
            &.active {
                color: #243B53 !important;
                font-weight: 600;
                .icon {
                    fill: $primary-color;
                }
                &::before {
                    background: $primary-color;
                }
            }
        }
    }
}