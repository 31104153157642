@import '../../assets/scss/variables.scss';

.newslist-mainwrapper {
    font-family: 'Inter', sans-serif;
    border: 0 !important;
    background: none !important;
    padding: 2rem 0 1rem 0 !important;
    background: #ffffff !important;
    min-height: calc(100vh - 60px);
    margin-top: 60px;

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'Inter', sans-serif;
    }

    @media screen and (max-width:991px) {
        padding: 1rem 0 1rem 0 !important;
    }

    .container {
        @media screen and (max-width:1200px) {
            max-width: 96%;
        }

        @media screen and (max-width:991px) {
            max-width: 100%;
            padding: 0;
        }
    }

    .news-list-upper {
        .filer-modal {
            position: fixed;
            z-index: 999;
            min-height: 100vh;
            min-width: 100vw;
            background: rgba(#000000, 0.5);
            display: flex;
            align-items: flex-end;
            top: 100%;
            left: 0;
            transition: all ease-in 0.2s;

            &.show {
                top: 0;
            }

            .filter-modal-content {
                background: $white;
                border-radius: 15px 15px 0 0;
                min-height: calc(100vh - 60px);
                width: 100%;

                .filter-modal-header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 1px solid #E5E8F0;
                    padding: 15px 18px;

                    .left-header {
                        display: flex;
                        align-items: center;
                        font-size: 18px;
                        color: #0D1A35;
                        font-weight: 700;
                        cursor: pointer;

                        .icon {
                            width: 9px;
                            fill: #0D1A35;
                            margin-right: 12px;
                        }
                    }

                    .clear-btn {
                        font-size: 14px;
                        color: #2166EE;
                        background: none;
                        border: 0;
                        outline: none;
                        font-weight: 600;
                        font-family: $inter-font;
                        cursor: pointer;
                    }
                }

                .filter-modal-body {
                    padding: 15px 18px;
                    max-height: calc(100vh - 165px);
                    min-height: calc(100vh - 165px);
                    overflow-y: auto;

                    .second-label {
                        text-transform: uppercase;
                        color: #829AB1;
                        font-size: 10px;
                        font-weight: 700;
                        margin-bottom: 10px;
                        letter-spacing: 1px;
                    }

                    .filter-head {
                        display: flex;
                        align-items: center;
                        position: relative;
                        margin-bottom: 0.5rem;

                        h4 {
                            margin-bottom: 0;
                            font-size: 14px;
                            font-weight: 700;
                            color: #243B53;
                        }

                        .header-search {
                            position: absolute;
                            right: 0;
                            max-width: 150px;
                            width: 100%;
                            transition: all ease-in 0.2s;
                            height: 34px;

                            &:hover {
                                max-width: 100%;
                            }

                            input {
                                border-color: #DBE2EB;
                                border-radius: 7px;
                                font-family: $inter-font;
                                font-weight: 500;
                                padding: 8px 20px 8px 8px !important;
                                font-size: 12px;

                                &::placeholder {
                                    color: #A3B2C6;
                                    font-weight: 500;
                                }
                            }

                            .icon {
                                fill: #829AB1;

                                &::before {
                                    color: #829AB1;
                                }
                            }
                        }
                    }

                    ul {
                        list-style-type: none;
                        padding-left: 0;
                        margin: 0;
                        height: 100%;
                        overflow-y: auto;
                        margin-bottom: 15px;

                        li {
                            padding: 8px 0;

                            .checkbox {
                                font-size: 14px;
                                color: #486581;
                                font-size: 14px;

                                label {
                                    padding-left: 2.3rem !important;
                                    font-weight: 500;
                                    color: #486581;
                                    font-family: $inter-font;
                                    line-height: 20px;
                                    margin: 0;

                                    &::before {
                                        width: 20px;
                                        height: 20px;
                                        border-radius: 5px;
                                        border-color: #829AB1;
                                    }
                                }

                                &.checked {
                                    label {
                                        &::before {
                                            background: $primary-color;
                                            border-color: $primary-color;
                                        }

                                        &::after {
                                            width: 22px;
                                            height: 22px;
                                            border-radius: 5px;
                                            border-color: $primary-color;
                                            background-image: url('data:image/svg+xml,<svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13 1.5L4.75 9.75L1 6" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
                                            font-size: 0;
                                            background-repeat: no-repeat;
                                            background-position: center center;
                                            background-size: 12px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .primary.button {
                        width: 100%;
                        max-width: calc(100% - 30px);
                        height: 50px;
                        font-size: 16px;
                        font-family: $inter-font;
                        margin-top: 12px;
                        border-radius: 8px;
                        position: absolute;
                        bottom: 8px;
                        left: 15px;
                        right: 15px;
                        margin: auto;
                    }
                }
            }
        }

        .newslistupper-inner {
            align-items: flex-end;
            justify-content: space-between;

            @media screen and (max-width: 991px) {
                align-items: center;
            }

            .event-list-upper {
                margin-bottom: 0.5rem;

                .back-link {
                    color: #1A1642;
                    font-size: 17px;
                    font-weight: 700;
                }
            }

            .clear-btn {
                font-size: 17px;
                color: #2166EE;
                background: none;
                border: 0;
                outline: none;
                font-weight: 500;
                font-family: $inter-font;
                cursor: pointer;

                @media screen and (max-width: 991px) {
                    display: none;
                }
            }

            .search-small {
                display: flex;
                padding: 0;
                justify-content: flex-end;

                .search.ui {
                    width: 100%;

                    input {
                        border-radius: 8px;
                        background: #F6F8FC;
                        border: 1px solid #DBE2EB;
                    }
                }

                .filter-btn {
                    width: 30px;
                    height: 30px;
                    min-width: 30px;
                    border-radius: 6px;
                    background: #F6F8FC;
                    border: 1px solid #DBE2EB;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    margin-left: 10px;
                    padding: 0;

                    .icon {
                        width: 18px;
                    }
                }
            }
        }

        .search {
            input {
                border-color: #DBE2EB;
                border-radius: 7px;
                font-family: $inter-font;
                font-weight: 500;

                &::placeholder {
                    color: #A3B2C6;
                    font-weight: 500;
                }
            }
        }

        h2 {
            font-size: 1.6rem;
            font-weight: 700;
            color: #0D1A35;

            @media screen and (max-width:991px) {
                display: flex;
                align-items: center;
                margin-bottom: 0;
                padding: 0;
                font-size: 17px;

                .icon {
                    width: 9px;
                    margin-right: 10px;
                    cursor: pointer;
                }
            }
        }

        .breadcrumb {
            color: #829AB1;
            font-size: 16px;

            a {
                color: #829AB1;
            }
        }
    }

    .news-list-bottom {
        display: flex;
        width: 100%;

        @media screen and (max-width: 991px) {
            padding-left: 15px;
            padding-right: 15px;
        }

        .news-list-left {
            width: 100%;

            @media screen and (min-width:991px) {
                width: calc(100% - 390px);
            }

            .filterlist-row {
                .filterlist-col {
                    @media screen and (max-width: 991px) {
                        padding: 0;
                    }
                }
            }

            .filterlist {
                position: relative;
                margin-top: 1.8rem;

                @media screen and (max-width: 991px) {
                    background: #F6F8FC;
                    border-top: 1px solid #E5E8F0;
                    border-bottom: 1px solid #E5E8F0;
                    padding: 10px 8px;
                    overflow-x: auto;
                    margin-top: 1rem;

                    &::-webkit-scrollbar {
                        width: 0;
                        height: 0;
                    }

                    &::-webkit-scrollbar-track {
                        -webkit-box-shadow: none;
                    }

                    &::-webkit-scrollbar-thumb {
                        width: 0;
                        height: 0;
                    }
                }

                ul {
                    list-style-type: none;
                    padding-left: 0;
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: 0;
                    grid-gap: 10px;
                    margin: 0;

                    @media screen and (max-width: 991px) {
                        flex-wrap: nowrap;
                        grid-gap: 8px;
                    }

                    li {
                        border: 1px solid #DBE2EB;
                        background: $white;
                        padding: 6px 12px;
                        display: flex;
                        align-items: center;
                        color: #486581;
                        font-size: 12px;
                        border-radius: 22px;
                        white-space: nowrap;
                        font-weight: 500;

                        @media screen and (max-width:991px) {
                            font-size: 11px;
                        }

                        span {
                            margin-left: 6px;
                            cursor: pointer;
                            display: flex;
                            align-items: center;

                            .icon {
                                fill: #829AB1;
                                width: 10px;
                            }

                            &:hover {
                                .icon {
                                    fill: #2166EE;
                                }
                            }
                        }
                    }
                }
            }

            .news-list-items {
                margin-top: 0.8rem;

                @media screen and (max-width:991px) {
                    margin-top: 0.2rem;
                }

                .news-list-item {
                    display: flex;
                    font-family: $inter-font;
                    padding: 15px 0 25px 0;

                    @media screen and (max-width:576px) {
                        display: block;
                    }

                    .left-img {
                        background: #E7EDFD;
                        border-radius: 15px;
                        overflow: hidden;
                        width: 100%;
                        max-width: 240px;
                        min-width: 240px;
                        margin-right: 20px;
                        height: 140px;

                        @media screen and (max-width:576px) {
                            max-width: 100%;
                            margin: 0 0 1rem 0;
                            height: 210px;
                        }

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }

                        .ieeBTD {
                            background-size: cover;
                        }

                        .microlink_card {
                            height: 100% !important;
                        }

                        .microlink_card__content {
                            display: none;
                        }
                    }

                    .right-dtls {
                        label {
                            color: #2166EE;
                            letter-spacing: 1px;
                            text-transform: uppercase;
                            font-weight: 600;
                            font-family: $inter-font;
                            margin-bottom: 0;
                        }

                        h3 {
                            font-size: 18px;
                            color: #243B53;
                            font-weight: 700;
                            margin: 0 0 0.85rem 0;
                            font-family: $inter-font;
                            cursor: pointer;
                        }

                        p {
                            font-size: 14px;
                            font-weight: 500;
                            color: #486581;
                            margin-bottom: 0;
                        }

                        small {
                            color: #829AB1;
                        }

                        div:first-child {
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-line-clamp: 4;
                            -webkit-box-orient: vertical;
                        }
                        
                        br{
                            display: none !important;
                        }
                    }
                }
            }
        }

        .news-list-right {
            width: 100%;
            max-width: 380px;
            padding-top: 0.2rem;
            padding-left: 2.2rem;
            border-left: 1px solid #DBE2EB;
            margin-left: 0.8rem;
            @media screen and (min-width:991px) {
                min-width: 380px;
            }
            h3 {
                font-size: 1.1rem;
                font-weight: 700;
                font-family: $inter-font;
                color: #243B53;
                margin-bottom: 1.6rem;
            }

            .second-label {
                text-transform: uppercase;
                color: #829AB1;
                font-size: 10px;
                font-weight: 700;
                margin-bottom: 10px;
                letter-spacing: 1px;
            }

            .filter-head {
                display: flex;
                align-items: center;
                position: relative;
                margin-bottom: 0.5rem;
                justify-content: space-between;

                h4 {
                    margin-bottom: 0;
                    font-size: 14px;
                    font-weight: 700;
                    color: #243B53;
                }

                .header-search {
                    position: absolute;
                    right: 0;
                    max-width: 150px;
                    width: 100%;
                    transition: all ease-in 0.2s;
                    height: 34px;

                    &:hover {
                        max-width: 100%;
                    }

                    input {
                        border-color: #DBE2EB;
                        border-radius: 7px;
                        font-family: $inter-font;
                        font-weight: 500;
                        padding: 8px 20px 8px 8px !important;
                        font-size: 12px;

                        &::-webkit-placeholder {
                            color: #A3B2C6;
                            font-weight: 500;
                        }

                        &::-moz-placeholder {
                            color: #A3B2C6;
                            font-weight: 500;
                        }

                        &::placeholder {
                            color: #A3B2C6;
                            font-weight: 500;
                        }
                    }

                    .icon {
                        fill: #829AB1;

                        &::before {
                            color: #829AB1;
                        }
                    }
                }
            }

            ul {
                list-style-type: none;
                padding-left: 0;
                margin: 0 0 25px 0;
                border-bottom: 1px solid #DBE2EB;
                padding-bottom: 10px;

                li {
                    padding: 7px 0;

                    .checkbox {
                        font-size: 14px;
                        color: #486581;
                        font-size: 14px;

                        label {
                            padding-left: 2.1rem !important;
                            font-weight: 500;
                            color: #486581;
                            font-family: $inter-font;
                            line-height: 20px;
                            margin: 0;

                            &::before {
                                width: 20px;
                                height: 20px;
                                border-radius: 5px;
                                border-color: #829AB1;
                            }
                        }

                        &.checked {
                            label {
                                &::before {
                                    background: $primary-color;
                                    border-color: $primary-color;
                                }

                                &::after {
                                    width: 22px;
                                    height: 22px;
                                    border-radius: 5px;
                                    border-color: $primary-color;
                                    background-image: url('data:image/svg+xml,<svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13 1.5L4.75 9.75L1 6" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
                                    font-size: 0;
                                    background-repeat: no-repeat;
                                    background-position: center center;
                                    background-size: 12px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.filter-search-drop {
    max-height: 30vh;
    min-height: 320px;
    overflow-y: auto;
}

.filter-search-drop-mobile {
    max-height: 38vh;
    overflow-y: auto;
}