@import '../../../assets/scss/variables.scss';
.alert-mask {
   background: rgba(0, 0, 0, .4);
   position: fixed;
   z-index: 9999;
   height: 100%;
   width: 100%;
   top: 0;
   left: 0;
   display: flex;
   align-items: center;
   justify-content: center;

   .alert-card {
      position: relative;
      width: 32em;
      max-width: 93%;
      padding: 2.25em;
      border: none;
      border-radius: 5px;
      background: #fff;
      font-family: $inter-font;
      font-size: 1rem;
      text-align: center;

      &.animate {
         transition: alertanimation .3s;
      }

      .alert-icon {
         img {
            width: 110px;
         }
      }

      .error-mark {
         width: 80px;
         height: 115px;
         margin: 0 auto;

         .check-icon {
            width: 80px;
            height: 80px;
            position: relative;
            border-radius: 50%;
            box-sizing: content-box;
            border: 4px solid #a71d1d;

            &::before {
               top: 3px;
               left: -2px;
               width: 30px;
               transform-origin: 100% 50%;
               border-radius: 100px 0 0 100px;
            }

            &::after {
               top: 0;
               left: 30px;
               width: 60px;
               transform-origin: 0 50%;
               border-radius: 0 100px 100px 0;
               animation: rotate-circle 4.25s ease-in;
            }

            &::before,
            &::after {
               content: '';
               height: 100px;
               position: absolute;
               background: #FFFFFF;
               transform: rotate(-45deg);
            }

            .icon-line {
               height: 5px;
               background-color: #ff6c6c;
               display: block;
               border-radius: 2px;
               position: absolute;
               z-index: 10;

               &.line-tip {
                  top: 40px;
                  left: 14px;
                  width: 50px;
                  transform: rotate(45deg);
                  animation: icon-line-tip-error 0.75s;
               }

               &.line-long {
                  top: 40px;
                  right: 14px;
                  width: 50px;
                  transform: rotate(-45deg);
                  animation: icon-line-long-error 0.75s;
               }
            }

            .icon-circle {
               top: -4px;
               left: -4px;
               z-index: 10;
               width: 80px;
               height: 80px;
               border-radius: 50%;
               position: absolute;
               box-sizing: content-box;
               border: 4px solid #ff6c6c;
            }

            .icon-fix {
               top: 8px;
               width: 5px;
               left: 26px;
               z-index: 1;
               height: 85px;
               position: absolute;
               transform: rotate(-45deg);
               background-color: #FFFFFF;
            }
         }
      }

      .success-checkmark {
         width: 88px;
         height: 105px;
         margin: 0 auto;

         .check-icon {
            width: 80px;
            height: 80px;
            position: relative;
            border-radius: 50%;
            box-sizing: content-box;
            border: 4px solid #4CAF50;

            &::before {
               top: 3px;
               left: -2px;
               width: 30px;
               transform-origin: 100% 50%;
               border-radius: 100px 0 0 100px;
            }

            &::after {
               top: 0;
               left: 30px;
               width: 60px;
               transform-origin: 0 50%;
               border-radius: 0 100px 100px 0;
               animation: rotate-circle 4.25s ease-in;
            }

            &::before,
            &::after {
               content: '';
               height: 100px;
               position: absolute;
               background: #FFFFFF;
               transform: rotate(-45deg);
            }

            .icon-line {
               height: 5px;
               background-color: #4CAF50;
               display: block;
               border-radius: 2px;
               position: absolute;
               z-index: 10;

               &.line-tip {
                  top: 46px;
                  left: 14px;
                  width: 25px;
                  transform: rotate(45deg);
                  animation: icon-line-tip 0.75s;
               }

               &.line-long {
                  top: 38px;
                  right: 8px;
                  width: 47px;
                  transform: rotate(-45deg);
                  animation: icon-line-long 0.75s;
               }
            }

            .icon-circle {
               top: -4px;
               left: -4px;
               z-index: 10;
               width: 80px;
               height: 80px;
               border-radius: 50%;
               position: absolute;
               box-sizing: content-box;
               border: 4px solid rgba(76, 175, 80, .5);
            }

            .icon-fix {
               top: 8px;
               width: 5px;
               left: 26px;
               z-index: 1;
               height: 85px;
               position: absolute;
               transform: rotate(-45deg);
               background-color: #FFFFFF;
            }
         }
      }

      @keyframes rotate-circle {
         0% {
            transform: rotate(-45deg);
         }

         5% {
            transform: rotate(-45deg);
         }

         12% {
            transform: rotate(-405deg);
         }

         100% {
            transform: rotate(-405deg);
         }
      }

      @keyframes icon-line-tip {
         0% {
            width: 0;
            left: 1px;
            top: 19px;
         }

         54% {
            width: 0;
            left: 1px;
            top: 19px;
         }

         70% {
            width: 50px;
            left: -8px;
            top: 37px;
         }

         84% {
            width: 17px;
            left: 21px;
            top: 48px;
         }

         100% {
            width: 25px;
            left: 14px;
            top: 45px;
         }
      }

      @keyframes icon-line-tip-error {
         0% {
            transform: rotate(0);
         }

         54% {
            transform: rotate(25deg);
         }

         100% {
            transform: rotate(45deg);
         }
      }

      @keyframes icon-line-long {
         0% {
            width: 0;
            right: 46px;
            top: 54px;
         }

         65% {
            width: 0;
            right: 46px;
            top: 54px;
         }

         84% {
            width: 55px;
            right: 0px;
            top: 35px;
         }

         100% {
            width: 47px;
            right: 8px;
            top: 38px;
         }
      }

      @keyframes icon-line-long-error {
         0% {
            transform: rotate(0);
         }

         54% {
            transform: rotate(-25deg);
         }

         100% {
            transform: rotate(-45deg);
         }
      }

      h1 {
         color: #243B53;
         font-weight: 700;
         font-size: 1.8rem;
      }

      p {
         color: #486581;
         font-size: 16px;
      }

      .action {
         margin-top: 18px;
      }
   }
}

@keyframes alertanimation {
   0% {
      transform: scale(0);
   }

   45% {
      transform: scale(1.05);
   }

   80% {
      transform: scale(0.95);
   }

   100% {
      transform: scale(1);
   }
}