@import '../../assets/scss/variables.scss';

.add-post-modal{
  width: 420px !important;
}

.mentionable{
  border-radius: 5px !important;
  resize: none !important;
}

.feeds-wrapper{
  position: relative;
  .feeds-top-section{
    background: $white;
    border: 1px solid #CBCBCB;
    border-radius: 12px;
    display: flex;
    align-items: center;
    padding: 15px;
    justify-content: space-around;
    position: relative;
    .user-avatar{
      width: 48px;
      height: 48px;
      min-width: 48px;
      border-radius: 48px;
      overflow: hidden;
      img{
        width: 100%;
      }
    }
    .post-add{
      width: 78%;
      @media screen and (max-width:480px) {
        margin-right: 10px;
        margin-left: 8px;
      }
      textarea{
        height: 46px;
        resize: none;
        background: #F8F8F8;
      }
    }
  }
  .feeds-container{
    .event{
      background: $white;
      border: 1px solid #DBE2EB !important;
      border-radius: 5px;
      margin-top: 15px;
      padding: 15px 0 !important;
      display: block;
      .event-head{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        padding-left: 20px;
        padding-right: 15px;
        @media screen and (max-width:640px) {
          padding-left: 15px;
        }
        .event-head-left{
          display: flex;
          .label{
            width: 48px;
            height: 48px;
            border: 48px;
            line-height: 48px;
            border-radius: 48px;
            overflow: hidden;
            margin-right: 15px;
            @media screen and (max-width:640px) {
              width: 40px;
              min-width: 40px;
              height: 40px;
              border: 40px;
              line-height: 40px;
              border-radius: 40px;
              margin-right: 10px;
            }
            img{
              height: 100%;
              width: 100%;
              object-fit: cover;
            }
          }
          .header-dtl{
            font-family: $inter-font;
            h4{
              color: #243B53;
              font-size: 18px;
              font-weight: 600;
              margin-bottom: 5px;
              font-family: $inter-font;
              a {
                color: #243B53;
              }
            }
            p{
              font-size: 13px;
              font-weight: 400;
              color: #829AB1;
              @media screen and (max-width:640px) {
                font-size: 12px;
              }
              a {
                color: #829AB1 !important;
              }
              .icon{
                width: 13px;
                margin-left: 5px;
              }
            }
          }
        }
      }
      .content{
        font-family: $inter-font;
        .microlink_card {
          border: 0;
        }
        .microlink_card__content {
          border: 0;
          box-shadow: none;
          background: #F6F8FC;
          header {
            margin-bottom: 5px;
            p {
              font-size: 17px;
              font-weight: 600;
              color: #243B53;
            }
          }
          .microlink_card__content_description {
            p {
              font-size: 15px;
              font-weight: 400;
              color: #243B53;
            }
          }
          .microlink_card__content_url {
            p {
              font-size: 15px;
              font-weight: 400;
              color: #243B53;
            }
          }
        }
        .withimage-dtl {
          font-family: $inter-font;
          .dtl-title {
            font-size: 17px;
            font-weight: 600;
            color: #243B53;
          }
          header {
            margin-bottom: 5px;
          }
          p {
            font-size: 15px;
            font-weight: 400;
          }
        }
        .feed-text-only {
          padding-bottom: 15px;
          color: #486581;
          font-size: 16px;
          font-weight: 400;
          @media screen and (max-width:640px) {
            font-size: 14px;
          }
          &.show_three_lines {
            .art-body-text {
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 2; 
                      line-clamp: 2; 
              -webkit-box-orient: vertical;
            }
          }
        }
        .content-css {
          padding-left: 20px;
          padding-right: 20px;
        }
        .feed-text-content {
          padding: 20px;
          color: #243B53;
          background: #F6F8FC;
          p {
            color: #243B53;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        .summary{
          font-weight: normal;
          margin-top: 0;
        }
        .images.extra{
          img{
            width: 100%;
          }
        }
        .meta{
          width: 100%;
        }
        .event-footer{
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-top: 1px solid $border-color-base;
          padding-top: 15px;
          padding-left: 20px;
          padding-right: 20px;
          .event-footer-left{
            display: flex;
            align-items: center;
            a {
              display: flex;
              align-items: center;
              color: #486581;
              min-width: 58px;
              font-family: $inter-font;
              font-weight: 600;
              margin-right: 18px !important;
              .icon {
                margin-right: 6px;
                width: 15px;
                height: 16px;
                fill: #334E68;
                &.comments-icon {
                  width: 14px;
                }
              }
              &.active {
                color: #486581 !important;
                .icon {
                  fill: #2166EE;
                }
              }
            }
          }
          .event-footer-right{
            a {
              display: flex;
              align-items: center;
              color: #486581;
              font-family: $inter-font;
              font-weight: 600;
              .icon {
                margin-right: 6px;
                fill: #334E68;
                width: 15px;
                height: 15px;
              }
            }
          }
        }
      }
    }
  } 
}

a.microlink_card{
  max-width: 100%;
}

.ui.stackable.grid>.column.main-content-area {
  &.event-outer-area {
    @media only screen and (max-width:767px) {
      padding: 0 0 0 0 !important;
    }
  }
}