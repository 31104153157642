@import './variables.scss';

html,
body {
    // min-height: 100vh;
    font-family: $font-family;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    font-smoothing: antialiased !important;
    -webkit-overflow-scrolling: touch;
    color: $text-color-primary;
    font-weight: normal;
    overflow-x: hidden;
}

.main-wrapper {
    overflow-x: hidden;

    @media only screen and(max-width:991px) {
        //   padding-top: 60px;
    }
}

* {
    -webkit-overflow-scrolling: touch;
}

b,
strong {
    font-weight: bold;
}

a {
    color: #2166EE;

    &:hover {
        color: darken(#2166EE, 5);
    }
}

.gradient-bg {
    background: #0098df;
    background: -moz-linear-gradient(45deg, #0098df 0%, #006dca 100%);
    background: -webkit-linear-gradient(45deg, #0098df 0%, #006dca 100%);
    background: linear-gradient(45deg, #0098df 0%, #006dca 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0098df', endColorstr='#006dca', GradientType=1);
}

.gradient-bg2 {
    background: #0098df;
    background: -moz-linear-gradient(45deg, #0098df 0%, #006dca 100%);
    background: -webkit-linear-gradient(45deg, #0098df 0%, #006dca 100%);
    background: linear-gradient(45deg, #0098df 0%, #006dca 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0098df', endColorstr='#006dca', GradientType=1);
}

.root {
    display: flex;
    flex: 0 1 100%;
    min-width: 1200px;
    // height: 100vh;
    // overflow: auto;
    // min-height: 100vh;
    overflow-x: hidden;
    flex-direction: column;
    min-height: 100vh;

    & main {
        flex: 0 1 100%;
        padding-top: 60px;
        padding-bottom: 0;
        min-height: 100vh;
    }

    &>.ant-spin-nested-loading,
    &>.ant-spin-container {
        min-height: 100vh;
    }
}

.main-loader-wrapper:not(.ant-spin-dot) {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    margin: 0 !important;
    width: 100%;
    height: 100%;
    background-color: $primary-color;
    z-index: 1200;

    & .loader {
        max-width: 450px;
        width: 100%;

        & svg {
            max-width: 450px;
            height: auto;
            margin: 0 auto;
        }

        & svg #p1 {
            stroke-dasharray: 109px;
            stroke-dashoffset: 109px;
        }

        & svg #p2 {
            stroke-width: 1;
            stroke-dasharray: 311px;
            stroke-dashoffset: 311px;
        }

        & svg #p3 {
            stroke-dasharray: 359px;
            stroke-dashoffset: 359px;
        }

        & svg #p1,
        & svg #p2,
        & svg #p3 {
            fill: transparent;
            stroke: $white;
            stroke-width: 1;
            animation-name: draw;
            animation-duration: 3s;
            animation-fill-mode: both;
            animation-iteration-count: 1;
            animation-timing-function: linear;
        }

        h1 {
            color: $white;
            opacity: 0;
            font-size: $font-size-lg;
            font-weight: 100;
            animation: fadeIn 1s ease-in-out 0s forwards;
            animation-delay: 1s;
            text-align: left;
            margin: 0;
        }
    }
}

@-webkit-keyframes draw {
    80% {
        stroke-dashoffset: 0;
        fill: transparent;
        stroke: $white;
    }

    90% {
        fill: $white;
        stroke: transparent;
    }

    100% {
        fill: $white;
        stroke: transparent;
    }
}

@-moz-keyframes draw {
    80% {
        stroke-dashoffset: 0;
        fill: transparent;
        stroke: $white;
    }

    90% {
        fill: $white;
        stroke: transparent;
    }

    100% {
        fill: $white;
        stroke: transparent;
    }
}

@-o-keyframes draw {
    80% {
        stroke-dashoffset: 0;
        fill: transparent;
        stroke: $white;
    }

    90% {
        fill: $white;
        stroke: transparent;
    }

    100% {
        fill: $white;
        stroke: transparent;
    }
}

@keyframes draw {
    80% {
        stroke-dashoffset: 0;
        fill: transparent;
        stroke: $white;
    }

    90% {
        fill: $white;
        stroke: transparent;
    }

    100% {
        fill: $white;
        stroke: transparent;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.common-container {
    width: 100%;
    padding: 0px 50px;
}

.visibility-hidden {
    visibility: hidden;
}

.row {
    &.gutter-sm {
        margin-left: -10px;
        margin-right: -10px;

        &>[class^='col-'] {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}

.icon {
    width: 16px;
    height: auto;
    fill: #000;
    transition: fill 0.3s;

    &.icon-white {
        fill: #fff;
    }
}

.vw-100 {
    width: 100vw;
}

.vh-100 {
    height: 100vh;
}

.w-100 {
    width: 100%;
}

.h-100 {
    height: 100%;
}

.mw-100 {
    max-width: 100%;
}

.mh-100 {
    max-height: 100%;
}

.min-vw-100 {
    min-width: 100vw;
}

.min-vh-100 {
    min-height: 100vh;
}

.mr-8px {
    margin-right: 8px;
}

.mt-8px {
    margin-top: 8px;
}

.mb-8px {
    margin-bottom: 8px;
}

.ml-8px {
    margin-left: 8px;
}

.mr-10px {
    margin-right: 10px;
}

.mt-10px {
    margin-top: 10px;
}

.mt-15px {
    margin-top: 15px;
}

.mb-10px {
    margin-bottom: 10px;
}

.ml-10px {
    margin-left: 10px;
}

.mr-20px {
    margin-right: 20px;
}

.mt-20px {
    margin-top: 20px;
}

.mb-20px {
    margin-bottom: 20px;
}

.ml-20px {
    margin-left: 20px;
}

.mr-30px {
    margin-right: 30px;
}

.mt-30px {
    margin-top: 30px;
}

.mb-30px {
    margin-bottom: 30px;
}

.ml-30px {
    margin-left: 30px;
}

.pr-40 {
    padding-right: 40px;
}

.pl-40 {
    padding-left: 40px;
}

.cursor-pointer {
    cursor: pointer;
}

.bt-1 {
    border-top: 1px solid $border-color;
}

.bb-1 {
    border-bottom: 1px solid $border-color;
}

.br-1 {
    border-right: 1px solid $border-color;
}

.bl-1 {
    border-left: 1px solid $border-color;
}

.link-btn {
    cursor: pointer;
    text-decoration: underline;
    color: $link-color-blue;
    font-size: $font-size-sm;
    font-weight: 400;
    line-height: 1;
    display: flex;
    align-items: center;
    transition: 300ms all ease-in-out;

    &:hover,
    &:active,
    &:focus {
        color: darken($link-color-blue, 6%);
    }
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.text-black {
    color: #000;
}

.gray-bg {
    background-color: #f6f6f6;

    &.border-bottom {
        border-bottom: solid 1px #dddddd;
    }
}

.over-flow-hidden {
    overflow: hidden;
}

.transform-horizontal {
    transform: rotateY(180deg);
}

.text-green {
    color: #03c281;
}

.text-orange {
    color: #e37107;
}

.ReactCollapse--collapse {
    transition: all 300ms ease-in-out;
}

.border-box {
    border-radius: 2px;
    border: solid 1px #dddddd;

    &.mb-20 {
        margin-bottom: 20px;

        &.h-100 {
            height: initial !important;
            min-height: calc(100% - 20px);
        }
    }
}

.fs-13 {
    font-size: $font-size-base - 1;
}

.fs-10 {
    font-size: $font-size-sm - 2;
}

.fs-12 {
    font-size: $font-size-sm;
}

.fs-14 {
    font-size: $font-size-sm + 2;
}

.fs-16 {
    font-size: $font-size-base + 2;
}

.fw-light {
    font-weight: 100;
}

.fw-regular {
    font-weight: 400;
}

.fw-medium {
    font-weight: 500;
}

.fw-bold {
    font-weight: bold;
}

body input::-webkit-input-placeholder {
    color: #3e3e3e71 !important;
    font-weight: 300;
}

body input:-ms-input-placeholder {
    color: #3e3e3e71 !important;
    font-weight: 300;
}

body input::placeholder {
    color: #3e3e3e71 !important;
    font-weight: 300;
}

.ui.dropdown:not(.button)>.default.text {
    color: #3e3e3e71 !important;
}

.custom-select {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    display: flex;
    padding-left: 11px;

    &>svg {
        fill: $black;
        opacity: 0.3;
    }

    &.br-2 {
        border-radius: 2px;
    }

    &.large-select {
        & .ant-select-lg {
            font-size: $font-size-base - 1;
        }
    }
}

.ui.segment {
    margin: auto;
}

.text-1 {
    font-family: 'Arial';
    font-size: 1.5rem;
    color: $text-color-primary;

    @media screen and (max-width: 640px) {
        font-size: 1.2rem;
    }
}

.text-2 {
    font-family: 'Arial';
    font-size: 1.25rem;
    color: $text-color-primary;
}

.react-resizable {
    position: relative;
    background-clip: padding-box;
}

.react-resizable-handle {
    position: absolute;
    width: 10px;
    height: 100%;
    bottom: 0;
    right: -5px;
    cursor: col-resize;
    z-index: 2;
}

//Ant Btn
.ui.button.custom-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 0;
    padding-top: 0;
    height: 40px;
    line-height: 100%;
    text-transform: uppercase;
    font-family: 'Arial';
    border-radius: 4px;
    border-width: 1px;
    box-shadow: none !important;
    font-weight: 500 !important;
    padding: 0 25px;

    &.fw-bold {
        font-weight: bold !important;
    }

    &.primary {
        border-color: $primary-color;
        background: $primary-color;
        color: $white;

        &:hover,
        &:active,
        &:focus {
            border-color: $primary-color;
            background: $primary-color-hover;
            color: $white;
            box-shadow: none;
        }
    }

    &.secondary {
        border-color: $secondary-color;
        background: $secondary-color;
        color: $white;
    }

    &.basic {
        box-shadow: none !important;
        border: 2px solid $primary-color;

        &.secondary {
            border-color: $secondary-color;
            box-shadow: none !important;
            background: $secondary-color;
        }
    }

    &:not(.ant-btn-circle) {
        border-radius: $btn-border-radius-base;
    }

    &.ant-btn-icon-only {
        padding: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    &.btn-success {
        border-color: $success-color;
        background-color: $success-color;
        color: $white;

        &:hover,
        &:active,
        &:focus {
            border-color: darken($success-color, 6%);
            background-color: darken($success-color, 6%);
        }
    }

    &.btn-lg {
        height: 50px;
        padding-left: 2.5rem;
        padding-right: 2.5rem;

        @media screen and (max-width: 1377px) {
            height: 46px;
        }

        @media screen and (max-width: 991px) {
            padding-left: 1.2rem;
            padding-right: 1.2rem;
        }
    }

    &.btn-sm {
        height: 40px;
        font-size: $font-size-sm - 2;
        letter-spacing: 0.5px;
        line-height: 1;
        font-weight: normal;
    }

    &.alternate-btn {
        font-size: $font-size-base;
        font-weight: bold;
        line-height: 1px;
        border-color: $text-color-secondary;
        color: $text-color-secondary;

        &:hover,
        &:focus,
        &:active {
            border-color: $primary-color;
            color: $primary-color;
        }
    }

    &.btn-transparent {
        background-color: transparent;
        border: none;
        padding: 0;

        &>svg {
            fill: $primary-color;
        }

        &:hover,
        &:focus,
        &:active {
            &>svg {
                fill: lighten($primary-color, 30%);
            }
        }
    }
}

.btn-link {
    color: $primary-color;
    padding: 0;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    line-height: 1;
    transition: 300ms all ease-in-out;

    &:hover,
    &:active,
    &:focus {
        color: lighten($primary-color, 10%);

        @media screen and (min-width: 1200px) {
            outline: none;
        }
    }
}

.common-back-btn {
    font-weight: bold;
    font-size: $font-size-lg;
    color: $text-color-primary;
    display: flex;
    align-items: center;
    line-height: 1;

    &>svg {
        fill: $primary-color;
        margin-right: 5px;
        margin-left: -3px;
    }

    &>span {
        display: flex;
        align-items: center;
        line-height: 1px;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.container-common {
    max-width: 1280px;
    margin: auto;
    width: 95%;
}

.form-input.ui {
    input {
        height: 46px;
        border: 2px solid $border-color;
        font-size: 14px;

        @media screen and (max-width:1377px) {
            height: 42px;
        }
    }

    .dropdown {
        height: 46px;
        border: 2px solid $border-color;
        border-radius: 0.28571429rem;
        padding: 0.67857143em 1em;
        font-size: 14px;
        background-image: none;

        @media screen and (max-width:1377px) {
            height: 42px;
        }

        .text {
            height: 24px;
            line-height: 24px;
        }
    }
}

.text-inherit {
    text-transform: inherit !important;
}

.container {
    @media only screen and (max-width: 991px) {
        max-width: 92%;
    }
}

.text-small-left {
    @media screen and (max-width: 767px) {
        text-align: left !important;
    }
}

.error,
.error input {
    background: #fff6f6 !important;
    border-color: #e0b4b4 !important;
    color: #9f3a38 !important;
}

.ui.grid {
    .row {
        margin-left: 0;
        margin-right: 0;
        display: block;
    }
}

.notification-loadmore {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #fff;
    height: 50px;
    text-align: center;
}

.postcontainer-section {
    &.fixTop {
        .secondary {
            &.menu {
                position: fixed;
                top: 50px;
                background: #fff;
                width: 100%;
                z-index: 9;
                max-width: 620px;

                .item {
                    .icon {
                        fill: #818181;
                    }
                }
            }
        }
    }
}

.tribute-container {
    position: absolute;
    top: 0;
    left: 0;
    height: auto;
    max-height: 300px;
    max-width: 500px;
    overflow: auto;
    display: block;
    z-index: 999999;
}

.tribute-container ul {
    margin: 0;
    margin-top: 2px;
    padding: 0;
    list-style: none;
    background: #efefef;
}

.tribute-container li {
    padding: 5px 5px;
    cursor: pointer;
}

.tribute-container li.highlight {
    background: #ddd;
}

.tribute-container li span {
    font-weight: bold;
}

.tribute-container li.no-match {
    cursor: default;
}

.tribute-container .menu-highlighted {
    font-weight: bold;
}

.mentionable {
    padding: 10px 0;
    outline: none;
    /* border: 1px solid #ddd; */
    border: 0;
    min-height: 200px;
    position: relative;
    font-family: $inter-font;
}

.mentionable:empty:before {
    content: attr(data-placeholder);
    color: #DBE2EB;
    font-size: 16px;
}

.login_button {
    padding-left: 4px !important;
    padding-right: 4px !important;
    margin-right: 0 !important;

    &.load_more {
        text-align: center;
        padding: 0;
        font-size: 15px;
        font-weight: 600 !important;
        margin: 0 !important;
        font-family: $inter-font;
        color: #2166EE !important;
    }
}

.border-1-radius {
    border: 1px solid #DBE2EB;
    border-radius: 14px;
}

.ui.grid.stackable>.column.left-content>.column.left-content {
    font-family: $inter-font !important;
}

.ui.grid.stackable>.column.right-content {
    font-family: $inter-font !important;
    padding: 0;
}

.ui.button {
    &.load_more {
        color: #2166EE !important;
        font-weight: 600 !important;
        font-size: 15px;
    }
}

.feed-text-only {
    &.show_three_lines {
        .art-body-text {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }
}

.primary {
    &.btn-primary {
        background: #2166EE;
        color: $white;
        border-radius: 6px;
        height: 48px;
        line-height: 48px;
        padding: 0 30px;
        font-size: 15px;
        font-weight: 600;
    }
}