.cropper-img {
    max-width: 350px;
}

.img-cropper-view{
    flex-direction: column;
}

.img-cropper-view .ui.button{
    color: #2699FB;
    border-color: #2699FB !important;
    padding: 9px 15px;
}

.img-cropper-view .cropper {
    width: 60%;
}

.img-cropper-view .cropper-img {
    width: 30%;
}

.img-cropper-view .cropper > img {
    display: none;
}

.cropper-img {
    max-height: 275px;
    overflow: hidden;
}

.cropper-container {
    overflow: hidden;
}