@import '../../assets/scss/variables.scss';

.emailselect-list{
    tr.selected{
        background: #E0EFFB !important;
        td{
            background: #E0EFFB !important;
        }
    }
}

.tocontact-wrapper{
    .product-name-mail{
        color: #1A1642;
        font-size: 1.6rem;
        font-weight: 600;
    }
    .expand-full{
        color: $primary-color;
        font-weight: 600;
        margin-top: 5px;
        font-size: $font-size-base + 1;
    }
    .to-contactlist{
        .list{
            a{
                &.item{
                    margin-left: 0;
                    margin-right: 0.5rem;
                    .clst-inner{
                        border: 1px solid $border-color;
                        color: $text-color-primary;
                        padding: 2px 6px !important;
                        border-radius: 20px;
                        .icon{
                            width: 10px;
                            fill: $text-gray;
                        }
                    }
                }
            }
        }
    }
}

.uploadWrapper {
    display: inline-block;
    margin-top: 3px;
    width: 100%;
    border: 2px dashed $border-color;
    background: #FCFCFC;
    padding: 20px;
    text-align: center;
    border-radius: 6px;
    .remove-icon {
        cursor: pointer;
        width: 24px;
        height: 24px;
        position: absolute;
        right: -6px;
        top: -4px;
        display: flex;
        align-content: center;
        justify-content: center;
        padding: 6px;
        border-radius: 24px;
        background: red;
        .icon {
          fill:#fff;
        }
        &:hover {
          opacity: 0.8;
        }
      }
    .uploaded-inner {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 8px;
      .upload-preview{
            position: absolute;
            z-index: 1;
            width: 100%;
            height: 100%;
            background: #FCFCFC;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .upload-btn {
      .upload-btn-inner {
        position: relative;
        display: flex;
        align-items: center;
        .icon{
            width: 55px;
            margin-right: 10px;
            fill: #e4e7ea;
        }
        p{
            margin-bottom: 0;
        }
        input {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          z-index: 3;
          opacity: 0;
          width: 100%;
          height: 100%;
          cursor: pointer;
          filter: alpha(opacity=1);
          -moz-opacity: 0.01;
          opacity: 0.01;
          font-size: 0;
        }
      }
    }
  }