@import '../../../assets/scss/variables.scss';

.forgot-password{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 60px);
    .forgot-password-inner{
        max-width: 340px;
        margin:auto;
        h2{
            color: $secondary-color;
            text-align: center;
            font-size: 2rem;
        }
        .password-hint{
            i{
                color: $primary-color;
                font-size: 1.2rem;
            }
        }
    }
}