@import '../../../../assets/scss//variables.scss';

.main-footer{
    background: $secondary-color;
    padding: 50px 60px;
    color: $white;
    @media screen and (max-width:767px){
        padding: 40px 30px;
    }
    a{
        color: $white;
    }
    .footer-wrapper{
        display: flex;
        justify-content: space-between;
        @media screen and (max-width:767px){
            display: block;   
        }
        .footer-left{
            width: 50%;
            display: flex;
            justify-content: space-between;
            max-width: 500px;
            @media screen and (max-width:767px){
                width: 100%;
            }
            .footer-logo{
                .icon{
                    fill: $white;
                    width: 65px;
                }
                &.lg-device{
                    @media screen and (max-width:767px){
                        display: none;   
                    }
                }
            }
            .footer-links{
                margin: 0;
                a{
                    color: $white;
                    display: flex;
                    align-items: center;
                    padding-bottom: 8px;
                    svg{
                        fill: $white;
                        height: 20px;
                        margin-right: 15px;
                    }
                }
            }
        }
        .footer-right{
            text-align: right;
            @media screen and (max-width:767px){
                display: flex;
                justify-content: space-between;
                border-top: 1px solid $white;
                padding-top: 30px;
                margin-top: 30px;
            }
            .small-device{
                display: none;
                @media screen and (max-width:767px){
                    display: block;   
                    .icon{
                        width: 50px;
                    }
                }
            }
            .footer-right-link{
                text-align: right;
                margin-top: 60px;
                display: block;
                font-size: 10px;
                @media screen and (max-width:767px){
                    margin-top: 10px;
                }
                a{
                    color: $white;
                }
            }
        }
    }
}