@import '../../../assets/scss/variables.scss';

.signup-wrapper{
    display: flex;
    min-height: 100vh;
    // margin-top: -60px;
    @media screen and (max-width:767px){
        display: block;
    }
    .signup-left{
        width: 48%;
        position: relative;
        background: url("../../../assets/images/signupbg.jpg");
        background-size: cover;
        @media screen and (max-width:767px){
            width: 100%;
        }
        .signupleft-inner{
            padding: 15%;
            .icon{
                width: 280px;
                fill: $secondary-color;
                @media screen and (max-width:767px){
                    width: 230px;
                }
            }
            h2{
                transform: translateY(40vh);
                color: $blue-dark;
                font-size: 2.5rem;
                font-weight: 600;
                @media screen and (max-width:767px){
                    transform: translateY(5vh);
                    font-size: 1.8rem;
                }
            }
        }
    }
    .signup-right{
        width: 52%;
        display: flex;
        @media screen and (max-width:767px){
            width: 100%;
        }
        .signupright-inner{
            max-width: 440px;
            margin:auto;
            padding-top: 6%;
            padding-bottom: 3%;
            @media screen and (max-width:1377px){
                padding-top: 4%;
            }
            @media screen and (max-width:767px){
                padding: 15px;
            }
            &.otpverify-inner{
                max-width: 420px;
                min-height: 500px;
                display: flex;
                flex-direction: column;
                h3{
                    margin-bottom: 2rem;
                }
            }
            &.messge-thanku{
                padding-top: 0;
                padding-bottom: 0;
                height: 100%;
                display: flex;
                align-items: center;
                max-width: 455px;
                .title{
                    h3{
                        font-size: 32px;
                    }
                    p{
                        font-size: 22px;
                    }
                }
                .button {
                    font-size: 16px;
                }
                @media screen and (max-width:767px){
                    padding-top: 30px;
                }
            }
            .back-arrow{
                margin-right: 10px;
                margin-bottom: 20px;
                width: 40px;
                .icon {
                    width: 24px;
                }
            }
            .title{
                h3{
                    color: black;
                    font-size: 1.68rem;
                    font-weight: 600;
                    display: flex;
                    align-items: center;
                    @media screen and (max-width:1377px){
                        font-size: 1.28rem;
                    }
                }
                p{
                    font-size: 1.15rem;
                    @media screen and (max-width:1377px){
                        font-size: 1.08rem;
                    }
                }
            }
            .password-strength{
                display: grid;
                grid-template-columns: auto auto auto auto auto;
                list-style-type: none;
                grid-gap:0 6px;
                padding-left: 0;
                margin-bottom: 5px;
                li{
                    height: 5px;
                    background: #e6e6e6;
                }
            }
            .otp-input{
                margin-top: 12px;
                input {
                    padding: 5px;
                    width: 50px;
                    height: 50px;
                    min-width: 50px;
                    margin-right: 12px;
                    border: 1px solid #707070;
                    border-radius: 6px;
                    @media screen and (max-width:420px) {
                        min-width: 40px;
                        height: 40px;
                        width: 40px;
                    }
                    & ~ span{
                        display: none;
                    }
                }
            }
            .bycliking-text{
                font-size: 14px;
                font-weight: 500;
            }
            .auth-btn{
                height: 52px;
                border-radius: 6px;
            }
        }
    }
}