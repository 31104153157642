.react-feed-player-container {
   background-color: rgba(0, 0, 0, 9);
   padding-bottom: 40px;
   position: relative;
   padding-top: 56.24%;

   .react-player {
      position: absolute;
      top: -20px;
      left: 0;
    }

   .iris-button,
   .vp-sidedock {
      display: none !important;
   }

   .myvideo {
      width: 100%;
      height: 100;
   }

   video {
      width: 100%;
      height: 100vh;
      object-fit: cover;
   }

   .content {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      color: #fff;
   }

   .video-player {
      display: flex;
      align-items: left;
      justify-content: left;
      text-align: left;
   }

   .react-feed-player-control {
      position: absolute;
      bottom: 0;
      color: white;
      display: flex;
      align-items: center;
      width: 100%;
      margin: auto;
      height: 45px;
   }

   .react-feed-player-control button {
      background: none;
      color: #ffffff;
      border: 0;
      font-size: 12px;
      border-radius: 8px;
      cursor: pointer;
      width: 32px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
   }

   .react-feed-player-control button:hover {
      opacity: 0.8;
   }

   .react-feed-player-control button svg {
      fill: #ffffff;
      height: 18px;
   }

   .volume-player {
      display: flex;
      align-items: center;
      width: 40px;
      justify-content: center;
      position: relative;
   }

   .volume-player input {
      display: none;
      transform: rotate(-90deg);
      position: absolute;
      bottom: 80px;
      height: 3px;
   }

   .volume-player input:focus {
      display: block;
   }

   .volume-player:hover input,
   .volume-player:focus input {
      display: block;
   }

   .react-feed-player-control .player-range {
      width: 100%;
      position: absolute;
      height: 2px;
      top: 6px;
      border-radius: 0;
   }

   .react-feed-player-container {
      display: inline-block;
   }

   .action-row{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-top: 5px;
      padding: 0 15px 0 18px;
      .action-row-left{
         display: flex;
         align-items: center;
         .playicon{
            width: 25px;
         }
         .stopicon{
            .icon{
               width: 16px;
            }
         }
      }
      .video-time{
         font-size: 13px;
         color: rgba(#ffffff, 0.74);
      }
   }

}