.node-title {
   dx: -200;
   fill: green;
}

.node__root>circle {
   fill: blue;
}

/* .node__branch>circle {
   fill: darkblue;
}

.node__leaf>circle {
   fill: grey;
    r: 40;
}
.custom-name-class{
   width: 50px;
} */
 
.nodeHeading{
   text-align: "center";
   float: 'left';
   font-family: '"Inter", sans-serif';
}
