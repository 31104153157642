@import '../../..//assets/scss/variables.scss';

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

.article-editor{
  margin-top: 5rem;
  position: relative;
  * {
    font-family: 'Inter', sans-serif;
  }
  .control-wrapper {
    display: flex;
    align-items: center;
    border: 1px solid #DBE2EB;
    padding: 4px 8px;
    border-radius: 6px;
    .button {
      background: none;
      padding: 10px;
    }
    select {
      font-size: 14px;
      color: #334E68;
      font-weight: 500;
      border: 0;
      box-shadow: none;
      outline: none;
      margin-right: 15px;
    }
    .icon {
      fill: #334E68;
      opacity: 1;
      height: 16px !important;
      width: 18px;
    }
  }
  .RichEditor-editor {
    margin-top: 1.5rem;
    color: #243B53;
    h1{
      font-size: 32px;
      font-weight: 700;
      color: #243B53;
    }
    h2{
      font-size: 24px;
      font-weight: 600;
      color: #243B53;
    }
    h3{
      font-size: 20px;
      font-weight: 600;
      color: #243B53;
    }
    h4{
      font-size: 16px;
      font-weight: 500;
      color: #243B53;
    }
    h5{
      font-size: 14px;
      font-weight: 500;
      color: #243B53;
    }
    
    .mentionable{
      background-color: white;
      border: 0.5px solid #ccc;
      padding: 5px;
    }
    
  }
  .public-DraftEditorPlaceholder-inner {
    color: #DBE2EB !important;
    font-weight: 500;
    background-color: white;
  }
  .editorupper-part {
   /*  position: absolute;
    top: 80px; */
    z-index: 0;
    width: 100%;
    .heading-input {
      font-size: 30px;
      font-weight: 700;
      border: 0;
      outline: none;
      box-shadow: none;
      margin-bottom: 0.5rem;
      background: none;
      width: 100%;
      position: relative;
      z-index: 2;
      &::-webkit-placeholder {
        color: #829AB1 !important;
      }
      &::-moz-placeholder {
        color: #829AB1 !important;
      }
      &::placeholder {
        color: #829AB1 !important;
        font-size: 30px;
        font-weight: 700;
      }
    }
    .imageupload-card {
      background: #FFFFFF;
      border: 3px dashed #DBE2EB;
      border-radius: 6px;
      padding: 2rem;
      text-align: center;
      max-width: 880px;
      margin: 0 auto 1rem auto;
      position: relative;
      .imgviewer-uploaded {
        max-height: 308px;
        display: flex;
        img {
          max-height: 100%;
          width: auto;
          object-fit: contain;
        }
      }
      .remove-btn {
        width: 30px;
        height: 30px;
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        text-align: center;
        position: absolute;
        z-index: 2;
        right: 10px;
        top: 10px;
        background: $primary-color;
        transition: all ease-in 0.1s;
        &:hover {
          opacity: 0.8;
        }
        .icon {
          margin: auto !important;
          fill: $white;
          opacity: 1;
        }
      }
      .imageupload-cricle {
        width: 80px;
        height: 80px;
        border-radius: 80px;
        background: #F3F7FF;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto auto 1rem auto;
        @media screen and (max-width:991px) {
          width: 65px;
          height: 65px;
        }
        .icon {
          width: 45px;
          @media screen and (max-width:991px) {
            width: 32px;
          }
        }
      }
      p {
        color: #243B53;
        font-size: 14px;
        line-height: 1.8;
        font-weight: 600;
        font-family: $inter-font;
        max-width: 670px;
        margin-left: auto;
        margin-right: auto;
        @media screen and (max-width:991px) {
          font-size: 13px;
        }
        @media screen and (max-width:640px) {
          font-size: 11px;
          line-height: 1.6;
        }
        &.small-p {
          font-size: 12px;
          color: #486581;
          @media screen and (max-width:640px) {
            font-size: 11px;
            line-height: 1.6;
          }
        }
        .browse-link {
          color: $primary-color;
        }
      }
      .browse-btn {
        color: #2166EE;
        border: 0;
        background: none;
        padding: 0;
        font-weight: 600;
      }
    }
  }

  .heading-main{
    margin-bottom: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // position: sticky;
    // top: 80px;
    h2{
      font-size: 24px;
      color: #243B53;
      font-weight: 700;
      display: flex;
      align-items: center;
      margin: 0;
      span {
        font-size: 16px;
        color: #829AB1;
        font-weight: 500;
        position: relative;
        padding-left: 14px;
        margin-left: 18px;
        &::before {
          content: ' ';
          width: 5px;
          height: 5px;
          border-radius: 5px;
          background: #829AB1;
          display: inline-block;
          position: absolute;
          left: 0;
          top: 50%;
        }
      }
    }
    .button {
      font-weight: 500 !important;
      font-family: $inter-font;
     /*  position: absolute; */
      top: 56px;
      right: 6px;
      z-index: 2;
    }
  } 
  .rdw-editor-toolbar{
    a {
      color: #334E68;
      font-weight: 600;
    }
  }
  .rdw-editor-wrapper{
    .rdw-editor-toolbar{
      border: 1px solid #DBE2EB;
      border-radius: 6px;
      .rdw-dropdown-wrapper{
        border: 0;
      }
    }
    color: #486581;
    font-size: 18px;
    font-weight: 500;
    h1, h2, h3, h4, h5, h6{
      color: #243B53;
      font-weight: 700;
    }
  }
  .editor-card{
    position: relative;
    overflow-y: auto;
    max-height: 82vh;
    min-height: 82vh;
    overflow: -moz-scrollbars-none; 
    &::-webkit-scrollbar { width: 0 !important }
    .RichEditor-editor {
      font-family: $inter-font;
      position: sticky;
      top: 0;
      margin-top: 0;
      .wrapper {
        .fr-box {
          .fr-toolbar {
            border: 1px solid #DBE2EB;
            border-radius: 6px;
            padding-right: 145px;
            top: 0px;
            position: sticky;
            z-index: 9;
            .fr-btn-grp {
              margin: 0 8px;
            }
            button {
              svg {
                margin: 6px 6px;
                width: 23px;
                path {
                  fill: #334E68;
                }
              }
            }
          }
        }
        .fr-wrapper {
          border: 0;
          margin-top: 460px;
          @media screen and (max-width:991px) {
            margin-top: 440px;
          }
          div:nth-child(1) {
            a {
              /* display: none !important; */
              height: 0 !important;
            }
          }
          .fr-placeholder {
            padding: 1.5rem 0 1rem 0 !important;
            color: #829AB1;
            font-size: 16px !important;
            font-weight: 500 !important;
            font-family: $inter-font;
            white-space: initial;
            line-height: 1.6 !important;
          }
          .fr-element {
            padding: 1.5rem 0 1rem 0;
            color: #829AB1;
            font-family: $inter-font;
          }
        }
      }
    }
    .action-btns{
      position: absolute;
      display: flex;
      align-items: center;
      top: 5px;
      right: 6px;
      .button {
        font-weight: 600;
        border-radius: 6px;
        margin-right: 0;
        margin-left: 8px;
      }
    }
  }
}

.article-view {
  max-width: 100% !important;
  width: 100%;
}