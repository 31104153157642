@import '../../../../assets/scss//variables.scss';

.productlist-mobile {
    position: fixed;
    height: 100vh;
    width: 100vw;
    right: -100%;
    top: 0;
    z-index: 9;
    // background: #00000080;
    transition: all ease-in 0.2s;
    &.opened {
        right: 0;
    }
    .productlist-mobile-wrapper {
        height: 100vh;
        background: $white;
        width: 100vw;
        margin-left: auto;
        font-family: $inter-font;
        .bar-header {
            height: 60px;
            cursor: pointer;
            color: #0D1A35;
            display: flex;
            align-items: center;
            padding: 0 1.5rem;
            font-size: 1.3rem;
            font-weight: 700;
            border-bottom: 1px solid #E5E8F0;
            .icon {
                fill: #0D1A35;
                width: 9px;
                margin-right: 12px;
            }
        }
        .bar-body {
            height: calc(100vh - 60px);
            max-height: calc(100vh - 60px);
            overflow-y: auto;
            overflow-y: auto;
            background: #F6F8FC;
            padding: 0 1rem 1rem 1rem;
            position: relative;
            display: flex;
            flex-direction: column;
            .list-header {
                position: sticky;
                padding-top: 1rem;
                top: 0;
                background: #F6F8FC;
                p {
                    margin-top: 10px;
                    color: #486581;
                    font-size: 13px;
                    font-weight: 500;
                    span {
                        font-weight: 700;
                    }
                }
            }
            .product-list {
                list-style-type: none;
                padding-left: 0;
                background: $white;
                border: 1px solid #E5E8F0;
                border-radius: 12px;
                li {
                    border-bottom: 1px solid #E5E8F0;
                    background: url('data:image/svg+xml,<svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.23429 0.234315C-0.0781293 0.546734 -0.0781293 1.05327 0.23429 1.36569L4.1686 5.3L0.23429 9.23431C-0.0781293 9.54673 -0.0781293 10.0533 0.23429 10.3657C0.54671 10.6781 1.05324 10.6781 1.36566 10.3657L5.86566 5.86569C6.17808 5.55327 6.17808 5.04673 5.86566 4.73431L1.36566 0.234315C1.05324 -0.0781049 0.54671 -0.0781049 0.23429 0.234315Z" fill="%23A3B2C6"/></svg>');
                    background-position: 96% center;
                    background-repeat: no-repeat;
                    &:last-child {
                        border: 0;
                    }
                    a {
                        color: #243B53;
                        font-weight: 600;
                        font-size: 14px;
                        padding: 1rem 1.2rem;
                        display: block;
                        width: 100%;
                    }
                }
            }
        }
    }
}