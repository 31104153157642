@import '../../assets/scss/variables.scss';

.grey-bg {
	background: #F6F8FC;

	.main-content {
		background: none;
	}

	.main-content_inner {
		background: none;
	}
}

.add-event-wrapper {
	background: none;
	padding: 20px;
	border: 0;
	border-radius: 15px;
	font-family: $inter-font;

	h1 {
		font-weight: 700;
		font-family: $inter-font;
		color: #1A1642;
		font-size: 20px;
		margin-bottom: 5px;
	}

	.row {
		display: flex !important;
		margin-left: -15px !important;
		margin-right: -15px !important;
	}

	p {
		font-family: $inter-font;
	}

	h1.ui.header {
		font-size: 20px;
		font-weight: 600;
		color: #243B53;
	}

	h5.ui.header {
		font-size: 18px;
		font-weight: 600;
	}

	h3 {
		color: #243B53;
		font-weight: 600;
		font-family: $inter-font;
		font-size: 16px;
		margin-bottom: 12px;
	}

	.btn-submit {
		background: #2166EE;
		opacity: 1;
		font-size: 16px;
		font-weight: 600;
		color: $white;
		font-family: $inter-font;
		border-radius: 6px;
		height: 50px;
		padding: 0 18px;
	}

	.btn-cancel {
		border: 1px solid #DBE2EB;
		background: $white;
		color: #829AB1;
		opacity: 1;
		font-size: 16px;
		font-weight: 600;
		font-family: $inter-font;
		border-radius: 6px;
		height: 50px;
		padding: 0 18px;
		min-width: 150px;
		margin-right: 10px;
	}

	.sub-title {
		font-weight: 500;
		color: #486581;
	}

	.field {
		margin-bottom: 15px !important;

		label {
			font-family: $inter-font !important;
		}

		.input {
			input {
				min-height: 42px;
				border: 1px solid #DBE2EB;
				border-radius: 6px;
				font-family: $inter-font;

				&:focus {
					border-color: $primary-color;
					border-radius: 6px;
				}

				&::placeholder {
					color: #A3B2C6 !important;
					font-weight: 500 !important;
					opacity: 1;
				}
			}
		}

		.search {
			min-height: 42px;
			background-color: white;
			border: 1px solid #DBE2EB;
			border-radius: 6px;
			font-family: $inter-font;
			background-image: none;

			&:focus, &.active, &:hover {
				border-color: $primary-color;
				border-radius: 6px;
			}

			.text[aria-live="polite"] {
				padding-top: 0 !important;
				color: #565656 !important;
				min-height: 40px;
				line-height: 40px;
			}

			&::placeholder {
				color: #A3B2C6 !important;
				font-weight: 500 !important;
				opacity: 1;
			}

			.item {
				line-height: 1.3;
				.text {
					padding-left: 0 !important;
				}
			}
			
		}

		textarea {
			border: 1px solid #DBE2EB;
			border-radius: 6px;
			font-family: $inter-font;
			outline: none;

			&:focus {
				border-color: $primary-color;
				border-radius: 6px;
				padding-left: 10px;
			}

			&::placeholder {
				color: #A3B2C6;
				font-weight: 500;
				opacity: 1;
				padding-left: 10px;
			}
		}

		.ui.selection.dropdown {
			.text {
				padding-left: 13px;
				font-weight: 400;
				font-size: 15px;
				color: black;
				padding-top: 5px;
			}
		}
	}

	.add-event-url {
		.input {
			width: 100%;

			input {
				width: 100%;
				height: 42px;
				background: #FBFBF8;
				border-radius: 6px;
				font-size: 14px;

				&::placeholder {
					color: #D9D9D9;
				}
			}
		}
	}

	.datetime-row {
		position: relative;

		label {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 15px;
			z-index: 1;
			background: $white;
			color: #A3B2C6 !important;
			font-weight: 500;
			font-family: $inter-font;
			width: calc(100% - 80px);
			font-size: 14px;
		}

		input.form-control {
			width: 100%;
			height: 42px;
			border-radius: 6px;
			font-size: 14px;
			background: $white;

			&::placeholder {
				color: #A3B2C6 !important;
				font-weight: 500;
				font-family: $inter-font;
				font-size: 14px;
			}
		}

		.rdtPicker {
			color: #243B53;
		}
	}

	.add-event-image-wrapper {
		position: relative;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-direction: column;

		.addevent-img-row {
			border: 2px dashed #DBE2EB;
			border-radius: 6px;
			padding: 10px;
			margin-top: 10px;
			width: 100%;

			@media screen and (max-width:991px) {
				padding: 18px;
			}

			.thumbs-list {
				display: flex;
				list-style-type: none;
				padding-left: 0;
				grid-gap: 8px;
				margin-top: 10px;
				margin-bottom: 0;
				flex-wrap: wrap;

				li {
					width: 60px;
					height: 44px;
					border-radius: 5px;
					position: relative;

					&.add-images-item {
						border: 1px solid #DBE2EB;
						background: $white;
						display: flex;
						align-items: center;
						justify-content: center;
						cursor: pointer;

						.icon {
							fill: #829AB1;
							position: absolute;
							cursor: pointer;
						}

						label {
							input {
								opacity: 0;
							}
						}
					}

					.remove-img {
						position: absolute;
						width: 20px;
						height: 20px;
						border-radius: 20px;
						display: flex;
						align-items: center;
						justify-content: center;
						background: #FFFFFF;
						box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
						right: -8px;
						top: -8px;
						cursor: pointer;
						visibility: hidden;
						transition: all ease-in 0.1s;

						&:hover {
							background: #829AB1;

							.icon {
								fill: $white;
							}
						}

						.icon {
							fill: #829AB1;
							width: 8px;
							height: 8px;
						}
					}

					&:hover {
						.remove-img {
							visibility: visible;
						}
					}

					img {
						width: 60px;
						height: 44px;
						object-fit: cover;
						border-radius: 5px;
					}

					&:nth-child(6n+1) {
						background: #C7B2EA;
					}

					&:nth-child(6n+2) {
						background: #B2D2EA;
					}

					&:nth-child(6n+3) {
						background: #B2EAC2;
					}

					&:nth-child(6n+4) {
						background: #EACDB2;
					}

					&:nth-child(6n+5) {
						background: #FFBCB8;
					}
				}
			}

			.addevent-imgrow-inner {
				display: flex;
				align-items: center;

				@media screen and (max-width:991px) {
					display: block;
				}
			}

			.addevent-col-left {
				max-width: 200px;
				min-width: 200px;
				min-height: 150px;
				border: 1px solid #DBE2EB;
				border-radius: 6px;
				margin-right: 18px;
				position: relative;
				background: #ffffff;

				@media screen and (max-width:991px) {
					margin-bottom: 1rem;
				}

				img {
					height: 100%;
					object-fit: cover;
				}

				.fillr {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					line-height: 1px !important;
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					z-index: 1;
					margin: 0;
					white-space: nowrap;
					padding: 5px 10px !important;
					background: #F8FAFB;
					color: #2699FB;
					font-size: 14px;
					font-weight: 600;
					font-family: $inter-font;

					.icon {
						width: 33px;
						fill: #D4DAE0;
						height: 33px;
						margin-bottom: 15px;
					}

					input {
						opacity: 0;
						visibility: hidden;
						width: 0;
						height: 0;
						padding: 0 !important;
						margin: 0;
						line-height: 0;
					}
				}
			}

			.addevent-col-right {
				font-family: $inter-font;

				h4 {
					color: #243B53;
					font-family: $inter-font;
					font-weight: 600;
				}

				ul {
					padding-left: 15px;
					margin-bottom: 0;
					color: #829AB1;
					font-size: 12px;
					font-weight: 500;

					li {
						padding: 2px 0;
					}
				}
			}
		}
	}

	.event-type {
		.checkbox {
			margin-right: 8px;

			label {
				height: 32px;
				border-radius: 32px;
				border: 1px solid #D9D9D9;
				padding: 0 15px;
				line-height: 32px;
				font-size: 14px;
				color: #707070;
				font-weight: 500;
				font-family: $inter-font;

				&::before {
					display: none;
				}

				&::after {
					display: none;
				}
			}

			input {
				display: none;
			}

			&.checked {
				label {
					color: #2166EE;
					border-color: #2166EE;
				}
			}
		}
	}
}

.cropBtn {
	margin-top: 10px;
}