@import '../../../assets/scss/variables.scss';

.listing-names-section {
    .listing-names-header {
        h1 {
            color: $secondary-color;
        }
        p {
            font-weight: normal;
            b {
                font-weight: 600;
                color: $secondary-color;
            }
        }
    }
    input[disabled] {
        background: lightgrey;
    }
}
