.event-img{ display:flex;flex-direction: row;justify-content: center;margin-bottom: 16px;}
.event-img img{ max-width: 100%;}
.event-matadata{flex-wrap: wrap;display:flex;flex-direction: row; align-items: flex-start;margin-bottom: 10px;}
.event-date {display: inline-flex;flex-direction: column;margin-right: 20px;align-self: flex-start;}
.event-date .month{color: #d2596a;font-size: 20px;font-weight: 600;line-height: 16px;}
.event-date .date{font-size: 18px;font-weight: 600;line-height: 1;color: #212121;margin-top: 5px;}
.event-name {display: inline-flex;flex-direction: column;align-self: flex-start;}
.event-name .name{font-size: 18px;font-weight: 600;line-height: 28px;color: #212121;}
.event-name .host{color: #212121;margin-top: 3px;}
.event-option{margin-left: auto;}
.event-option ul{padding: 0; margin: 0; list-style: none;display: flex; flex-direction: row;}
.event-option ul li{display: flex;flex-direction: column;align-items: center;margin-right: 10px;}
.event-option ul li:last-of-type{margin-right: 0;}
.event-option ul li button{margin: 0; width: 100%; padding: .8rem .65rem !important;}
.event-option ul li span{font-size: 12px;font-weight: 600;margin-top: 5px;}
.event-venue{border-bottom: 1px solid rgba(120, 130, 140, .13);border-top: 1px solid rgba(120, 130, 140, .13);padding: 10px 0;}
.event-venue{display: flex;flex-direction: row;justify-content: space-between; align-items: flex-end;}
.event-venue ul{margin:0;list-style: none;padding:0 10px 0 0;display: flex;align-items: flex-start;flex:0 1 100%;justify-content:space-around;flex-direction: column;}
.event-venue ul li{display: flex;align-items: center;justify-content: flex-start;font-size: 12px;}
.event-venue ul li.time{font-weight: 400;}
.event-venue ul li.time span{font-weight: 600;margin-right: 4px;}
.event-venue ul li.address {align-items: flex-start;margin-top: 5px;}
.event-venue ul li.address span{font-weight: 600;display: block;}
.event-venue ul li.address p{font-weight: 400;margin-right: 4px;}
.event-venue ul li i{color: #00adee;font-size: 18px;line-height: 1;min-width: 25px;display: inline-flex;}
.event-venue ul li i:before{margin-left: 0;}
.event-venue a{white-space: nowrap;font-weight: 600;}
.event-wrapper .custom-tab .ui.pointing.secondary.menu {margin: 0;display: flex;justify-content: space-between;align-items:center;flex:0 1 100%;}
.event-wrapper .custom-tab .ui.pointing.secondary.menu a{font-weight: 600;line-height: 1;font-size: 16px;flex:0 1 100%;justify-content: center;}
.event-wrapper .custom-tab .ui.segment .filterBtn{position: absolute;right: 0;top: 29px;}
.filterBtn.ui.dropdown .menu > .item{display: flex;align-items: center;padding: 10px !important;}
.filterBtn.ui.dropdown .menu > .item .circular{margin:0 10px 0 0 !important;}
@media screen and (max-width:767px){
    .event-option{margin-top: 15px;}
    .event-venue{flex-wrap: wrap;justify-content: flex-end;}
    .event-venue a{margin-top: 5px;}
    .event-wrapper .custom-tab .ui.pointing.secondary.menu a{padding: 5px 8px;font-size: 16px;}
}
.pinkBackground.active {
    background-color: pink !important;
}
.greyBackground.active {
    background-color: grey !important;
}
.greenBackground.active {
    background-color: green !important;
}
.blueBackground.active {
    background-color: blue !important;
}
.yellowBackground.active {
    background-color: yellow !important;
}
.eventDetails{white-space: break-spaces; word-break: break-word; font-family: Assistant,sans-serif !important;}

.tagproduct-removebutton{
    width: 30px !important;
    height: 30px !important;
    min-height: 30px !important;
    min-width: 30px !important;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tagproduct-removebutton:hover{
    opacity: 0.8;
}

.editorClassName{
    border: 1px solid #ccc;
    min-height:150px;
}

.ui.menu .item.disabled,
.ui.menu .item.disabled:hover {
    cursor: default;
    background-color: initial !important;
    color: rgba(40, 40, 40, .3) !important;
}

.ui.menu .item>.label.disabled{
    cursor: default;
    background-color: initial !important;
    color: rgba(40, 40, 40, .3) !important;
}