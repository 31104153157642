@import '../../../assets/scss/variables.scss';

.product-search-alt {
    position: relative;
    .addnewcompany-link {
        padding: 10px;
        cursor: pointer;
        font-weight: 500;
        display: inline-block;
    }
    label{
        font-weight: 700;
        color: #243B53;
    }
    .result_list{
        position: absolute;
        background: #ffffff;
        border-radius: 5px;
        top: 100%;
        width: 100%;
        box-shadow: 0 2px 4px 0 rgba(34,36,38,.12), 0 2px 10px 0 rgba(34,36,38,.15);
        border: 1px solid #d4d4d5;
        ul {
            list-style-type: none;
            padding-left: 0;
            margin-top: 0;
            max-height: 250px;
            overflow-y: auto;
            margin-bottom: 0;
            li {
                padding: 8px 15px;
                color: #243B53;
                cursor: pointer;
                &:hover{
                    background: #eeeeee;
                }
            }
        }
    }
}


.modal.ui.creataproduct-modal {
    font-family: $inter-font;
    border-radius: 12px !important;
    @media screen and (max-width:576px) {
      min-height: 100%;
      margin: 0 !important;
      width: 100vw;
      position: fixed !important;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      border-radius: 0 !important;
    }
    .content {
      border-radius: 12px !important;
      @media screen and (max-width:576px) {
        border-radius: 0 !important;
      }
    }
    .addproduct-btn {
      height: 46px;
      padding: 0 28px;
      font-size: 15px;
      font-weight: 600;
    }
    .header {
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      border-radius: 12px 12px 0 0 !important;
      @media screen and (max-width:576px) {
        border-radius: 0 !important;
        padding: 0.75rem 1rem!important;
        justify-content: flex-start;
      }
      .closeicon-header {
        cursor: pointer;
        .icon {
          fill: #334E68;
          width: 12px;
        }
      }
      .backicon-header {
        display: flex;
        align-items: center;
        .icon {
          width: 15x;
          height: 15px;
          margin-right: 6px;
          fill: #334E68;
        }
      }
      h3{
        margin: 0;
        font-size: 18px;
        font-weight: 700;
        font-family: $inter-font;
        color: #243B53;
        font-size: 18px;
        font-weight: 700;
      }
    }
    .ui.form {
      @media screen and (max-width:576px) {
        display: flex;
        flex-direction: column;
      }
      .field {
        label {
          font-size: 16px;
          font-weight: 600;
          color: #243B53;
          font-family: $inter-font;
          margin-bottom: 4px;
          display: block;
          small {
            color: #A3B2C6;
            font-size: 13px;
            font-weight: 500;
          }
        }
        textarea {
          min-height: 48px;
          border: 1px solid #DBE2EB;
          border-radius: 6px;
          font-family: $inter-font;
          font-weight: 500;
          font-size: 14px;
          &::placeholder {
            font-weight: 500;
            font-size: 14px;
            color: #A3B2C6;
          }
          &::-webkit-placeholder {
            font-weight: 500;
            font-size: 14px;
            color: #A3B2C6;
          }
          &::-moz-placeholder {
            font-weight: 500;
            font-size: 14px;
            color: #A3B2C6;
          }
        }
        input {
          min-height: 48px;
          border: 1px solid #DBE2EB;
          border-radius: 6px;
          font-family: $inter-font;
          font-weight: 500;
          font-size: 14px;
          &::placeholder {
            font-weight: 500;
            font-size: 14px;
            color: #A3B2C6;
          }
          &::-webkit-placeholder {
            font-weight: 500;
            font-size: 14px;
            color: #A3B2C6;
          }
          &::-moz-placeholder {
            font-weight: 500;
            font-size: 14px;
            color: #A3B2C6;
          }
        }
        .selection.dropdown {
          min-height: 48px;
          border: 1px solid #DBE2EB;
          border-radius: 6px;
          padding: 0 32px 0 16px;
          color: #486581;
          background-image: none;
          .pil-item-row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-height: 48px;
          }
          .item {
            padding: 2px 8px !important;
          }
          & > .text {
            font-family: $inter-font;
            font-size: 14px;
            font-weight: 500;
            min-height: 48px;
            line-height: 48px;
            color: #486581;
            .right-col {
              display: none;
            }
            &::after {
              background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxMCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAgNkw1IDBMMTAgNkgwWiIgZmlsbD0iI0EzQjJDNiIvPgo8cGF0aCBkPSJNMCA4TDUgMTRMMTAgOEgwWiIgZmlsbD0iI0EzQjJDNiIvPgo8L3N2Zz4=");
              content: "";
              background-size: 10px;
              background-repeat: no-repeat;
              width: 10px;
              height: 15px;
              top: 50%;
              transform: translateY(-50%);
              right: -18px !important;
            }
          }
        }
      }
    }
  }
  
  .modals.dimmer {
    @media screen and (max-width:576px) {
      padding: 0 !important;
      overflow: hidden !important;
    }
  }