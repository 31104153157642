.gettingstarted-section{
    @media screen and (max-width:576px){
        padding-top: 30px !important;
        padding-bottom: 0 !important;
    }
    .gettingstarted-row{
        @media screen and (max-width:576px){
            flex-direction: column-reverse;
        }
    }
    .connect-word{
        svg{
            width: 100%;
        }
    }
    .button {
        @media screen and (max-width:767px){
            width: 100%;
            margin-bottom: 10px;
        }
    }
}