.croppingimg-modal{
    max-width: 760px;
    margin: auto;
    border-radius: 15px !important;
    .modal-close{
        right: 20px !important;
        top: 20px !important;
        position: absolute;
        width: 12px;
        fill: #707070;
        cursor: pointer;
    }
    .header{
        border-radius: 15px 15px 0 0 !important;
        font-size: 20px !important;
        color: #1A1642;
        padding: 15px 20px !important;
        border-bottom: 1px solid #D9D9D9;
    }
    .content{
        border-radius: 0 0 15px 15px !important;
    }
    .upload-circle{
        width: 180px;
        height: 180px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #E9EEF3;
        border-radius: 180px;
        margin: auto auto 20px auto;
    }
    .container{
        text-align: center;
        padding-bottom: 1rem;
        [role="presentation"]{
            border: 0 !important;
            background-color: #ffffff !important;
            height: auto !important;
        }
        h4{
            color: #1A1642;
            font-weight: 600;
            margin-bottom: 2px;
        }
        i{
            font-weight: lighter;
            font-style: italic;
            font-size: 12px;
            color: #707070;
        }
        .or-div{
            font-weight: 600;
            color: #1A1642;
            font-size: 18px;
            margin: 10px auto;
        }
        .ui.button{
            color: #2699FB;
            border-color: #2699FB !important;
            padding: 9px 15px;
        }
    }
}