@import '../../assets/scss/variables.scss';

.edit-prfile-head{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid $border-color;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 20px;
    @media only screen and (max-width:640px){
        display: block;
    }
    .upgrade-btn{
        font-size: 13px;
        padding: .6rem 1rem;
        font-weight: 600 !important;
        color: $primary-color !important;
    }
    .ui.items{
        margin-bottom: 0;
        .item{
            .image{
                width: 95px !important;
                height: 72px;
                border-radius: 4px;
                background: #a0a0a0;
                color: #ffffff;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: $font-size-xlg + 8;
                font-weight: bold;
                overflow: hidden;
                img{
                    max-height: 100%;
                    width: auto;
                }
            }
            .content{
                .extra{
                    margin-top: 0;
                }
                .header{
                    margin-top: 4px;
                    font-size: $font-size-base + 3;
                    font-weight: bold;
                }
                .userImage{
                    margin: 0;
                    label{
                        color: $primary-color !important;
                        font-size: $font-size-base;
                        cursor: pointer;
                    }
                    input{
                        visibility: hidden;
                        height: 0;
                        width: 0;
                    }
                }
            }
        }
    }
}

.edit-profile-form{
    .main-heading{
        font-size: $font-size-base + 2;
        color: $secondary-color;
        font-weight: 600;
        margin-bottom: 30px;
    }
    input{
        color: $secondary-color !important;
        font-weight: 600;
        font-size: $font-size-base !important;
        border-radius: 3px !important;
    }
    .bule-heading{
        color: $primary-color;
        font-weight: bold;
        font-size: $font-size-base;
        margin-top: 15px;
    }
    .select-custom{
        background-color: #fff !important;
        border: 1px solid #e4e7ea !important;
        box-shadow: none;
        color: $secondary-color !important;
        font-weight: 600 !important;
        font-size: $font-size-base !important;
        border-radius: 3px !important;
        height: 38px !important;
        max-width: 100% !important;
        padding: 7px 12px !important;
        transition: all 300ms linear 0s;
        .default.text{
            color: $secondary-color !important;
            font-weight: 600 !important;
            font-size: $font-size-base !important;
        }
    }
    .form-input.ui .dropdown{

        height: 38px !important;
        padding: 7px 12px !important;
    }      
}
.delete-profile-account {
    .button {
        background: none;
        padding: 0;
        color: $primary-color;
        font-weight: 600;
        &:hover {
            background-color: $white;
        }
    }
}

.profile-button-action{
    padding-top: 16px;
    .button {
        height: 46px;
        padding: 0 20px;
        border-radius: 6px;
        min-width: 110px;
    }
    .btn-outline{
        border: 1px solid $border-color-base;
        background: $white;
        color: #707070;
        margin-right: 15px;
    }
}

.leftuserbox{
    border: 1px solid #DBE2EB;
    border-radius: 5px;
    padding: 14px;
    background: $white;
    margin-bottom: 15px;
    .userprofile-leftdtl a:hover{
        color: #000000 !important;
    }    
    .ui.items{
        margin-bottom: 0;
        .item{
            display: block;
            div.image{
                width: 60px !important;
                height: 65px;
                border-radius: 4px;
                color: #ffffff;
                display: flex;
                justify-content: center;
                font-size: $font-size-xlg + 3;
                font-weight: bold;
                margin-top: 8px;
                margin-bottom: 5px;
                background: #a0a0a0;
                overflow: hidden;
                align-items: center;
                position: relative;
                a{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    transform: translate(50%, 50%);
                    top: -50%;
                    left: -50%;
                    margin: auto;
                    text-align: center;
                }
                img{
                    border-radius: 0;
                    margin: 0;
                    width: auto;
                    height: 100%;
                }
                .user-initials{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    i{
                        cursor: pointer;
                        margin: 0;
                        &:hover{
                            opacity: 0.85;
                        }
                    }
                }
            }
            .heads{
                b{
                    font-size: $font-size-base - 2;
                }
            }
            .content{
                padding-left: 0;
                .extra{
                    border-top: 1px solid $border-color;
                    padding-top: 6px;
                    margin-top: 10px;
                    font-size: $font-size-base - 2;
                    a{
                        color: #818181;
                    }
                    span{
                        color: $primary-color;
                    }
                }
                .header{
                    margin-top: 0;
                    font-size: $font-size-base - 1 !important;
                    font-weight: bold;
                    color: #444444;
                    width: 100%;
                    a{
                        color: #444444;
                    }
                    .edit-link{
                        fill: $primary-color;
                        width: 12px;
                        float: right;
                        cursor: pointer;
                        position: relative;
                        bottom: -4px;
                    }
                   
                }
                .meta{
                    margin: 0;
                    font-size: $font-size-base - 2;
                    font-style: italic;
                    *{
                        color: #818181;
                    }
                    a{
                        font-size: 12px;
                    }
                    .meta-rows{
                        padding: 5px 0;
                    }
                    &.dark{
                        span{
                            color: #1A1642;
                        }
                    }
                }
                .followproduct-leftox{
                    padding: 10px;
                    h3{
                        color: #1A1642;
                        font-size: 20px;
                        font-weight: 600;
                        margin-bottom: 12px;
                    }
                }
                .my-product-list{
                    padding: 0;
                    .item{
                        padding: 0;
                        height: auto;
                        visibility: visible;
                        a{
                            color: #818181;
                            font-size: 12px;
                            padding: 4px 15px;
                            display: block;
                            &:hover{
                                background: #ebebeb;
                                color: $black;
                            }
                        }
                        .meta-rows{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: 100%;
                            span{
                                float: right;
                                color: $primary-color;
                                font-weight: bold;
                                font-size: 1.05rem;
                            }
                        }
                    }
                }
            }
        }
    }
    &.network-card{
        padding: 0;
        .item{
            padding: 0;
            a{
                color: #818181;
            }
        }
    }
    &.following-company-card{
        .items{
            .item{
                .content{
                    .meta-rows{
                        padding: 0;
                        a{
                            color: #28244D;
                            font-size: 13px;
                        }
                    }
                }
            }
        }
    }
    &.startnow-leftbox{
        border-radius: 11px 10px 4px 4px;
        color: $secondary-color;
        .header{
            width: calc(100% + 20px);
            background: $primary-color;
            color: $white;
            margin-left: -10px;
            margin-top: -10px;
            padding: 10px;
            border-radius: 11px 10px 0 0;
        }
        a{
            color: $primary-color;
            font-weight: 600;
            margin-top: 10px;
            display: inline-block;
        }
    }
}

.deletemyaccount-modal{
    text-align: center;
    .header{
        text-align: center;
        border: 0 !important;
    }
    .content{
        text-align: center;
        padding: 15px;
    }
    .actions{
        border-top: 0 !important;
    }
}