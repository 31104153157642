@import '../../assets/scss/variables.scss';

.chat-wrapper {
    background: white;
    border-radius: 8px;
    border: 1px solid #ccc;
    display: flex;
    width: 100%;
    padding: 0 !important;
    margin-top: 15px;
    position: relative;

    .chat-listbar {
        max-width: 350px;
        width: 100%;
        background: #ffffff;
        border-radius: 8px 0 0 8px;

        @media screen and (max-width:1100px) {
            max-width: 300px;
        }

        @media screen and (max-width:767px) {
            position: absolute;
            width: 100%;
            max-width: 100%;
            z-index: 2;
            top: 0;
            background: #fff;
            border-radius: 8px;
            height: 100%;

            &.hideChatlist {
                display: none;
            }
        }

        .chat-list-left {
            background: #fff;
            border-radius: 8px 0 0 0;
            border-right: 1px solid #ccc;

            @media screen and (max-width:767px) {
                border-radius: 8px;
            }

            .ui.list {
                max-height: calc(100vh - 200px);
                min-height: calc(100vh - 190px);
                overflow-y: auto;

                .item {
                    display: flex;
                    padding: 14px 15px;
                    border-bottom: 1px solid #eee;
                    background: #fff;
                    border-left: 3px solid transparent;

                    .avatar.image {
                        width: 3rem;
                        min-width: 3rem;
                        height: 3rem;
                    }

                    .circular.label{
                        min-width: 3rem;
                        width: 3rem;
                        height: 3rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .content {
                        width: 100%;
                        font-size: 15px;
                        margin-top: 2px;
                        align-self: center;
                        padding-left: 0.5em;

                        a.header {
                            color: #333 !important;
                            font-size: 16px;
                            font-weight: normal;
                            display: flex;
                            justify-content: space-between;

                            small {
                                color: #333;
                                font-size: 11px;
                            }
                        }

                        .ui.dropdown {
                            margin-top: 10px;
                        }
                    }
                }

                .item.active {
                    border-left: 4px solid #00adee;
                    background: #ededed;
                }
            }

            .search-chat-user {
                height: 32px;
                padding: 0 5px;
                margin-top: 8px;

                .ui.input {
                    width: 100%;

                    input {
                        background: #ededed;
                        border: 0;
                    }
                }
            }

            .chat-list-heads {
                border-bottom: 1px solid #e5e5e5;
                padding: 10px 15px;
                color: #000000;
                font-size: 18px;
                border-radius: 8px 0 0 0;
                font-weight: 600;
                min-height: 56px;
                display: flex;
                align-items: center;
            }
        }
    }

    .chatbot-right {
        width: 100%;

        .chatheader-user {
            padding: 8px 15px;
            margin-top: 0;
            border-bottom: 1px solid #ccc;
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;

            .chatheader-left {
                display: flex;
                align-items: center;

                .chat-backbutton {
                    display: none;
                    margin-right: 15px;
                    cursor: pointer;
                    color: $primary-color;

                    @media screen and (max-width:767px) {
                        display: block;
                    }
                }

                h4 {
                    margin-bottom: 0;
                    color: #000;
                }
            }
        }

        .chats-wrapper {
            display: flex;
            flex-direction: column;

            .msg-list {
                .conversation-box.ui.modal {
                    >.ui.header {
                        color: #6c777d;
                        border-radius: 0;

                        .close {
                            float: right;
                            font-size: 14px;
                            line-height: 18px;
                            color: #000;
                            opacity: .2;
                            filter: alpha(opacity=20);
                            margin-right: 0;
                            cursor: pointer;
                        }
                    }

                    >.content {
                        border-radius: 0;
                        padding: 15px;
                    }
                }

                .chat-header {
                    padding-bottom: 10px;
                    border-bottom: 1px solid #ccc;
                    margin-bottom: 10px;
                    padding-left: 20px;
                    padding-right: 20px;

                    i {
                        margin-right: 8px;
                    }
                }

                .conversation {
                    max-height: calc(100vh - 310px);
                    min-height: calc(100vh - 310px);
                    overflow-y: auto;

                    @media screen and (max-width:767px) {
                        max-height: calc(100vh - 340px);
                        min-height: calc(100vh - 340px);
                    }

                    ul {
                        margin: 0;
                        padding: 0 3px 0 0;
                        list-style: none;
                        overflow: auto;
                        width: 100%;
                        display: block;
                    }

                    li {
                        margin-bottom: 5px;
                        display: flex;
                        width: 100%;
                        justify-content: flex-start;
                        align-items: flex-start;
                        padding-left: 15px;
                        padding-right: 15px;

                        .thread-pic {
                            display: inline-flex;
                            min-width: 40px;
                            min-height: 40px;
                            height: 40px;
                            width: 40px;
                            background-color: #ccc;
                            border-radius: 100px;
                            justify-content: center;
                            align-items: flex-start;
                            overflow: hidden;

                            .ui.avatar.image.avatar-40 {
                                margin: 0;
                            }

                            img {
                                max-width: 100%;
                            }
                        }

                        .thread-body {
                            display: inline-flex;
                            justify-content: flex-start;
                            position: relative;
                            font-size: 14px;
                            width: 100%;
                            flex-direction: row;

                            .thread-text {
                                padding: 10px 15px;
                                display: inline-block;
                                margin-left: 0 !important;

                                p{
                                    white-space: pre-line;
                                }
                            }
                        }

                        .thread-text {
                            .thread-name {
                                color: #1a2942;
                                display: block;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 600;

                                .message_timestamp {
                                    opacity: .8;
                                    margin-left: 5px;
                                    color: #8e8e8e;
                                }

                                .message_timestamp::before {
                                    content: "•";
                                    text-transform: lowercase;
                                    margin-right: 5px;
                                }
                            }

                            p {
                                margin: 0;
                                padding-top: 3px;
                                line-height: 1.6;
                                text-align: left;
                                color: #5b5959;
                            }

                            b {
                                font-size: 10px;
                                opacity: .8;
                                font-weight: 600;
                            }
                        }

                        .thread-name {
                            a {
                                color: #000 !important;
                            }
                        }
                    }

                    li.thread-out {
                        .thread-body {
                            margin-left: 0;
                            padding-left: 0;
                            justify-content: flex-start;

                            .thread-text {
                                margin-left: 0;
                                margin-left: 10px;
                            }
                        }

                        text-align: left;
                        justify-content: flex-start;
                        align-items: flex-start;

                        .thread-text {
                            .thread-name {
                                color: #000 !important;

                                a {
                                    color: #000 !important;
                                }
                            }
                        }
                    }
                }
            }

            .chatbottom-wrapper {
                .text-area {
                    border-top: 3px solid green;
                    border-bottom: 1px solid #ccc;
                }

                .send-button {
                    text-align: right;

                    @media screen and (max-width:767px) {
                        text-align: left;
                    }
                }
            }
        }
    }

    .time-heading {
        display: flex;
        justify-content: center;
        letter-spacing: 1px;
        margin: 8px 0;
        text-transform: uppercase;
    }
}

.pdfview-modal {
    .PDFView__container__document {
        max-height: 92vh;
    }
}