@import '../../../assets/scss/variables.scss';

.ui.sign-modal {
    max-width: 504px;
    border-radius: 8px !important;
    border-radius: 8px !important;
    margin-left: auto;
    margin-right: auto;
    .modal-close {
        position: absolute;
        right: 30px;
        top: 30px;
        fill: $secondary-color;
        cursor: pointer;
    }
    .header {
        background-image: url('../../../assets/images/signbg.jpg');
        border: 0;
        text-align: center;
        padding: 4rem 2rem;
        border-top-left-radius: 8px !important;
        border-top-right-radius: 8px !important;
        h1 {
            color: black;
            font-size: 2.6rem;
            font-weight: 600;
        }
        img{
            width: 90px;
        }
    }
    .content {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        .modal-content-inner {
            max-width: 290px;
            margin: auto;
            padding-top: 40px;
            padding-bottom: 40px;
        }
    }
    .loader {
        display: none;
    }
    .loader.active:after {
        display: inline-block;
        border-color: black transparent transparent !important;
    }
}
