@import '../../assets/scss/variables.scss';

.req-quote-wrapper {
    background: #E5E8F0;
    padding: 20px;
    max-width: 840px;
    margin: auto;

    .terms {
        margin-top: 4px !important;
    }

    .main-heading {
        color: #324467;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 0;
    }

    .steps {
        width: 100%;
        background: none;
        border: 0;

        .step {
            background: none;
            border: 0;
            justify-content: flex-start;
            padding-left: 0 !important;

            &::after {
                background: none;
                border-color: #4D6189;
                border-width: 0 2px 2px 0;
                width: 15px;
                height: 15px;
                right: 20%;
            }

            .content {
                color: #324467;
                font-size: 16px;
                font-weight: 600;

                .title {
                    color: #7C8CAB;
                    font-weight: 600;
                    text-transform: uppercase;
                    font-size: 13px;
                    margin-bottom: 8px;
                }
            }
        }
    }

    .card-section {
        margin-top: 20px;

        .title {
            font-size: 16px;
            font-weight: 600;
            color: $black;
            margin-bottom: 8px;
        }

        .card {
            background: $white;
            padding: 20px;

            .field {
                label {
                    color: #8895AF;
                    letter-spacing: 1.2px;
                    font-size: $font-size-base;
                    font-weight: 600;
                    margin-bottom: 8px;


                }

                input {
                    background: #F8F9FA;
                    border: 1px solid #E5E8F0;
                    border-radius: 3px;
                    min-height: 48px;
                    height: 48px;
                    color: #4D6189;

                    &::placeholder {
                        color: #4D6189;
                    }

                    &::-webkit-placeholder {
                        color: #4D6189;
                    }

                    &::-moz-placeholder {
                        color: #4D6189;
                    }
                }

                textarea {
                    background: #F8F9FA;
                    border: 1px solid #E5E8F0;
                    border-radius: 8px;
                    font-size: $font-size-base;
                    color: #4D6189;

                    &::placeholder {
                        color: #4D6189;
                    }

                    &::-webkit-placeholder {
                        color: #4D6189;
                    }

                    &::-moz-placeholder {
                        color: #4D6189;
                    }

                    padding: 15px;
                    overflow: hidden;

                }

                .icon {
                    max-height: 48px;
                    color: $black;
                    opacity: 1;

                    &::before {
                        color: $black;
                        opacity: 1;
                    }
                }

                .browse-btn {
                    background: #324467;
                    color: $white;
                    font-size: 18px;
                    padding: 0 30px;
                    height: 48px;
                    font-weight: 600;
                }
            }

            .technicalspe-list {
                list-style-type: none;
                padding-left: 0;

                li {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    color: #8895AF;
                    font-size: 14px;
                    font-weight: 600;
                    max-width: 300px;
                    padding: 6px 0;

                    .icon {
                        width: 12px;
                        cursor: pointer;

                        &:hover {
                            fill: $primary-color;
                        }
                    }
                }
            }
        }
    }

    .terms-check {
        font-size: 16px;
        font-weight: 600;
        color: $black;
        padding: 20px 0 5px 20px;
        display: flex;
        align-items: center;

        span {
            color: #2168F3;
        }

        .checkbox {
            margin-right: 5px;
        }
    }

    .submit-btn {
        text-align: center;

        button.ui {
            background-color: #191641;
            color: $white;
            padding: 0 50px;
            height: 46px;
            line-height: 46px;
            font-size: 18px;
            font-weight: 600;
            border-radius: 0;

            &:hover {
                background-color: rgba(#191641, 0.8);
            }
        }
    }
}