@import '../../../assets/scss/variables.scss';

.invite-header{
    background: $white;
    h1{
        color: #1A1642;
        font-size: 1.4rem;
        line-height: 1.5;
        font-weight: 600;
        @media screen and (max-width:480px) {
            font-size: 1.2rem;
        }
        @media screen and (max-width:380px) {
            font-size: 1rem;
        }
    }
    h2{
        color: $secondary-color;
        font-size: 1.9rem;
    }
    p{
        font-weight: normal;
        b{
            font-weight: 600;
            color: $secondary-color;
        }
    }
}

.headers-section{
    background: $white;
    padding: 1.1rem 1.5rem !important;
    h1{
        color: $secondary-color;
        font-size: 1.9rem;
    }
    h2{
        color: $secondary-color;
        font-size: 1.8rem;
    }
    p{
        font-weight: normal;
        b{
            font-weight: 600;
            color: $secondary-color;
        }
    }
}


.invitation-email{
    margin: 15px 0 10px 0;
    width: 100%;
    max-width: 100%;
    .ui.button.primary {
        margin-left: 10px;
        padding: 0 18px;
        font-weight: 600 !important;
    }
}

.message {
    display: inline-flex;
    vertical-align: middle;
    .loader {
        left: 4%;
    }
    h3 {
        color: red;
    }
    .icon-checked {
        color: green;
        font-size: 24px;
        margin-left: 8px;
    }
}


.admoreemail-link{
    cursor: pointer;
    margin-top: 10px;
    span{
        color: rgba($text-gray, 0.5);
        font-size: $font-size-base + 2;
        font-weight: 600;
        &:hover{
            color: $primary-color;
        }
    }
}

.inviteor-divider{
    border-top: 2px dotted $primary-color;
    margin-top: 60px;
    margin-bottom: 50px;
    position: relative;
    text-align: center;
    .or-div{
        position: absolute;
        margin: auto;
        display: inline-block;
        width: 50px;
        background: $white;
        padding: 10px;
        left: 50%;
        transform: translateX(-50%);
        font-weight: 600;
        color: $primary-color;
        top: -25px;
        font-size: $font-size-base + 6;
    }
}

.invite-buttons{
    border: 1px solid rgba($primary-color, 0.3);
    padding: 20px;
    border-radius: 4px;
    .bottom{
        .button{
            background: rgba($primary-color, 0.1);
            font-weight: normal;
            font-size: $font-size-base + 1;
            padding: 0 18px 0 15px;
            height: 46px;
            line-height: 46px;
            margin-right: 10px;
            .icon{
                width: 22px;
                height: 22px;
                fill: $primary-color;
                margin-top: 12px !important;
                float: left;
            }
        }
    }
}

.ui.invite-modal{
    max-width: 520px;
    width: 100%;
    margin: auto !important;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 12px;
    width: 94%;
    .modal-close {
        position: absolute;
        right: 15px;
        top: 18px;
        width: 12px;
        cursor: pointer;
    }
    .header{
        padding: 15px;
        font-size: 20px !important;
        border-radius: 15px 15px 0 0 !important;
        color: #1A1642;
        font-weight: 700;
    }
    .content{
        border-radius: 0 0 15px 15px !important;
        padding-bottom: 50px;
        .inputemail-row{
            .react-multi-email{
                display: block;
                flex: none;
                width: calc(100% - 130px);
                float: left;
                @media screen and (max-width:480px) {
                    width: 100%;
                }
            }
            .react-multi-email [data-tag]{
                border: 1px solid #D9D9D9;
                background: $white;
                padding: 0.4533em 0.733em;
                border-radius: 5px;
                color: #000000;
                display: inline-block;
                font-size: 12px;
            }
            .react-multi-email [data-tag-handle]{
                font-size: 18px;
                line-height: 11px;
                margin-left: 0.333em;
                float: right;
            }
            span[data-placeholder]{
                color: #C5C5C5;
                font-size: 14px;
                top: 3px;
                font-weight: normal;
                @media screen and (max-width:480px) {
                    font-size: 13px;
                }
                @media screen and (max-width:380px) {
                    font-size: 12px;
                }
            }
            .custom-btn{
                width: 115px;
                padding: 0 10px;
                float: right;
                @media screen and (max-width:480px) {
                    width: 100%;
                    margin-top: 20px;
                }
            }
        }
    }
}

.ui.invitemore-btn{
    background: none;
    padding: 0;
    color: $primary-color;
    font-weight: normal;
    font-size: 12px;
    &:hover{
        background: none;
        opacity: 0.9;
    }
}

.invitesend-msg {
    display: flex;
    align-items: center;
    width: 100%;
    padding-top: 8px;
    .icon-checked {
        width: 20px;
        height: 20px;
        border-radius: 20px;
        background: green;
        color: $white;
        font-size: 10px;
        display: flex;
        align-items: center;
        margin: 0 5px 0 0;
        text-align: center;
        &::before {
            margin: auto;
        }
    }
}