.mappes-dropdown {
  position: relative;
  display: inline-block;
}

.mappes-dropdown-trigger {
  border: 0;
  background: none;
  box-shadow: none;
  cursor: pointer;
}

.mappes-dropdown-overlay {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: auto;
  border-radius: 6px;
  &.show-drop {
    display: block;
  }
  &.hide-drop {
    display: none;
  }
}

.mappes-dropdown-content {
  padding: 10px;
}