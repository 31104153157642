.PDFView__container {
   display: flex;
   flex-direction: column;
   align-items: center;
   margin: 0 auto;
   padding: 10px;
   width: 100%;
   background: black;
   position: relative;
}

.PDFView__container__load {
   margin-top: 1em;
   color: white;
}

.PDFView__container__document {
   width: 100%;
   max-width: 100%;
   margin: 0;
   max-height: 380px;
   overflow-y: auto;

   .react-pdf__Page {
      margin: 0;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
   }

   .react-pdf__message {
      padding: 20px;
      color: white;
   }
}

.page-controls {
   opacity: 1;
   z-index: 2;
   font-size: 20px;
   font-weight: 500;
   position: absolute;
   bottom: 20px;
   z-index: 2;
   color: #ffffff;
   left: 0;
   right: 0;
   text-align: center;
   display: flex;
   align-items: center;
   justify-content: center;

   .page-controls-inner {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
      background: rgba(0, 0, 0, 0.75);
      border-radius: 30px;
      padding: 0 15px;
   }

   span {
      font: inherit;
      font-size: .8em;
      padding: 0 .5em;
   }

   .icon {
      fill: #ffffff;
   }

   button {
      width: 38px;
      height: 38px;
      background: none;
      border: 0;
      font: inherit;
      font-size: .8em;
      border-radius: var(--rounded-corners);
      color: #ffffff;

      &.zoom-btn {
         font-size: 1.6rem !important;
      }

      &:enabled {
         &:hover {
            cursor: pointer;
            // background-color: #e6e6e6;
         }

         &:focus {
            // background-color: #e6e6e6;
         }
      }

      &:first-child {
         border-top-right-radius: 0;
         border-bottom-right-radius: 0;
      }

      &:last-child {
         border-top-left-radius: 0;
         border-bottom-left-radius: 0;
      }
   }
}

.react-pdf__Page__canvas {
   margin: auto;
}

.pdfvirew-modal-close {
   position: absolute;
   right: -18px;
   top: -18px;
   z-index: 9;
   padding: 0;
   width: 36px !important;
    height: 36px !important;
    
}


.pdf-fullview-btn{
   width: 40px !important;
    height: 40px !important;
    border-radius: 0;
    background: rgba(0, 0, 0, 0.75);
    border: 0;
    right: 0;
    top: 0;
    position: absolute;
    z-index: 3;
    cursor: pointer;
    transition: all ease-in 0.1s;
    outline: none;
    &:hover {
      background: rgba(0, 0, 0, 0.75);
    }
    i {
      color: #ffffff;
      pointer-events: none;
    }
}