@import '../../../assets/scss/variables.scss';

.lwo-body {
    .lwo-card {
        .lwo-card-inner {
            .skip-password {
                position: absolute;
                right: 25px;
                top: 30px;
                color: #2166EE;
                font-size: 14px;
                font-weight: 600;
                font-family: $inter-font;
                background: none;
                padding: 0;
            }
            .password-form-item {
                position: relative;
                .icon {
                    position: absolute;
                    right: 15px;
                    top: 50%;
                    transform: translateY(-50%);
                    fill: #A4B0CC;
                    cursor: pointer;
                }
            }
            .password-hint {
                margin-top: 15px;
                p {
                    font-size: 12px;
                    color: #A3B2C6;
                    font-weight: 500;
                    font-family: $inter-font;
                    margin-bottom: 5px;
                }
                ul {
                    list-style-type: none;
                    padding-left: 0;
                    margin: 5px 0;
                    li{
                        padding: 4px 0 4px 20px;
                        font-size: 12px;
                        color: #A3B2C6;
                        font-weight: 500;
                        font-family: $inter-font;
                        position: relative;
                        z-index: 1;
                        &::before {
                            background: #F3F7FF;
                            background-image: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.87032 0.521539L3.76216 5.13925L1.85138 3.41955C1.34184 3.00555 0.736763 3.00555 0.354608 3.41955C-0.0275472 3.83355 0.0679916 4.43863 0.450147 4.82078L3.15708 7.2411C3.57108 7.6551 4.17616 7.52772 4.55831 7.14556L9.3671 1.7317C9.7811 1.3177 9.65371 0.712617 9.27156 0.330462C8.8894 0.0119992 8.28432 0.0119991 7.87032 0.521539Z' fill='white'/%3E%3C/svg%3E%0A");
                            background-repeat: no-repeat;
                            background-position: center center;
                            width: 15px;
                            height: 15px;
                            border-radius: 15px;
                            position: absolute;
                            left: 0;
                            content: ' ';
                            z-index: 0;
                        }
                        &.active {
                            &::before {
                                background-image: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.87032 0.521539L3.76216 5.13925L1.85138 3.41955C1.34184 3.00555 0.736763 3.00555 0.354608 3.41955C-0.0275472 3.83355 0.0679916 4.43863 0.450147 4.82078L3.15708 7.2411C3.57108 7.6551 4.17616 7.52772 4.55831 7.14556L9.3671 1.7317C9.7811 1.3177 9.65371 0.712617 9.27156 0.330462C8.8894 0.0119992 8.28432 0.0119991 7.87032 0.521539Z' fill='%232166EE'/%3E%3C/svg%3E%0A");
                            }
                        }
                    }
                }
            }
        }
    }
}