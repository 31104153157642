@import '../../assets/scss/variables.scss';

.productpage-wip {
    .product-upper {
        background: $white;
        border: 1px solid #B7B7B771;
        border-radius: 5px;
        .product-upper-inner {
            padding: 10px;
            display: flex;
            @media screen and (max-width: 580px) {
                display: block !important;
            }
            .productupper-left {
                width: 160px;
                min-width: 160px;
                margin-right: 20px;
                .productimage-main {
                    width: 100%;
                    height: 160px;
                    border-radius: 10px !important;
                    overflow: hidden;
                    background: #E9EEF3;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    z-index: 2;
                    img {
                        width: 100%;
                    }
                    .no-image-avlbl {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        height: 100%;
                        background: #E9EEF3;
                        color: #ffffff;
                        font-size: 3rem;
                        font-weight: 600;
                        .icon{
                            width: 60%;
                            fill: #818181;
                        }
                    }
                }
                .productimage-thumbs {
                    margin-top: 8px;
                    ul {
                        display: grid;
                        grid-template-columns: repeat(4, 1fr);
                        grid-gap: 6px;
                        list-style-type: none;
                        padding-left: 0;
                        margin-top: 5px;
                        margin-bottom: 5px;
                        li {
                            border-radius: 8px !important;
                            background: #E9EEF3;
                            max-height: 38px;
                            min-height: 38px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            overflow: hidden;
                            &:hover{
                                background: $primary-color !important;
                                .icon {
                                    fill: $white;
                                }
                            }
                            .icon {
                                fill: #707070;
                            }
                            img {
                                max-width: 100%;
                                min-height: 100%;
                            }
                            &:last-child {
                                cursor: pointer;
                            }
                        }
                    }
                }
                .viewll-media-link {
                    font-size: $font-size-base - 2;
                }
            }
            .productupper-right {
                width: 100%;
                display: flex;
                flex-direction: column;
                // .actionbtns{
                //     text-align: left;
                //     margin-top: 2px;
                //     margin-bottom: 12px;
                //     button.ui {
                //         margin-left: 0;
                //         margin-right: 10px;
                //         height: 36px;
                //         font-weight: 600;
                //         padding: 6px 14px !important;
                //         font-size: 16px;
                //         border-radius: 6px !important;
                //         .icon {
                //             margin-right: 6px !important;
                //         }
                //     }
                // }
                // .product-topright-action{
                //     .toogle-icon{
                //         width: 15px;
                //         text-align: center;
                //         .ellipsis-action{
                //             width: 4px;
                //             fill: $primary-color;
                //             &:hover{
                //                 fill: rgba($primary-color, 0.7);
                //             }
                //         }
                //     }
                //     .menu{
                //         padding: 0 !important;
                //         border-radius: 6px !important;
                //         box-shadow: -1px 4px 8px rgba(0, 0, 0, 0.08);
                //         border-color: #CBCBCB !important;
                //         overflow: inherit !important;
                //         margin-top: 5px !important;
                //         left: auto;
                //         right: 0;
                //         .item {
                //             border-bottom: 1px solid rgba($border-color-base, 0.5) !important;
                //             display: flex !important;
                //             align-items: center;
                //             font-weight: 600 !important;
                //             color: #1A1642 !important;
                //             font-size: 16px;
                //             .icon{
                //                 width: 14px;
                //                 height: 14px;
                //                 margin-right: 5px !important;
                //                 fill: #1A1642 !important;
                //             }
                //             &:hover{
                //                 .icon{
                //                     fill: $primary-color;
                //                 }
                //             }
                //         }
                //     }
                // }
                .namerow {
                    display: flex;
                    justify-content: space-between;
                    padding-top: 10px;
                    .follow-btn-new {
                        display: flex;
                        align-items: center;
                        padding-left: 10px;
                        padding-right: 10px !important;
                        height: 24px;
                        .icon {
                            margin-right: 5px !important;
                            fill: $black;
                            opacity: 1;
                        }
                        div {
                            margin: 0;
                            &.hidden.content {
                                top: 4px !important;
                                padding-left: 3px;
                            }
                        }
                    }
                    .product-topright-action{
                        width: 18px;
                        text-align: center;
                    }
                    h1 {
                        color: $secondary-color;
                        font-size: $font-size-base + 6;
                        font-weight: 700;
                        margin-bottom: 2px;
                    }
                    .connection-subhead {
                        padding-left: 0;
                        li {
                            font-size: $font-size-base + 2;
                            border-right: 1px solid $border-color-base;
                            font-weight: 600;
                            a, span{
                                color: #707070;
                                font-size: $font-size-base + 2;
                            }
                            &:last-child {
                                border-right: 0;
                            }
                        }
                    }
                }
                .detail-text {
                    font-size: $font-size-base - 1;
                    .ui.button {
                        padding: 0 !important;
                        border-radius: 0;
                        border: 0 !important;
                        box-shadow: none !important;
                    }
                }
                .details-view {
                    padding-right: 15px;
                    .icon {
                        fill: #c6c6c6;
                    }
                }
            }
        }
    }
    .product-bottom {
       /*  background: $white;
        border: 1px solid #B7B7B771; */
        border-radius: 5px;
        margin-top: 10px;
        // position: -webkit-sticky;
        // position: sticky;
        top: 0;
        .product-bottom-inner {
            .product-tabes {
                .background-white{
                    &.extra-padding-sides{
                        border-radius: 0 0 5px 5px;
                    }
                }
                // .menu {
                //     background: $white;
                //     border: 1px solid #B7B7B771;
                //     border-width: 1px;
                //     padding: 10px 10px 0 10px;
                //     margin-bottom: 0;
                //     overflow-x: auto;
                //     &::-webkit-scrollbar {
                //         height: 0;
                //         display: none !important;
                //     }
                //     &::-webkit-scrollbar-track {
                //         background: transparent;
                //         height: 0;
                //     }
                //     &::-webkit-scrollbar-thumb {
                //         background: transparent;
                //         height: 0;
                //     }
                //     &::-moz-scrollbar-track {
                //         background: transparent;
                //         height: 0;
                //     }
                //     &::-moz-scrollbar-thumb {
                //         background: transparent;
                //         height: 0;
                //     }
                //     @media screen and (max-width: 768px) {
                //         overflow-x: auto;
                //         overflow-y: hidden;
                //     }
                //     a.item {
                //         padding: 0.65714286em 0.13285714em !important;
                //         font-size: $font-size-base - 1 !important;
                //         margin-right: 6px !important;
                //         border-bottom: 2px solid transparent;
                //         margin-bottom: 0;
                //         &.active {
                //             border-color: #2699fb;
                //         }
                //     }
                // }
                .main-content-area{
                    margin-top: 0;
                    .feeds-top-section{
                        border-width: 0 0 1px 0;
                        border-radius: 0;
                        .post-add{
                            textarea{
                                background: $white;
                            }
                        }
                    }
                }
                .tab {
                    // border: 0;
                    padding: 0;
                }
                & .feed-top-section {
                    margin-bottom: 15px;
                    padding: 10px 10px 15px 10px;
                    border-bottom: 1px solid $border-color-base;
                    .post-btn {
                        margin-left: 18px;
                        .ui.button {
                            padding: 9px 20px;
                        }
                    }
                    .user-avtar-image {
                        background: #e0e0e0;
                        img {
                            min-width: 104%;
                            min-height: 104%;
                        }
                    }
                    .selectprivacy-drop {
                        .menu {
                            padding: 0;
                            margin: 0;
                        }
                    }
                    .uploads-section {
                        margin-top: 6px;
                        .uploads-btns {
                            display: flex;
                            width: 100%;
                            .btn-sm {
                                color: #2c2c2c;
                                border: 0;
                                box-shadow: none;
                                display: flex;
                                align-items: center;
                                .icon {
                                    color: #2c2c2c;
                                    font-size: 1.2em;
                                }
                            }
                        }
                    }
                }
                & .article {
                    padding: 10px;
                }
                & .supplierslist-wrapper {
                    padding: 10px;
                    background: $white;
                    .supplier-head {
                        padding: 15px 15px 15px 20px;
                        display: flex;
                        justify-content: space-between;
                        background: #f1f9ff;
                        border: 1px solid #2699fb;
                        border-radius: 6px;
                        color: $secondary-color;
                        h3 {
                            margin-bottom: 0;
                            color: $secondary-color;
                            font-size: $font-size-base + 2;
                            font-weight: 600;
                        }
                        p {
                            margin-bottom: 0;
                            font-size: $font-size-base - 1;
                        }
                        .right {
                            display: flex;
                            align-items: center;
                        }
                    }
                    .no-suppliers {
                        text-align: center;
                        padding: 60px 30px;
                        b {
                            color: $text-color-secondary;
                        }
                        img {
                            max-width: 220px;
                            display: block;
                            margin: 15px auto 30px auto;
                        }
                    }
                    .filter-select {
                        color: #818181;
                        background: #f8f8f8;
                        display: flex;
                        border: 1px solid #d1d1d1;
                        border-radius: 3px;
                        font-size: 12px;
                        align-items: center;
                        margin-left: 15px;
                        width: 100%;
                        max-width: 125px;
                        position: relative;
                        .filter {
                            position: absolute;
                            left: 5px;
                        }
                        .ui.selection.dropdown {
                            background: none;
                            appearance: none;
                            -webkit-appearance: none;
                            -moz-appearance: none;
                            border: 0;
                            font-weight: normal;
                            font-size: 12px;
                            height: 22px;
                            min-height: 22px;
                            max-height: 22px;
                            padding-left: 25px;
                            .text {
                                min-height: inherit;
                                line-height: 20px;
                                &::after {
                                    display: none;
                                }
                            }
                            .menu {
                                padding: 0;
                                .item {
                                    margin: 0;
                                    font-weight: normal;
                                    padding: 8px 10px !important;
                                }
                            }
                        }
                    }
                    .supplier-list {
                        .table {
                            tr {
                                td {
                                    padding: 8px 3px;
                                }
                            }
                            .follow-btn-right {
                                text-align: right;
                                .follow-btn-new {
                                    display: flex;
                                    align-items: center;
                                    float: right;
                                    min-width: 68px;
                                    justify-content: center;
                                    @media screen and (max-width: 767px) {
                                        justify-content: flex-end;
                                        float: right;
                                        margin-top: -18px;
                                    }
                                }
                            }
                            .supplier-details {
                                line-height: 1;
                                @media screen and (max-width: 767px) {
                                   padding-right: 88px;
                                }
                                .logo-img {
                                    width: 50px;
                                }
                                .addresses {
                                    font-size: $font-size-base - 2;
                                }
                                .supplierdetails-circle {
                                    width: 45px;
                                    height: 45px;
                                    min-width: 45px;
                                    border-radius: 45px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    background: $primary-color;
                                    color: $white;
                                    font-size: 16px;
                                    font-weight: 500;
                                    @media screen and (max-width: 767px) {
                                      width: 38px;
                                      height: 38px;
                                      min-width: 38px;
                                      border-radius: 38px;
                                    }
                                }
                            }
                            .suppliername {
                                margin-bottom: 0;
                                font-size: $font-size-base + 3;
                                color: $secondary-color;
                                font-weight: 600;
                                @media screen and (max-width: 767px) {
                                    font-size: $font-size-base + 2;
                                }
                                &:hover {
                                    color: $primary-color;
                                }
                            }
                            .contactlist {
                                display: flex;
                                align-items: center;
                                padding-left: 0;
                                position: relative;
                                min-width: 70px;
                                li {
                                    width: 32px;
                                    height: 32px;
                                    border-radius: 32px;
                                    overflow: hidden;
                                    position: absolute;
                                    border: 1px solid $white;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    color: $white;
                                    text-transform: uppercase;
                                    &:nth-child(1) {
                                        right: 0;
                                        z-index: 3;
                                        background: $primary-color;
                                        &.init-circles{
                                            border-color: $primary-color;
                                        }
                                    }
                                    &:nth-child(2) {
                                        right: 18px;
                                        z-index: 2;
                                        background: #ffa727;
                                        &.init-circles{
                                            border-color: #ffa727;
                                        }
                                    }
                                    &:nth-child(3) {
                                        right: 36px;
                                        z-index: 1;
                                        background: #8ac34a;
                                        &.init-circles{
                                            border-color: #8ac34a;
                                        }
                                    }
                                    img {
                                        width: 100%;
                                        min-height: 100%;
                                    }
                                }
                            }
                        }
                    }
                    // .supplier-head-add {
                    //     padding: 15px 15px 15px 20px;
                    //     background: #f1f9ff;
                    //     border-radius: 6px;
                    //     color: $secondary-color;
                    //     h3 {
                    //         margin-bottom: 0;
                    //         color: $secondary-color;
                    //         font-weight: 600;
                    //         font-size: $font-size-base + 2;
                    //     }
                    //     p {
                    //         margin-bottom: 0;
                    //         font-size: $font-size-base - 1;
                    //     }
                    //     .addnewcompany-link {
                    //         font-weight: 500;
                    //     }
                    //     .selectsupplier-drop {
                    //         background: #fafafa !important;
                    //         border: 1px solid $primary-color !important;
                    //         font-size: 12px;
                    //         height: 34px;
                    //         border-radius: 3px;
                    //         max-width: 443px;
                    //         min-width: auto;
                    //         padding: 0;
                    //         input {
                    //             border: 0 !important;
                    //             &.search {
                    //             }
                    //         }
                    //         input ~ .text {
                    //             line-height: 33px;
                    //             padding-left: 10px;
                    //         }
                    //         .text {
                    //             &::after {
                    //                 display: none;
                    //             }
                    //         }
                    //         .search.icon {
                    //             padding: 9px;
                    //             color: $primary-color;
                    //         }
                    //         .menu {
                    //             .item {
                    //                 border: 0;
                    //                 padding: 7px !important;
                    //             }
                    //         }
                    //     }
                    //     .selectedsupplier {
                    //         padding-top: 6px;
                    //         .badge {
                    //             border: 1px solid $primary-color;
                    //             border-radius: 15px;
                    //             background: rgba($color: $primary-color, $alpha: 0.2);
                    //             font-size: 10px;
                    //             line-height: 10px;
                    //             cursor: pointer;
                    //             margin-right: 5px;
                    //             display: inline-block;
                    //             margin-bottom: 2px;
                    //             .badge-inner {
                    //                 display: block;
                    //                 padding: 3px 6px;
                    //             }
                    //             .icon {
                    //                 width: 8px;
                    //                 fill: $primary-color;
                    //                 margin-left: 4px;
                    //             }
                    //             &:hover {
                    //                 background: rgba($color: $primary-color, $alpha: 0.4);
                    //             }
                    //         }
                    //     }
                    // }
                }
                & .rawmaterials-wrapper {
                    padding: 10px;
                    .no-rawmaterials {
                        text-align: center;
                        padding: 60px 30px;
                        b {
                            color: $text-color-secondary;
                        }
                        img {
                            max-width: 220px;
                            display: block;
                            margin: 15px auto 30px auto;
                        }
                    }
                    .rawmaterials-head {
                        padding: 15px 15px 15px 20px;
                        display: flex;
                        justify-content: space-between;
                        background: #f1f9ff;
                        border: 1px solid #2699fb;
                        border-radius: 6px;
                        color: $secondary-color;
                        h3 {
                            margin-bottom: 0;
                            color: $secondary-color;
                            font-size: $font-size-base + 2;
                            font-weight: 600;
                        }
                        p {
                            margin-bottom: 0;
                            font-size: $font-size-base - 1;
                        }
                        .right {
                            display: flex;
                            align-items: center;
                        }
                    }
                    .no-suppliers {
                        text-align: center;
                        padding: 60px 30px;
                        b {
                            color: $text-color-secondary;
                        }
                        img {
                            max-width: 220px;
                            display: block;
                            margin: 15px auto 30px auto;
                        }
                    }
                }
            }
        }
    }
}

.ui.button {
    &.follow-btn-new {
        background: #bce0fd;
        color: $secondary-color;
        padding: 4px 10px;
        .icon {
            margin-right: 2px !important;
            fill: $secondary-color;
            width: 9px;
        }
    }
}

.createa-supplier {
    .content {
        padding: 1.95rem !important;
        label {
            color: #818181 !important;
        }
        input {
            border-radius: 4px !important;
            &:focus {
                border-color: $primary-color !important;
            }
        }
        textarea {
            border-radius: 4px !important;
            resize: none !important;
            appearance: none !important;
            &:focus {
                border-color: $primary-color !important;
            }
        }
        .ui.selection.dropdown {
            border-radius: 4px !important;
            border: 1px solid #e4e7ea !important;
            font-weight: normal;
            background-image: none;
            padding: 0 0 0 10px;
            line-height: 33px;
        }
    }
}

.rawmaterial-list {
    box-shadow: none !important;
    border-top: 1px solid rgba(34, 36, 38, 0.15);
    .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.95em 1em;
        font-weight: 400;
        a {
            color: #1a1642;
            &:hover {
                color: #2699fb;
            }
        }
        span {
            color: #1a1642;
        }
        .icon {
            transform: rotate(-90deg);
            fill: #c6c6c6;
            width: 13px;
            margin-left: 15px;
        }
        &.active {
            .icon {
                transform: rotate(0deg);
            }
        }
    }
}

.visualiz-listing {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px;
    padding: 15px;
    .card-box {
        box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        cursor: pointer;
        &:hover {
            box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.15);
        }
        .card-upper {
            border-radius: 4px 4px 0 0;
            background: #f8f8f8;
            img {
                width: 100%;
            }
        }
        .card-bottom {
            padding: 8px;
            p {
                font-size: 15px;
            }
        }
    }
}


.nodatalist-wrapper {
    padding: 10px;
    .no-nodatalist {
        text-align: center;
        padding: 50px 30px;
        b {
            color: #243B53;
            font-size: 16px;
            font-weight: 700;
        }
        img {
            max-width: 220px;
            display: block;
            margin: 15px auto 30px auto;
        }
        p{
            color: #486581;
        }
    }
    .nodatalist-head {
        padding: 15px 15px 15px 20px;
        display: flex;
        justify-content: space-between;
        background: #f1f9ff;
        border: 1px solid #2699fb;
        border-radius: 6px;
        color: $secondary-color;
        h3 {
            margin-bottom: 2px;
            color: $secondary-color;
            font-size: $font-size-base + 3;
            font-weight: 600;
        }
        p {
            margin-bottom: 0;
            font-size: $font-size-base - 1;
        }
        .right {
            display: flex;
            align-items: center;
            padding-left: 15px;
            .ui.button{
                white-space: nowrap;
            }
        }
    }
}

.supplierfollow-btn-right{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @media screen and (max-width:767px) {
        position: absolute;
        top: 18px;
        right: -10px;
        padding: 0 !important;
    }
    .icon{
        margin: 0 10px -3px 0;
    }
}

.supplier-list{
    tr{
        position: relative;
    }
    .follow-btn-right{
        @media screen and (max-width:767px) {
            position: absolute;
            top: 18px;
            right: -10px;
            padding: 0 !important;
        }
    }
}

.modal.ui.creatacompany-modal {
    font-family: $inter-font;
    border-radius: 12px !important;
    @media screen and (max-width:576px) {
      min-height: 100%;
      margin: 0 !important;
      width: 100vw;
      position: fixed !important;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      border-radius: 0 !important;
    }
    .content {
      border-radius: 12px !important;
      @media screen and (max-width:576px) {
        border-radius: 0 !important;
      }
    }
    .addproduct-btn {
      height: 46px;
      padding: 0 28px;
      font-size: 15px;
      font-weight: 600;
    }
    .header {
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      border-radius: 12px 12px 0 0 !important;
      @media screen and (max-width:576px) {
        border-radius: 0 !important;
        padding: 0.75rem 1rem!important;
        justify-content: flex-start;
      }
      .closeicon-header {
        cursor: pointer;
        .icon {
          fill: #334E68;
          width: 12px;
        }
      }
      .backicon-header {
        display: flex;
        align-items: center;
        .icon {
          width: 15x;
          height: 15px;
          margin-right: 6px;
          fill: #334E68;
        }
      }
      h3{
        margin: 0;
        font-size: 18px;
        font-weight: 700;
        font-family: $inter-font;
        color: #243B53;
        font-size: 18px;
        font-weight: 700;
      }
    }
    .ui.form {
      @media screen and (max-width:576px) {
        display: flex;
        flex-direction: column;
      }
      .field {
        label {
          font-size: 16px;
          font-weight: 600;
          color: #243B53;
          font-family: $inter-font;
          margin-bottom: 4px;
          display: block;
          small {
            color: #A3B2C6;
            font-size: 13px;
            font-weight: 500;
          }
        }
        textarea {
          min-height: 48px;
          border: 1px solid #DBE2EB;
          border-radius: 6px;
          font-family: $inter-font;
          font-weight: 500;
          font-size: 14px;
          &::placeholder {
            font-weight: 500;
            font-size: 14px;
            color: #A3B2C6;
          }
          &::-webkit-placeholder {
            font-weight: 500;
            font-size: 14px;
            color: #A3B2C6;
          }
          &::-moz-placeholder {
            font-weight: 500;
            font-size: 14px;
            color: #A3B2C6;
          }
        }
        input {
          min-height: 48px;
          border: 1px solid #DBE2EB;
          border-radius: 6px;
          font-family: $inter-font;
          font-weight: 500;
          font-size: 14px;
          &::placeholder {
            font-weight: 500;
            font-size: 14px;
            color: #A3B2C6;
          }
          &::-webkit-placeholder {
            font-weight: 500;
            font-size: 14px;
            color: #A3B2C6;
          }
          &::-moz-placeholder {
            font-weight: 500;
            font-size: 14px;
            color: #A3B2C6;
          }
        }
        .selection.dropdown {
          min-height: 48px;
          border: 1px solid #DBE2EB;
          border-radius: 6px;
          padding: 0 32px 0 16px;
          color: #486581;
          background-image: none;
          .pil-item-row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-height: 48px;
          }
          .item {
            padding: 2px 8px !important;
          }
          & > .text {
            font-family: $inter-font;
            font-size: 14px;
            font-weight: 500;
            min-height: 48px;
            line-height: 48px;
            color: #486581;
            .right-col {
              display: none;
            }
            &::after {
              background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxMCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAgNkw1IDBMMTAgNkgwWiIgZmlsbD0iI0EzQjJDNiIvPgo8cGF0aCBkPSJNMCA4TDUgMTRMMTAgOEgwWiIgZmlsbD0iI0EzQjJDNiIvPgo8L3N2Zz4=");
              content: "";
              background-size: 10px;
              background-repeat: no-repeat;
              width: 10px;
              height: 15px;
              top: 50%;
              transform: translateY(-50%);
              right: -18px !important;
            }
          }
        }
      }
    }
  }
  
  .modals.dimmer {
    @media screen and (max-width:576px) {
      padding: 0 !important;
      overflow: hidden !important;
    }
  }