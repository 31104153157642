@import '../../assets/scss/variables.scss';

 .about-main{
    padding-top: 60px;
    .banner-section{
        min-height: calc(100vh - 60px);
        position: relative;
        @media screen and (max-width:767px){
            display: flex;
            flex-direction: column-reverse;
        }
        .aboutsmall-heading{
            @media screen and (max-width:767px){
                margin-top: 0 !important;
                padding-bottom: 10px;
            }
        }
        .row{
            min-height: calc(100vh - 180px); 
            @media screen and (max-width:767px){
                min-height: auto;
            }
        }
        h1{
            font-size: 4.2rem;
            font-weight: bold;
            color: black;
            @media screen and (max-width:767px){
                font-size: 2.5rem;
            }
        }
        .banner-img{
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            z-index: -1;
            max-height: 90%;
            @media screen and (max-width:767px){
                position: inherit;
                width: 100%;
                margin-top: -65px;
                margin-bottom: 0;
            }
        }
        .button {
            @media screen and (max-width:767px){
                width: 100%;
                margin-bottom: 10px;
            }
        }
    }
    .whoweare-section{
        text-align: center;
        @media screen and (max-width:767px){
            background: #F1F9FF;
            padding-top: 10px;
            margin-top: 80px !important;
        }
        .tri-shapes{
            display: none;
            @media screen and (max-width:767px){
                display: block;
                width: 120px;
                height: 120px;
                margin-top: -68px;
            }
        }
        .common-heading{
            @media screen and (max-width:767px){
                text-align: left !important;
            } 
        }
        .gotanyq-section{
            @media screen and (max-width:767px){
                text-align: left !important;
            }
            .button {
                @media screen and (max-width:767px){
                    display: none;
                }
            }
        }
        h2{
            @media screen and (max-width:767px){
                font-size: 1.8rem;
            }
        }
        p{
            font-size: 1.2rem;
            font-weight: 500;
        }
        h4{
            color: $secondary-color;
            font-size: 1.4rem;
            font-weight: 600;
            @media screen and (max-width:767px){
                font-size: 1.2rem;
            }
        }
    }
    .aboutproduct-section{
        .tri-shape{
            svg{
                max-width: 380px;
                width: 100%;
                margin-top: -100px;
                @media screen and (max-width:767px){
                    display: none;
                }
            }
        }
    }
 }
 
 .bottom-shape{
    margin-top: -10em;
    svg{
        width: 100%;
    }
 }
 

 .about-mobile-tri{
     text-align: right;
     display: none;
     @media screen and (max-width:767px){
        display: block;
    }
 }