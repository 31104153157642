@import '../../../../assets/scss//variables.scss';

.mobile-sidebar {
    background: $white;

    .dimmer {
        background: rgba(0, 0, 0, 0.2);
        position: absolute;
        right: 0;
        width: calc(100vw - 260px);
        height: 100vh;
        top: 0;
        right: 0;
    }
}

.overlay-bar {
    position: fixed;
    height: 100vh;
    width: 100vw;
    right: 0;
    top: 0;
    z-index: 9;
    background: #00000080;
    transition: all ease-in 0.2s;
}

.sidebar-mobile {
    position: fixed;
    height: 100vh;
    width: 100vw;
    right: -100%;
    top: 0;
    z-index: 9;
    // background: #00000080;
    transition: all ease-in 0.2s;

    &.opened {
        right: 0;
    }

    .sidebar-mobile-wrapper {
        height: 100vh;
        background: $white;
        width: calc(100vw - 5rem);
        margin-left: auto;
        font-family: $inter-font;
        text-align: left;

        .bar-header {
            height: 60px;
            cursor: pointer;
            color: #0D1A35;
            display: flex;
            align-items: center;
            padding: 0 1.5rem;
            font-size: 1.3rem;
            font-weight: 700;
            border-bottom: 1px solid #E5E8F0;

            .icon {
                fill: #0D1A35;
                width: 9px;
                margin-right: 12px;
            }
        }

        .bar-body {
            height: calc(100vh - 60px);
            max-height: calc(100vh - 60px);
            overflow-y: auto;
            overflow-y: auto;
            background: #F6F8FC;
            padding: 1rem 1rem 5.8rem 1rem;

            .user-card {
                background: $white;
                border: 1px solid #DBE2EB;
                border-radius: 12px;
                padding: 1.2rem;
                margin-bottom: 1rem;

                * {
                    font-family: $inter-font;
                }

                .titles {
                    font-size: 14px;
                    font-weight: 600;
                    color: #243B53;
                    margin-bottom: 0.2rem;
                }

                .extra-small {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-top: 1px solid #DBE2EB;
                    margin: 0;
                    font-family: $inter-font;
                    width: calc(100% + 2.4rem);
                    margin-left: -1.2rem;
                    background: url('data:image/svg+xml,<svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.234315 0.234315C0.546734 -0.0781049 1.05327 -0.0781049 1.36569 0.234315L5.86569 4.73431C6.1781 5.04673 6.1781 5.55327 5.86569 5.86569L1.36569 10.3657C1.05327 10.6781 0.546734 10.6781 0.234315 10.3657C-0.0781049 10.0533 -0.0781049 9.54673 0.234315 9.23431L4.16863 5.3L0.234315 1.36569C-0.0781049 1.05327 -0.0781049 0.546734 0.234315 0.234315Z" fill="%23A3B2C6"/></svg>');
                    background-repeat: no-repeat;
                    background-position: 96% 50%;
                    background-size: 8px;

                    &.border-0 {
                        border: 0;
                    }

                    &:last-child {
                        padding-bottom: 0;
                    }

                    a {
                        font-size: 12px;
                        font-weight: 500;
                        color: #486581;
                        padding: 1.2rem 0 1.2rem 1.5rem;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }

                    b {
                        font-size: 12px;
                        font-weight: 500;
                        color: #2166EE;
                        padding-right: 2.2rem;
                    }
                }

                .viewprofile-link {
                    font-size: 12px;
                    color: #2166EE !important;
                    margin-top: 3px;
                    display: block;
                }

                .extra {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 1.2rem 2.2rem 1.2rem 1.5rem;
                    border-top: 1px solid #DBE2EB;
                    margin: 0;
                    font-family: $inter-font;
                    width: calc(100% + 1.6rem);
                    margin-left: -0.8rem;
                    background: url('data:image/svg+xml,<svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.234315 0.234315C0.546734 -0.0781049 1.05327 -0.0781049 1.36569 0.234315L5.86569 4.73431C6.1781 5.04673 6.1781 5.55327 5.86569 5.86569L1.36569 10.3657C1.05327 10.6781 0.546734 10.6781 0.234315 10.3657C-0.0781049 10.0533 -0.0781049 9.54673 0.234315 9.23431L4.16863 5.3L0.234315 1.36569C-0.0781049 1.05327 -0.0781049 0.546734 0.234315 0.234315Z" fill="%23A3B2C6"/></svg>');
                    background-repeat: no-repeat;
                    background-position: 96% 20px;
                    background-size: 8px;

                    .rightnum-arrow {
                        padding-right: 2.2rem;
                    }

                    &.border-0 {
                        border: 0;
                    }

                    &:last-child {
                        padding-bottom: 0;
                    }

                    a {
                        font-size: 14px;
                        font-weight: 600;
                        color: #243B53;
                    }

                    span {
                        b {
                            font-size: 14px;
                            font-weight: 600;
                            color: #2166EE;
                        }
                    }
                }

                .userprofile-sidebar {
                    margin-top: 1.5rem;
                    width: calc(100% + 1rem);
                    margin-left: -0.5rem;

                    .profile-progress {
                        display: flex;
                        align-items: center;
                        padding: 12px 15px 15px 15px;
                        background: #FFF5DE;
                        border-radius: 10px;
                        margin-bottom: 0.5rem;

                        .CircularProgressbar-text {
                            font-weight: 600;
                            font-size: 24x;
                        }

                        .progreesbar {
                            min-width: 46px;
                            max-width: 46px;
                        }

                        .right-content {
                            margin-left: 10px;
                            color: #486581;
                            font-size: 12px;
                            font-family: $inter-font;
                            font-weight: 500;
                            line-height: 18px;

                            h3 {
                                font-size: 14px;
                                font-weight: 700;
                                color: #243B53;
                                margin-bottom: 5px;
                            }

                            a {
                                color: #2166EE;
                                font-weight: 600;
                            }
                        }
                    }
                }

                .upper-row {
                    display: flex;
                    align-items: center;

                    .left-image {
                        width: 64px;
                        height: 64px;
                        border-radius: 64px;
                        overflow: hidden;
                        margin-right: 1rem;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: fill;
                        }

                        .circular {
                            width: 64px;
                            height: 64px;
                            background: #19153F !important;
                            color: $white;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-family: $inter-font;
                            font-size: 1.8rem;
                            font-weight: 500;
                        }
                    }

                    .header {
                        .names {
                            margin-bottom: 0.15rem;

                            a {
                                font-weight: 700;
                                color: #243B53;
                                font-size: 1.2rem;
                                margin-bottom: 0.3rem;
                            }

                            .fa {
                                margin-left: 10px;
                            }
                        }

                        a {
                            color: #486581;
                            font-size: 14px;
                            font-weight: 500;
                        }
                    }
                }

                .bottom-row {
                    margin-top: 1rem;

                    h2 {
                        font-weight: 700;
                        color: #243B53;
                        font-size: 1.2rem;
                        margin-bottom: 0.3rem;
                    }

                    p {
                        color: #486581;
                        font-size: 14px;
                        font-weight: 500;
                    }
                }
            }

            .links-card {
                background: $white;
                border: 1px solid #DBE2EB;
                border-radius: 12px;
                font-family: $inter-font;
                margin-bottom: 1rem;

                a {
                    display: flex;
                    padding: 1rem;
                    border-bottom: 1px solid #DBE2EB;
                    background: url('data:image/svg+xml,<svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.234315 0.234315C0.546734 -0.0781049 1.05327 -0.0781049 1.36569 0.234315L5.86569 4.73431C6.1781 5.04673 6.1781 5.55327 5.86569 5.86569L1.36569 10.3657C1.05327 10.6781 0.546734 10.6781 0.234315 10.3657C-0.0781049 10.0533 -0.0781049 9.54673 0.234315 9.23431L4.16863 5.3L0.234315 1.36569C-0.0781049 1.05327 -0.0781049 0.546734 0.234315 0.234315Z" fill="%23A3B2C6"/></svg>');
                    background-repeat: no-repeat;
                    background-position: 96%;
                    background-size: 8px;

                    &:last-child {
                        border: 0;
                    }

                    .left-icon {
                        width: 32px;
                        margin-right: 4px;

                        .icon {
                            width: 24px;
                            fill: #334E68;
                        }
                    }

                    .right-dtl {
                        b {
                            font-size: 14px;
                            font-weight: 600;
                            color: #243B53;
                        }

                        p {
                            font-size: 12px;
                            font-weight: 500;
                            color: #486581;
                        }
                    }
                }
            }

            .bar-footer {
                text-align: center;
                margin-top: 3rem;
                font-family: $inter-font;
                font-size: 13px;
                color: #486581;

                img {
                    max-width: 102px;
                    margin-bottom: 0.3rem;
                    border-radius: 0;
                    width: 100%;
                    height: auto;
                }

                p {
                    font-weight: 500;
                    font-family: $inter-font;
                }
                a, span {
                    color: #2166EE;
                }
            }
        }
    }
}