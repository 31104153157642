@import '../../assets/scss/variables.scss';

.leftuserbox {
    &.connections{
        .content{
            .header{
                font-size: 14px !important;
            }
            .meta{
                font-style: inherit !important;
                p{
                    margin-bottom: 0;
                    color: $secondary-color;
                }
            }
            .extra{
                span{
                    margin: 0;
                    float: right;
                    color: $secondary-color;
                }
            }
        }
    }
}

.connetion-head{
    display: flex;
    align-items: center;
    h2{
        color: $secondary-color;
        font-weight: bold;
        font-size: 1.8rem;
        margin-bottom: 0;
    }
    button{
        height: 25px;
        line-height: 25px;
        padding: 0 12px !important;
        margin-left: 18px !important;
        font-size: 13px !important;
        font-weight: 600;
        &.background-light-blue{
            background: $primary-color !important;
        }
        &.ui.primary.button{
            height: 25px;
            line-height: 25px;
            padding: 0 12px !important;
        }
        & .visible.content{
            margin-right: 0 !important;
        }
        & .hidden.content{
            margin-top: 0 !important;
            top: 0 !important;
        }
    }
}

.connection-subhead{
    list-style-type: none;
    padding-left: 0;
    margin-top: 0;
    padding-left: 10px;
    li{ 
        display: inline-block;
        font-size: 1.2rem;
        color: rgba(26, 22, 66, 0.3);
        padding-right: 10px;
        margin-right: 10px;
        cursor: pointer;
        a{
            color: rgba(26, 22, 66, 0.3); 
        }
        &:first-child{
            border-right: 1px solid rgba(26, 22, 66, 0.3);
        }
        &.followuser{
            a{
                color: #2166EE;
                cursor: pointer;
                font-size: 14px;
                font-weight: 600;
            }
        }
        span{
            color: $secondary-color;
        }
        .icon{
            margin-right: 0;
        }
    }
}

.back-link{
    color: $secondary-color;
    margin-bottom: 10px;
    padding-top: 8px;
    a{
        color: $secondary-color;
    }
    i{
        font-size: 12px;
        margin-right: 0;
    }
}

.connections-list-heading{
    color: $secondary-color;
    font-weight: bold;
    font-size: 1.6rem;
    margin-bottom: 0;
}

.connections-list{
    .item{
        padding: 12px 15px 12px 12px !important;
        display: flex !important;
        align-items: center !important;
        justify-content: space-between !important;
        width: 100%;
        .connection-left{
            display: flex;
            align-items: center;
            width: 100%;
            h4{
                margin-bottom: 0;
                font-size: 1.35rem;
                color: $secondary-color;
            }
            .images{
                margin-right: 10px;
                border-radius: 50px;
                img{
                    width: 48px;
                    height: 48px;
                    margin: 5px;
                }
                .circular.label{
                    width: 48px;
                    height: 48px;
                    min-width: 48px;
                    margin-right: 20x;
                    font-size: 1.5rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
        .right {
            font-size: 1.25rem;
            padding-top: 15px;
            margin-right: 15px;
            span{
                color: $primary-color;
                font-size: 16px;
            }
        }
    }
}

.leftuserbox{
    &.productsleft-box{
        border-color: #DBE2EB;
        .item{
            .header{
                width: 100%;
                display: flex !important;
                align-items: center;
                justify-content: space-between;
                i{
                    color: rgba(129, 129, 129, 1);
                    font-size: 12px;
                }
            }
            .content{
                .meta{
                    font-style: inherit !important;
                    padding-top: 12px;
                    padding-bottom: 12px;
                    .meta-rows{
                        padding: 8px 0;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        span{
                            float: right;
                            color: $primary-color;
                            font-weight: bold;
                            font-size: 1.05rem;
                        }
                    }
                }
                .extra{
                    padding:10px 0 !important;
                    span{
                        float: right;
                        font-weight: bold;
                        font-size: 1.188rem;
                        margin: 0;
                        color: $secondary-color;
                    }
                }
            }
        }
        &.second-box{
            .meta{
                font-size: 14px !important;
                padding: 0 !important;
            }
        }
    }
}

.likesModal{
    .header{
        padding: 1rem 1.5rem 0 !important;  
        font-size: 1.1rem !important;
        border: 0 !important;
    }
    .modal-close{
        position: absolute;
        right: 22px;
        top: 18px;
        cursor: pointer;
        width: 13px;
        &:hover{
            opacity: 0.6;
        }
    }
}
