html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

body {
    font-weight: 400;
    line-height: 1.42857143;
    -webkit-overflow-scrolling: touch;
    letter-spacing: 0.1px;
}

html,
body {
    min-height: 100vh;
}

*,
*:after,
*:before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

@font-face {
    font-family: 'mappes-icon';
    src: url('./mappes-icon.eot?xb3rs4');
    src: url('./mappes-icon.eot?xb3rs4#iefix') format('embedded-opentype'),
        url('./mappes-icon.ttf?xb3rs4') format('truetype'), url('./mappes-icon.woff?xb3rs4') format('woff'),
        url('./mappes-icon.svg?xb3rs4#mappes-icon') format('svg');
    font-weight: normal;
    font-style: normal;
}

i.icon:before {
    display: inline-block;
    vertical-align: middle;
}

[class^='icon-'],
[class*=' icon-'],
i.icon.envelope,
i.icon.sitemap,
i.icon.list,
i.icon.search,
i.icon.content,
i.icon.ellipsis.horizontal,
i.icon.share.square,
i.icon.comment.outline,
i.icon.setting,
i.icon.power,
i.icon.cancel.sidebar-menu__toggle,
.icon-eye.icon,
.icon.icon-user-group {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'mappes-icon' !important;
    speak: none;
    font-style: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    speak: none;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-news-feed:before,
.icon.bars.icon-news-feed:before {
    content: '\e941';
}

.icon-user-group:before,
.icon.icon-user-group:before {
    content: '\e93e';
}

.icon-group-alt:before {
    content: '\e93f';
}

.icon-eye:before,
.icon-eye.icon:before {
    content: '\e940';
}

.icon-checked:before {
    content: '\e93d';
}

.icon-chats:before {
    content: '\e93c';
}

.icon-linkedin-alt:before {
    content: '\e93a';
}

.icon-group:before {
    content: '\e93b';
}

.icon-network:before {
    content: '\e937';
}

.icon-users:before {
    content: '\e938';
}

.icon-pdf:before {
    content: '\e939' !important;
}

.icon-check-mark:before {
    content: '\e936';
}

.icon-info:before {
    content: '\e935';
}

.icon-remove-circular:before {
    content: '\e933';
}

.icon-add-circular:before {
    content: '\e934';
}

i.icon-mappes:before {
    font-family: 'mappes-icon' !important;
}

i.icon-mappes {
    display: inline-block;
    opacity: 1;
    margin: 0 0.25rem 0 0;
    width: 1.18em;
    height: 1em;
    font-family: 'mappes-icon' !important;
    font-style: normal;
    font-weight: 400;
    text-decoration: inherit;
    text-align: center;
    speak: none;
    font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.icon-mappers-logo-sm-alt:before {
    content: '\e932';
    margin-top: 1px;
    display: block;
}

.icon-mappers-logo-sm:before {
    content: '\e931';
}

.icon-user-alt:before {
    content: '\e930';
}

.icon-home:before {
    content: '\e92f';
}

.icon-search-medium:before {
    content: '\e900';
}

.icon-close-medium:before {
    content: '\e901';
}

.icon-envelope-medium:before {
    content: '\e902';
}

.icon-clip:before {
    content: '\e903';
}

.icon-arrow-up:before {
    content: '\e904';
}

.icon-arrow-up-circle:before {
    content: '\e905';
}

.icon-arrow-down:before {
    content: '\e906';
}

.icon-arrow-down-circle:before {
    content: '\e907';
}

.icon-arrow-left:before {
    content: '\e908';
}

.icon-arrow-left-circle:before {
    content: '\e909';
}

.icon-arrow-right:before {
    content: '\e90a';
}

.icon-arrow-right-circle:before {
    content: '\e90b';
}

.icon-linkedin:before {
    content: '\e90c';
}

.icon-rocket:before {
    content: '\e90d';
}

.icon-flag:before {
    content: '\e90e';
}

.icon-check-box:before {
    content: '\e90f';
}

.icon-share:before {
    content: '\e910';
}

.icon-cloud-uplod:before {
    content: '\e911';
}

.icon-link:before {
    content: '\e912';
}

.icon-face-smile:before {
    content: '\e913';
}

.icon-plus:before {
    content: '\e914';
}

.icon-star-o:before {
    content: '\e915';
}

.icon-star:before {
    content: '\e916';
}

.icon-image:before {
    content: '\e917';
}

.icon-control-play:before {
    content: '\e918';
}

.icon-power-off:before {
    content: '\e919';
}

.icon-settings:before {
    content: '\e91a';
}

.icon-email:before {
    content: '\e91b';
}

.icon-user:before {
    content: '\e91c';
}

.icon-search:before {
    content: '\e91d';
}

.icon-thumbs-up:before {
    content: '\e91e';
}

.icon-share-alt:before {
    content: '\e91f';
}

.icon-speech:before {
    content: '\e920';
}

.icon-plus-medium:before {
    content: '\e921';
}

.icon-align-left:before {
    content: '\e922';
}

.icon-camera:before {
    content: '\e923';
}

.icon-chevron-up:before {
    content: '\e924';
}

.icon-chevron-down:before {
    content: '\e925';
}

.icon-chevron-left:before {
    content: '\e926';
}

.icon-chevron-right:before {
    content: '\e927';
}

.icon-menu:before {
    content: '\e928';
}

.icon-close:before {
    content: '\e929';
}

.icon-envolope:before {
    content: '\e92a';
}

.icon-options:before {
    content: '\e92b';
}

.icon-layers:before {
    content: '\e92c';
}

.icon-menu-alt:before {
    content: '\e92d';
}

.icon-logo:before {
    content: '\e92e';
}

i.icon.envelope {
    font-size: 15px;
}

i.icon.sitemap:before {
    content: '\e92c';
}

i.icon.envelope:before {
    content: '\e92a';
}

i.icon.list:before {
    content: '\e92d';
}

i.icon.search:before {
    content: '\e900';
}

i.icon.content:before {
    content: '\e928';
}

i.icon.ellipsis.horizontal:before {
    content: '\e92b';
}

.ui.dropdown i.icon.ellipsis.horizontal {
    font-size: 14px !important;
}

i.icon.share.square:before {
    content: '\e91f';
}

i.icon.comment.outline:before {
    content: '\e920';
}

i.icon.setting:before {
    content: '\e91a';
}

i.icon.power:before {
    content: '\e919';
}

i.icon.cancel.sidebar-menu__toggle:before {
    content: '\e929';
}

i.home.icon:before {
    content: '\e92f';
    margin-top: -4px;
}

i.user.user-alt.icon.icon-mappes:before {
    content: '\e930';
    margin-top: -4px;
}

i.icon.add.icon-mappes:before {
    content: '\e914';
}

/* div#root {
    height: inherit;
} */
.main-wrapper {
    /* padding-top: 60px; */
    overflow: auto;
    height: 100vh;
}

/* .main-wrapper > .pushable > .pusher {
    padding-top: 50px ;
} */
.ui.button {
    font-family: 'Assistant', sans-serif;
}

.ui.search .prompt::-webkit-input-placeholder {
    color: #ffffff;
}

.ui.search .prompt::-moz-placeholder {
    color: #ffffff;
}

.ui.search .prompt:-ms-input-placeholder {
    color: #ffffff;
}

.ui.search .prompt::placeholder {
    color: #ffffff;
    line-height: 1;
}

body *::-webkit-input-placeholder {
    font-weight: 400;
}

body *::-moz-placeholder {
    font-weight: 400;
}

body *:-ms-input-placeholder {
    font-weight: 400;
}

body *::placeholder {
    font-weight: 400;
}

.landingSearchBar input::-webkit-input-placeholder {
    color: #ffffff !important;
}

.landingSearchBar input::-moz-placeholder {
    color: #ffffff !important;
}

.landingSearchBar input:-ms-input-placeholder {
    color: #ffffff !important;
}

.landingSearchBar input::placeholder {
    color: #ffffff !important;
}

.searchHolder>.ui.search .prompt::-webkit-input-placeholder {
    color: #fff;
    opacity: 0.5;
    font-size: 14px;
}

.searchHolder>.ui.search .prompt::-moz-placeholder {
    color: #fff;
    opacity: 0.5;
    font-size: 14px;
}

.searchHolder>.ui.search .prompt:-ms-input-placeholder {
    color: #fff;
    opacity: 0.5;
    font-size: 14px;
}

.searchHolder>.ui.search .prompt::placeholder {
    color: #fff;
    opacity: 0.5;
    font-size: 14px;
}

.primary-background {
    background-color: #F6F8FC;
    min-height: 100vh;
    padding-top: 58px;
}

.main-wrapper>.pushable:not(body) {
    -webkit-transform: initial;
    transform: initial;
}

.blue-color {
    background-color: #1a1642 !important;
}

.ui.menu.topNav {
    height: 60px !important;
    border-radius: 0;
}

.ui.menu.topNav+.ui.container {
    flex: 1 1 100%;
    position: relative;
}

/* .ui.menu.topNav > .container > .item > img {
    width: 130px !important;
    padding-left: 30px !important;
} */
.navbar-logo {
    max-width: 60px;
    width: 100%;
    padding-left: 8px !important;
}

/* .navbar-logo__img {
    width: 112px;
} */

.navbar-logo__i {
    color: #fff;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dropdown-warapper {
    position: static;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.dropdown-box {
    border: 1px solid rgba(120, 130, 140, 0.13);
    width: 300px;
    position: absolute;
    top: 30px;
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.1);
    /*left: 50%;
    margin: 0 auto;
    transform: translateX(-50%);*/
    right: -20px;
    background-color: #fff;
}

.dropdown-box.dropdown-box-small {
    width: 140px;
}

.ui.menu .dropdown-box .ui.list:first-child {
    margin-bottom: 0;
    padding: 0 0;
    max-height: 390px;
    overflow-y: auto;
    font-size: 1.1rem;
}

.ui.menu .dropdown-box .item {
    padding: 0.62857143em 1.14285714em;
}

.ui.menu .dropdown-box .ui.list>.item {
    font-size: 14px;
    padding: 15px;
    border-bottom: 1px solid rgba(120, 130, 140, 0.13);
}

.ui.menu .dropdown-box .ui.list:first-child>.item {
    border-bottom: 1px solid rgba(120, 130, 140, 0.13);
    color: #6c777d;
}

.ui.menu .dropdown-box .ui.list:first-child>.item:hover {
    background-color: rgba(0, 0, 0, 0.02);
}

.ui.menu .dropdown-box .ui.list:first-child>.item:last-child {
    border-bottom: none;
}

.ui.menu .dropdown-box .ui.list:last-child>.item {
    padding: 10px 15px;
}

.ui.menu .dropdown-box .ui.list+.list {
    margin-top: 0;
    border-top: 1px solid rgba(120, 130, 140, 0.13);
    text-align: center;
}

.ui.menu .dropdown-box:not(.dropdown-box-small) .ui.list .item>.content {
    width: 100%;
    margin-left: -34px;
    padding-left: 44px;
}

.gradient-down {
    background: -webkit-linear-gradient(top, #08061c, #1a1642) !important;
    background: -o-linear-gradient(top, #08061c, #1a1642) !important;
    background: -moz-linear-gradient(top, #08061c, #1a1642) !important;
    background: linear-gradient(to top, #08061c, #1a1642) !important;
}

.gradient-right {
    background: -webkit-linear-gradient(left, #08061c, #1a1642) !important;
    background: -o-linear-gradient(right, #08061c, #1a1642) !important;
    background: -moz-linear-gradient(right, #08061c, #1a1642) !important;
    background: linear-gradient(to right, #08061c, #1a1642) !important;
}

.white-color {
    color: #ffffff !important;
}

.ui.labeled.icon.menu.nav-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    border: none;
    border-radius: 0;
    background-color: #f8f8f8;
    border-top: 1px solid #e9ecef;
    z-index: 10;
}

.ui.labeled.icon.menu.nav-bottom .item {
    padding: 0;
    font-size: 9px;
}

.ui.labeled.icon.menu.nav-bottom .item>i.icon {
    font-size: 1.3rem !important;
    margin-bottom: 3px !important;
    position: relative;
}

.ui.labeled.icon.menu.nav-bottom .item>i.icon .notify {
    top: 10px;
    position: absolute;
    right: 0px;
}

.ui.labeled.icon.menu.nav-bottom .item>i.icon.envelope:before {
    margin-top: -4px;
}

.padding-top-10 {
    padding-top: 10px;
}

.searchHolder {
    padding: 0px !important;
    width: 63.5%;
    width: -webkit-calc(100% - 418px);
    width: calc(100% - 418px);
}

.ui.mini.search {
    height: 100%;
    width: 100%;
}

.ui.icon.input {
    height: 100%;
}

.menu.right-menu {
    margin-left: 0 !important;
    /* margin-right: auto !important; */
}

.right-menu>.item img {
    margin-right: 0;
}

.right-menu .navbar-profile-pic img {
    width: 32px;
    height: 32px;
    border-radius: 32px;
}

.right-menu>.item,
.left.menu>.profile-mobile-toggle {
    padding-left: 12px !important;
    padding-right: 12px !important;
}

.topNav.ui.menu>.ui.container>.right.menu .navbar-profile-pic.item img {
    margin-right: 0;
}

.ui .left.menu>.profile-mobile-toggle {
    position: static;
}

.ui .left.menu>.profile-mobile-toggle .title i {
    font-size: 14px;
    display: inline-block;
    vertical-align: middle;
    opacity: 0.5;
    float: left;
    line-height: 0.8;
}

.ui .left.menu>.profile-mobile-toggle .title.active>i:before {
    content: '\e929';
}

.ui .left.menu>.profile-mobile-toggle .title {
    padding: 0;
}

.right-menu>.item>i.icon {
    margin-right: 0 !important;
}

.right-menu>.item>i.icon .notify {
    position: relative;
    transform: translateX(-3px) translateY(-6px);
}

.notify .heartbit {
    position: absolute;
    top: -20px;
    right: -16px;
    height: 25px;
    width: 25px;
    z-index: 10;
    border: 2px solid #ffffff;
    border-radius: 70px;
    -moz-animation: heartbit 1s ease-out;
    -moz-animation-iteration-count: infinite;
    -o-animation: heartbit 1s ease-out;
    -o-animation-iteration-count: infinite;
    -webkit-animation: heartbit 1s ease-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

.notify .point {
    width: 6px;
    height: 6px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    background-color: #fb9678;
    position: absolute;
    right: -6px;
    top: -10px;
}

@-moz-keyframes heartbit {
    0% {
        -moz-transform: scale(0);
        opacity: 0;
    }

    25% {
        -moz-transform: scale(0.1);
        opacity: 0.1;
    }

    50% {
        -moz-transform: scale(0.5);
        opacity: 0.3;
    }

    75% {
        -moz-transform: scale(0.8);
        opacity: 0.5;
    }

    100% {
        -moz-transform: scale(1);
        opacity: 0;
    }
}

@-webkit-keyframes heartbit {
    0% {
        -webkit-transform: scale(0);
        opacity: 0;
    }

    25% {
        -webkit-transform: scale(0.1);
        opacity: 0.1;
    }

    50% {
        -webkit-transform: scale(0.5);
        opacity: 0.3;
    }

    75% {
        -webkit-transform: scale(0.8);
        opacity: 0.5;
    }

    100% {
        -webkit-transform: scale(1);
        opacity: 0;
    }
}

.searchHolder>.ui.search .prompt {
    color: white;
    /* background: -webkit-linear-gradient(left, #08061c, #1a1642) !important;
    background: -o-linear-gradient(right, #08061c, #1a1642) !important;
    background: -moz-linear-gradient(right, #08061c, #1a1642) !important;
    background: linear-gradient(to right, #08061c, #1a1642) !important; */
    background: #5ea7de;
    padding-left: 20px;
    border-radius: 8px !important;
    border: 2px solid #2977c9 !important;
}

.searchHolder>.ui.search .prompt::placeholder {
    color: white;
    opacity: 1;
}

.searchHolder>.ui.search .prompt:-ms-input-placeholder {
    color: white;
}

.searchHolder>.ui.search .prompt::-ms-input-placeholder {
    color: white;
}

.searchHolder>.search>.ui.icon.input>.search.icon {
    color: white;
    /* opacity: 1; */
}

.art-header-meta a {
    font-style: normal !important;
}

a:hover {
    color: #00adee;
}

.a {
    color: black;
}

.ui.pushable>.visible.sidebar {
    width: 280px !important;
}

.intro-text-sidebar {
    font-size: 26px !important;
    font-family: 'Assistant', sans-serif !important;
    margin: 12px 0 10 !important;
    font-weight: 300 !important;
    letter-spacing: -0.1px !important;
}

.ui.modal.sign-in-reminder .signin-text {
    font-size: 2.2rem;
    color: #a0a4a6;
    font-weight: 100;
    letter-spacing: -0.1px !important;
    max-width: 330px;
    text-align: center;
    margin: 0 auto 20px;
    border-bottom: 1px solid #ddd;
    padding: 20px 10px;
    width: 100%;
    line-height: 2.8rem;
}

.ui.modal.sign-in-reminder {
    width: 100%;
    margin: 0 auto !important;
    transform: none !important;
    position: fixed !important;
    top: initial;
    left: initial !important;
    bottom: 0;
}

.ui.modal.sign-in-reminder>.content {
    width: 100%;
    position: fixed;
    z-index: 10;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 0;
    background: url('../landing/atlas.png') rgba(255, 255, 255, 0.9) no-repeat;
    background-size: 500px auto;
    background-position: right center;
    padding: 60px 15px;
}

.ui.modal.sign-in-reminder>.content .linkedin-img-small {
    display: inline-block;
    margin-left: 15px;
}

.ui.container {
    width: 100%;
}

.ui.search .prompt {
    border-radius: 0.25rem;
}

.pushable:not(body)>.ui.sidebar.sidebar-menu {
    position: fixed;
    text-align: left;
    z-index: 1002;
}

.ui.sidebar.sidebar-menu {
    overflow-x: hidden !important;
    overflow-y: auto !important;
    background: rgba(25, 22, 65, 1);
    background: -moz-linear-gradient(top, rgba(25, 22, 65, 1) 0%, rgba(28, 23, 28, 1) 100%);
    background: -webkit-gradient(left top,
            left bottom,
            color-stop(0%, rgba(25, 22, 65, 1)),
            color-stop(100%, rgba(28, 23, 28, 1)));
    background: -webkit-linear-gradient(top, rgba(25, 22, 65, 1) 0%, rgba(28, 23, 28, 1) 100%);
    background: -o-linear-gradient(top, rgba(25, 22, 65, 1) 0%, rgba(28, 23, 28, 1) 100%);
    background: -ms-linear-gradient(top, rgba(25, 22, 65, 1) 0%, rgba(28, 23, 28, 1) 100%);
    background: linear-gradient(to bottom, rgba(25, 22, 65, 1) 0%, rgba(28, 23, 28, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#191641', endColorstr='#1c171c', GradientType=0);
}

.ui.sidebar.sidebar-menu:before {
    content: '';
    background: url('../landing/atlas.png') no-repeat;
    background-size: 174%;
    background-position: center -35px;
    background-repeat: no-repeat;
    position: absolute;
    z-index: -2;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50%;
}

.main-wrapper .sidebar-menu .ui.container {
    width: 100%;
    position: relative;
}

.ui .sidebar-menu__toggle {
    color: #fff;
    margin: 0;
    cursor: pointer;
    font-size: 1.2em;
    line-height: 1em;
    position: absolute;
    right: 0;
    top: 0;
}

.ui.image.inline-image {
    display: inline-block;
}

.ui.segment {
    background: inherit;
}

.main-content-area {
    margin-top: 10px;
}

.ui.grid.stackable>.column.main-content-area {
    width: 100%;
    /* padding: 0 220px 0 180px; */
    /* margin-left: -180px; */
    /* margin-right: -220px; */
    max-width: 665px;
    margin: auto;
    padding-left: 0;
    padding-right: 0;
    margin-top: 0;
}

.ui.statistics.sidebar-state {
    flex-flow: column;
    margin-top: 30px;
}

.sidebar-state .ui.statistic {
    text-align: center;
    width: 100%;
    max-width: 140px;
    padding-bottom: 20px;
    border-bottom: 1px solid #fff;
    margin: 0 auto 20px !important;
}

.sidebar-state .ui.statistic:last-of-type {
    border-bottom: none;
}

.sidebar-state .ui.statistic .value img {
    margin: 0 auto;
}

.sidebar-state .ui.statistic .value {
    font-size: 66px !important;
    line-height: 64px;
    font-weight: 200;
}

.sidebar-state .ui.statistic .label {
    font-size: 14px !important;
    text-transform: none;
    color: #fff !important;
    opacity: 1;
    font-weight: normal;
    line-height: 1.42857143;
}

.ui.search>.results {
    max-height: 267px;
    overflow: auto;
}

.main-content {
    background-color: #fff;
    /* border: solid 1px #ccc; */
}

@media only screen and(max-width:991px) {
    .main-content {
        padding-top: 60px;
    }
}

.main-content_inner {
    background-color: rgba(241, 241, 242, 0.5);
}
.ui.grid.stackable > .column.left-content {
    max-width: 252px;
    width: 100% !important;
    padding: 0 10px 0 0 !important;
    position: relative;
    z-index: 1;
}

@media only screen and(max-width:991px) {
    .ui.grid.stackable>.column.left-content {
        max-height: calc(100vh - 80px);
        overflow-y: auto;
    }
}

.ui.grid.stackable>.column.left-content>.column.left-content {
    position: fixed;
    max-width: calc(252px - 10px);
    width: 100% !important;
}
.ui.grid.stackable > .column.right-content {
    max-width: 252px;
    width: 100% !important;
    padding-top: 0;
    padding-left: 10px !important;
}

.ui.grid.stackable>.column.right-content>.column.right-content {
    position: fixed;
    max-width: calc(252px - 10px);
    width: 100% !important;
    max-height: calc(100vh - 70px);
    overflow: auto;
    padding-bottom: 10px;
    /* position: -webkit-sticky;
    position: sticky;
    top: 0px; */
}

.ui.grid.stackable>.column.right-content>.column.right-content::-webkit-scrollbar {
    width: 0;
    height: 0;
}

body .ui.modal .ui.fluid.search .results.visible {
    display: block !important;
}

body .ui.modal .ui.search>.results .result>div {
    display: inline-block !important;
    margin: 3px !important;
}

.ui.grid.stackable>.column.right-content .label.background-light-blue {
    margin: 0 10px 5px 0;
}

.right-side-footer.ui.horizontal.list {
    margin-left: -1rem;
}

.right-side-footer.ui.horizontal.list>.item:first-child {
    margin-left: 1rem !important;
}

.panel-header {
    padding: 25px 15px !important;
}

.ui.selection.list>.item.rightBarList {
    color: #bbbdbf;
    display: flex;
    width: 100%;
    padding: 0.65em 0.5em 0.65em 0;
    align-items: center;
    justify-content: space-between;
}

.ui.selection.list>.item.rightBarList .right {
    order: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ui.selection.list>.item.rightBarList:hover {
    background: transparent;
}

.ui.selection.list>.item.rightBarList a.a {
    color: #bbbdbf;
    font-weight: 300;
    line-height: 1.3;
}

.ui.selection.list>.item.rightBarList:hover a,
.ui.selection.list>.item.rightBarList:hover i {
    color: #00adee;
}

.ui.selection.list>.item.rightBarList i {
    font-size: 10px;
}

.ui.dropdown .menu[direction='right'] {
    right: 0;
    left: initial;
    overflow: hidden;
    min-width: 160px;
}

.full-width {
    width: 100%;
}

.ui.input input {
    font-family: 'Assistant', sans-serif;
}

.ui.primary.button.follow-btn {
    margin-left: 10px;
    padding: 2px 8px 3px 7px !important;
    border-radius: 4px;
    font-weight: 300;
    line-height: 1.5;
}

.ui.table thead th {
    font-weight: 600;
}

.ui.primary.animated.button.follow-btn .visible.content {
    margin: 0 !important;
}

.ui.primary.button.follow-btn.animated .hidden.content {
    margin-top: -0.7em;
}

.color-green {
    color: #8bc53f;
}

.cursor-pointer,
a {
    cursor: pointer;
}

.font-18,
i.font-18 {
    font-size: 1.2em;
}

.font-14 {
    font-size: 14px !important;
}

.font-21,
i.font-21 {
    font-size: 1.3rem;
}

.company_detail--logo {
    width: 64px;
}

.update-image label {
    font-weight: 400;
    font-size: 12px;
    color: #979499;
    cursor: pointer;
}

.update-image label:hover {
    color: #00adee !important;
}

.update-image input[type='file'] {
    position: absolute;
    opacity: 0;
}

.company_detail-info {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    margin-left: -76px;
    padding-left: 88px;
}

.company_detail-info .h4 {
    font-size: 22px;
    font-weight: 500;
    color: #404041;
    line-height: 36px;
    line-height: 26px;
    margin-top: 5px;
}

.company_infoL-left-main {
    display: flex;
}

.company_infoL-left-main-image {
    width: 65px;
    height: 65px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 8px;
    background: #fff;
}

.company_infoL-left-main-image-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 98%;
    height: 98%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.company_infoL-left-main-image-inner img {
    height: auto;
    width: 100%;
    border-radius: 0 !important;
}

.company_infoL-left-main-image-inner .uploadLogo-imgae {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    cursor: pointer;
}

.company_infoL-left-main-image-inner .uploadLogo-imgae input {
    height: 100%;
    width: 100%;
}

.fw-600 {
    font-weight: 600 !important;
}

.state-head {
    font-weight: 600;
    margin-top: 5px;
    margin-bottom: 5px;
    color: #00aced;
    display: block;
    font-size: 80%;
}

.state-address {
    font-weight: 300;
    font-size: 12px;
    /*color: #babcbe;*/
    color: #808184;
    margin-bottom: 5px;
    line-height: 14px;
}

.state-mid {
    font-weight: 300;
    font-size: 26px;
    color: #6c777d;
    margin-bottom: 5px;
    line-height: 28px;
}

.ui.list i.list {
    padding: 0;
}

.ui.list>.item>i.icon:not(:last-child) {
    padding-right: 0;
}

.left-sidebar .ui.list>.item>.content {
    font-weight: 600;
    font-size: 15px;
    color: #949799;
}

.left-sidebar .ui.list>.item>.content a {
    color: inherit;
}

.left-sidebar>.ui.selection.list>.item.user-profile-element {
    padding: 17px 7px 16px 8px !important;
}

.left-sidebar .ui.selection.list>.item {
    padding: 10px 7px 10px 15px;
}

.left-sidebar .ui.selection.list>.item,
.left-sidebar .ui.selection.list .list>.item {
    border-radius: 0;
}

.left-sidebar .ui.selection.list.list>.item:hover,
.left-sidebar .ui.selection.list>.item:hover {
    color: #949799;
}

i.icon.list-arrow-small,
.left-sidebar .ui.list>.item>.content i.icon.list-arrow-small {
    font-size: 10px;
    font-weight: 100;
    color: rgba(0, 0, 0, 0.4);
}

.left-sidebar .ui.selection.list .inner-list.list {
    padding-top: 0;
    padding-bottom: 0;
}

.left-sidebar .ui.selection.list .inner-list.list.my-product-list {
    /* overflow: auto;
    max-height: calc(100vh - 212px); */
}

.left-sidebar .ui.selection.list .inner-list.list>.item {
    padding: 10px 10px 10px 7px;
}

.left-sidebar .ui.selection.list .inner-list.list>.item.inner-list-profile {
    padding-left: 9px;
}

.left-sidebar .ui.selection.list .inner-list.list>.item.inner-list-profile:hover i {
    color: #00adee !important;
}

.left-sidebar .ui.selection.list .inner-list.list>.item .content,
.left-sidebar .ui.selection.list .inner-list.list>.item .content a {
    font-size: 12px;
    color: #949799;
    font-weight: 400;
}

.left-sidebar .ui.selection.list .inner-list.list>.item .content a {
    display: flex;
    flex-wrap: wrap;
}

.left-sidebar .ui.selection.list .inner-list.list>.item .content a span {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    align-self: center;
    line-height: 14px;
}

.left-sidebar .ui.selection.list .inner-list.list>.item .content a .label {
    font-size: 10px;
    border-radius: 60px;
    padding: 6px 8px;
    min-width: 30px;
    height: 20px;
    background-color: #00adee !important;
    border-color: #00adee !important;
    text-align: center;
    line-height: 7px;
    align-self: center;
}

.left-sidebar .ui.selection.list .inner-list.list>.item .content {
    width: 100%;
}

.left-sidebar .ui.selection.list .inner-list.list>.item:hover,
.left-sidebar .ui.selection.list .inner-list.list>.item:active,
.left-sidebar .ui.selection.list .inner-list.list>.item:focus,
.left-sidebar .ui.selection.list .inner-list.list>.item .content:hover,
.left-sidebar .ui.selection.list .inner-list.list>.item .content a:hover,
.left-sidebar .ui.selection.list .inner-list.list>.item .content:focus,
.left-sidebar .ui.selection.list .inner-list.list>.item .content a:focus,
.left-sidebar .ui.selection.list .inner-list.list>.item .content:active,
.left-sidebar .ui.selection.list .inner-list.list>.item .content a:active,
.left-sidebar .ui.selection.list .inner-list.list>.item:hover .content a,
.left-sidebar .ui.selection.list .inner-list.list>.item:hover .content,
.left-sidebar .ui.selection.list .inner-list.list>.item:active .content a,
.ui.dropdown .menu>.item.dropDownThreeDots:hover {
    color: #00adee !important;
}

.left-sidebar .ui.selection.list .inner-list.list>.item:active {
    background-color: transparent;
}

/* .left-sidebar .ui.selection.list .inner-list.list > .item .content i {
    margin-right:
} */
.left-content .no-auth>.button {
    margin-left: 15px;
}

.ui.avatar.image.avatar-42 {
    width: 42px;
    height: 42px;
}

.ui.avatar.image.avatar-40 {
    width: 40px;
    height: 40px;
}

.ui.avatar.image.avatar-36 {
    width: 36px;
    height: 36px;
}

.ui.avatar.image.avatar-34 {
    width: 34px;
    height: 34px;
}

.ui.avatar.image.avatar-28 {
    width: 28px;
    height: 28px;
}

.ui.label.notification-initials {
    height: 34px;
    width: 34px;
    font-size: 16px;
    margin: 0px !important;
    background-color: #00adee !important;
}

.ui.label.notification-initials-40 {
    height: 40px;
    width: 40px;
    font-size: 18px;
    margin: 0px !important;
    background-color: #00adee !important;
}

.ui .user-profile-element .user-initials {
    display: inline-block;
    vertical-align: middle;
    width: 42px;
    height: 42px;
    border-radius: 100%;
    background-color: #00adee;
    color: #fff;
    text-align: center;
    line-height: 42px;
    font-size: 20px;
    font-weight: 300;
}

.ui .user-profile-element .user-initials+.content {
    display: inline-block;
    padding-left: 0.3em;
}

.ui.label.notification-initials+.content {
    display: inline-block;
}

.ui.image.user-avtar-image {
    border: 3px solid #fff;
    width: 100%;
    height: inherit;
    border-radius: 100%;
}

.profile-info-bar {
    width: 100%;
    display: flex;
    align-items: center;
}

.user-profile-messageicon {
    margin-top: 20px;
}

@media only screen and (max-width: 767px) {
    .profile-info-bar {
        display: block;
    }
}

.profile-info-bar .profile-image {
    position: relative;
    border: 5px solid #fff;
    border-radius: 250px;
    overflow: hidden;
}

.profile-info-bar .profile-image img {
    position: absolute;
    transform: translatex(-50%) translateY(-50%);
    top: 50%;
    left: 50%;
    border: none !important;
    min-height: 100%;
}

.profile-info-text {
    display: inline-block;
    vertical-align: top;
    width: 100%;
}

.profile-image {
    width: 90px;
    height: 90px;
    display: inline-block;
    vertical-align: top;
    color: white;
    background-color: #00adee;
    text-align: center;
    font-size: 50px;
}

.profile-name {
    margin-bottom: 6px;
    padding-right: 30px;
    position: relative;
}

.profile-name-text {
    font-size: 22px;
    font-weight: 400;
    letter-spacing: -0.5px;
    color: #404041;
    display: inline-block;
    vertical-align: sub;
    margin-right: 15px;
}

.profile-name>a {
    float: right;
}

.profile-drop-down {
    position: absolute;
    right: 0;
    top: 0;
}

.profile-designation {
    font-size: 20px;
    font-weight: 300;
    letter-spacing: -0.5px;
    color: #404041;
}

.profile-details {
    margin-bottom: 10px;
}

/* .profile-company {
} */
.profile-network {
    font-weight: 600;
    color: #00adee;
}

.l-h-20 {
    line-height: 20px;
}

i.icon:before {
    /* content: "\F011"; */
    display: inline-block;
    vertical-align: middle;
    margin-left: auto;
    margin-right: auto;
}

.ui.dropdown .menu>.item:hover {
    background: #f7fafc;
}

.ui.green.button,
.ui.green.buttons .button {
    background-color: #8bc53f;
}

.ui.green.button:hover,
.ui.green.buttons .button:hover {
    background-color: #80ad3a;
}

.ui.mini.button.mappes-small-button,
.ui.mini.button.mappes-small-button.a {
    font-size: 13px;
    line-height: 18px;
    padding: 3px 8px;
    border-radius: 4px;
    font-weight: 300;
}

.ui.mini.button.btn-sm,
.ui.mini.button.btn-sm.a {
    font-size: 12px;
    line-height: 1.25;
    padding: 5px 10px;
    border-radius: 4px;
    font-weight: 300;
}

.ui.button {
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

.ui.basic.button {
    color: #6c777d !important;
    background: #ffffff !important;
}

.ui.basic.mini.button.btn-sm.active {
    -webkit-box-shadow: 0 0 0 2px rgba(2, 117, 216, 0.25);
    box-shadow: 0 0 0 2px rgba(2, 117, 216, 0.25);
    /* opacity: 0.8; */
    background: #e4e7ea !important;
}

.ui.basic.button:hover,
.ui.basic.buttons .button:hover {
    /* opacity: 0.8; */
    background: #e4e7ea !important;
    color: #00adee !important;
    -webkit-box-shadow: 0 0 0 1px #e4e7ea inset, 0 0 0 0 #e4e7ea inset;
    box-shadow: 0 0 0 1px #e4e7ea inset, 0 0 0 0 #e4e7ea inset;
}

.ui.primary.button,
.ui.primary.buttons .button {
    background-color: #2166EE;
}

.ui.primary.button:hover,
.ui.primary.buttons .button:hover {
    /* opacity: 0.8; */
}

.ui.menu {
    font-family: 'Assistant', sans-serif;
}

.custom-tab .ui.secondary.pointing.menu {
    border-bottom: 2px solid #f7fafc;
}

.custom-tab .ui.pointing.secondary.menu a,
.custom-tab .ui.pointing.secondary.menu a.active {
    padding: 10px 15px;
    color: #6c777d;
    font-weight: 300;
    line-height: 42px;
    font-size: 19px;
    letter-spacing: -0.1px;
    margin-top: 10px;
}

.custom-tab .ui.pointing.secondary.menu a.active,
.custom-tab .ui.pointing.secondary.menu a.active:hover,
.custom-tab .ui.pointing.secondary.menu a:hover {
    border-bottom: 2px solid #00adee;
    color: #6c777d;
}

.custom-tab .ui.pointing.secondary.menu a:active:after,
.custom-tab .ui.pointing.secondary.menu a.active:after {
    color: #00adee;
}

.custom-tab .ui.segment {
    box-shadow: none;
    border: none;
    padding-left: 0;
    padding-right: 0;
}

.mobile-menu-icon-tab .ui.pointing.secondary.menu a.item:after {
    content: '';
    font-family: Icons;
    position: static;
    transform: none;
    visibility: visible;
    border: none;
    background: none;
    vertical-align: middle;
    font-size: 14px;
}

@media screen and (max-width:767px) {
    .mobile-menu-icon-tab .ui.pointing.secondary.menu a.item:after {
        margin-top: -9px;
        margin-left: 9px;
    }

    .productpage-wip .product-bottom .product-bottom-inner .product-tabes .menu a.item {
        margin-right: 8px;
        padding-right: 8px;
    }
}

.mobile-menu-icon-tab .ui.pointing.secondary.menu a.item:first-child:after {
    content: '\f11d';
}

.mobile-menu-icon-tab .ui.pointing.secondary.menu a.item:nth-child(2):after {
    content: '\f135';
}

.mobile-menu-icon-tab .ui.pointing.secondary.menu a.item:nth-child(3):after {
    content: '\e918';
    font-family: 'mappes-icon' !important;
}

.mobile-menu-icon-tab .ui.pointing.secondary.menu a.item:nth-child(4):after {
    content: '\f03e';
}

.articles article:last-child {
    border-bottom: none;
    margin-bottom: 0;
}

.articles article {
    display: block;
    border-bottom: 1px solid #eee;
    min-height: 15px;
    margin-bottom: 20px;
}

.article-header {
    margin-bottom: 20px;
    position: relative;
}

.art-header-img {
    width: 48px;
    height: 48px;
    min-width: 48px;
    line-height: 100%;
    border-radius: 48px;
    overflow: hidden;
    margin-right: 10px;
    background: #19153F;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    font-weight: 500;
    font-family: "Inter", sans-serif;
    
}

.art-header-img .label {
    display: flex;
    justify-content: center;
    justify-content: center;
    height: 48px;
    line-height: 48px;
}

.art-header-img img.ui.image.user-avtar-image {
    min-width: 100%;
    min-height: 100%;
    height: auto;
    max-height: initial;
    border: none !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.art-header-meta {
    display: inline-block;
    /* vertical-align: top; */
    padding-left: 10px;
    font-size: 11.5px;
    color: #949799;
    font-weight: 300;
    width: 100%;
    margin-left: -50px;
    padding-left: 60px;
    margin-right: -21px;
    padding-right: 21px;
}

.art-header-meta a {
    display: block;
    font-size: 18px;
    font-style: italic;
    color: #00adee;
    font-weight: 400;
}

.art-header-drop-down {
    display: inline-block;
    vertical-align: top;
}

article .article-body,
article .article-footer {
    /* display: table; */
    width: 100%;
}

article .art-body-text {
    margin-bottom: 20px;
    letter-spacing: 0.1px;
    font-size: 14px;
    line-height: 1.42857143;
    color: black;
}

article .art-body-media {
    margin-bottom: 15px;
}

article .art-body-media a {
    max-width: 100%;
}

article .article-footer .art-footer-top {
    margin-bottom: 12px;
    color: #949799;
    line-height: 20px;
}

article .article-footer .art-footer-bottom {
    border-top: 1px solid #eee;
    padding: 15px 0;
    color: #949799;
}

.ui.form.custom-input-form {
    padding-left: 0.6rem;
    padding-right: 0.6rem;
}

.ui.form.custom-input-form .field>label {
    font-family: 'Assistant', sans-serif;
    color: #ccc;
    font-weight: 400;
    margin-bottom: 0;
    font-size: 14px;
}

.page-head-h4 {
    font-size: 16px;
    color: #333;
    font-weight: 600;
    margin-bottom: 30px !important;
}

.ui.form.custom-input-form .fields {
    margin-bottom: 0;
}

.ui.form.custom-input-form .fields>.field {
    margin-bottom: 40px;
}

.ui.form.custom-input-form .fields>.field.mB-0 {
    margin-bottom: 0;
}

.ui.form.custom-input-form .ui.input input,
.ui.form.custom-input-form textarea,
.ui.selection.dropdown {
    font-family: 'Assistant', sans-serif;
    color: #333;
    font-weight: 600;
    font-size: 16px;
    height: 34px;
    line-height: 1.3333333;
    background-color: rgba(0, 0, 0, 0);
    background-position: center bottom, center calc(99%);
    background-repeat: no-repeat;
    background-size: 0 2px, 100% 1px;
    padding: 0;
    transition: background 0s ease-out 0s;
    background-image: linear-gradient(#25a8df, #25a8df),
        linear-gradient(rgba(37, 168, 223, 0.75), rgba(37, 168, 223, 0.75));
    border: 0;
    border-radius: 0;
    box-shadow: none;
    float: none;
}

.ui.selection.dropdown {
    min-height: 34px;
}

.ui.selection.dropdown>.text {
    line-height: 34px;
    position: relative;
    width: 100%;
}

.ui.selection.dropdown>.text:after {
    content: '\E925';
    font-size: 10px;
    font-family: 'mappes-icon' !important;
    position: absolute;
    top: 0;
    right: 10px;
    transition: all 200ms ease-in-out;
}

.ui.active.visible.selection.dropdown>.text:after {
    transform: rotateZ(180deg);
}

.ui.dropdown:not(.button)>.default.text {
    color: #999;
    font-weight: 400;
}

.ui.form.custom-input-form textarea {
    height: auto;
    padding-top: 5px;
    min-height: 44px;
}

.ui.form.custom-input-form .ui.input input:focus,
.ui.form.custom-input-form textarea:focus,
.ui.selection.dropdown:focus {
    background-size: 100% 2px, 100% 1px;
    outline: 0;
    transition-duration: 0.3s;
}

.ui.selection.visible.dropdown>.text:not(.default) {
    font-weight: 600;
    color: #333;
}

.ui.form.custom-input-form .ui.input input::-webkit-input-placeholder,
.ui.form textarea::-webkit-input-placeholder {
    color: #999;
    opacity: 1;
}

.ui.form.custom-input-form .ui.input input::-moz-placeholder,
.ui.form textarea::-moz-placeholder {
    color: #999;
    opacity: 1;
}

.ui.form.custom-input-form .ui.input input:-ms-input-placeholder,
.ui.form textarea:-ms-input-placeholder {
    color: #999;
    opacity: 1;
}

.ui.form.custom-input-form .ui.input input::placeholder,
.ui.form.custom-input-form textarea::placeholder {
    color: #999;
    opacity: 1;
}

.ui.mini.button {
    padding: 6px 10px;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.25;
}

.input-type-file.large {
    cursor: pointer;
    position: relative;
    text-align: center;
    min-height: 100px;
    border: 1px dashed #e5e5e5;
}

.input-type-file.large:hover {
    background-size: 30px 30px;
    background-image: -webkit-linear-gradient(135deg,
            #f6f6f6 25%,
            transparent 25%,
            transparent 50%,
            #f6f6f6 50%,
            #f6f6f6 75%,
            transparent 75%,
            transparent);
    background-image: linear-gradient(-45deg,
            #f6f6f6 25%,
            transparent 25%,
            transparent 50%,
            #f6f6f6 50%,
            #f6f6f6 75%,
            transparent 75%,
            transparent);
    -webkit-animation: stripes 2s linear infinite;
    animation: stripes 2s linear infinite;
}

.toggle-custom .toggle-label {
    color: #ccc;
    font-size: 80%;
    font-weight: 400;
    line-height: 1.6;
    margin-right: 5px;
}

.toggle-custom .ui.fitted.toggle.checkbox,
.toggle-custom .ui.fitted.toggle.checkbox input {
    vertical-align: middle;
    border-radius: 20px;
    height: 22px;
    width: 35px;
    min-height: 20px;
}

.toggle-custom .ui.toggle.checkbox label {
    padding-top: 0;
    height: 22px;
}

.toggle-custom .ui.toggle.checkbox label:before {
    height: 22px;
    width: 35px;
    background-color: rgb(255, 255, 255);
    border-color: rgb(223, 223, 223);
    box-shadow: rgb(223, 223, 223) 0px 0px 0px 0px inset;
    transition: border 0.4s, box-shadow 0.4s;
}

.toggle-custom .ui.toggle.checkbox label:after {
    height: 20px;
    width: 20px;
    background: #fff;
    border-radius: 100%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4) !important;
    position: absolute;
    top: 1px;
    left: 1px;
    background-clip: content-box;
}

.toggle-custom .ui.toggle.checkbox input:focus~label:before,
.toggle-custom .ui.toggle.checkbox label:hover::before,
.toggle-custom .ui.toggle.checkbox label:before {
    background-color: #fff;
    border: 1px solid #dfdfdf;
}

.toggle-custom .ui.toggle.checkbox input:checked~label:before,
.toggle-custom .ui.toggle.checkbox input:focus:checked~label:before {
    background-color: rgb(139, 197, 63) !important;
    border-color: rgb(139, 197, 63);
}

.toggle-custom .ui.toggle input:checked~label:before {
    border-color: rgb(139, 197, 63);
    box-shadow: rgb(139, 197, 63) 0px 0px 0px 11px inset;
    transition: border 0.4s, box-shadow 0.5s, background-color 1.2s;
}

.toggle-custom .ui.toggle.checkbox input:checked~label:after {
    left: 14px;
}

.ui.form.custom-input-form .ui.radio.checkbox label {
    font-family: 'Assistant', sans-serif;
    color: #333;
    font-weight: 400;
    font-size: 14px;
}

.ui.form.custom-input-form .ui.checkbox input[type='radio']:checked label,
.ui.form.custom-input-form .ui.checkbox.checked label {
    color: #333;
    font-weight: 600;
}

.ui.checkbox input:checked~.box:before,
.ui.checkbox input:checked~label:before {
    border-color: rgb(48, 127, 211);
}

.ui.form.custom-input-form .ui.radio.checkbox label:before {
    width: 18px;
    height: 18px;
    top: 0px;
}

.ui.radio.checkbox .box:after,
.ui.radio.checkbox label:after {
    top: -2px;
    left: -2px;
    width: 22px;
    height: 22px;
}

.ui.form.custom-input-form .ui.radio.checkbox input:checked~label:after {
    background-color: #3681d0;
}

.radio-group-divided .field {
    border-bottom: 1px solid rgba(120, 130, 140, 0.13);
    padding: 15px 0 15px 0;
}

.radio-group-divided .field:last-child {
    border: none;
    padding: 15px 0 0 0;
}

.radio-group-divided .field.no-border {
    border: none;
    padding: 0;
}

.radio-group-divided .field.no-border+.field {
    padding-top: 10px;
}

@-webkit-keyframes stripes {
    from {
        background-position: 0 0;
    }

    to {
        background-position: 60px 30px;
    }
}

@keyframes stripes {
    from {
        background-position: 0 0;
    }

    to {
        background-position: 60px 30px;
    }
}

.input-type-file.large .file-input,
.company_detail--logo .file-input {
    background: transparent;
    cursor: pointer;
    border: none;
    border-radius: 0;
    opacity: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
    left: 0;
    top: 0;
}

.input-type-file.large i {
    font-size: 48px;
    line-height: 0.9;
    margin: 10px 0 0 0;
    display: inline-block;
    opacity: 0.5;
}

.input-type-file.large p {
    margin-top: 10px;
    letter-spacing: 0.1px;
    color: #777;
    font-family: Roboto, 'Helvetica Neue', Helvetica, Arial;
}

.ui.accordion .title~.content.profile-mobile-drop-down {
    padding-bottom: 15px !important;
}

.search-bar-mobile {
    padding: 15px;
}

.profile-mobile-drop-down .left-sidebar>.ui.selection.list {
    overflow: auto;
    max-height: 185px;
}

.footer {
    padding: 20px 15px 20px 15px;
    /* position: absolute; */
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
}

.footer .copright {
    color: rgba(0, 0, 0, 0.7);
}

.footer .ui.link.list .item {
    color: rgba(0, 0, 0, 0.7);
}

.footer .ui.link.list .item:hover,
.right-side-footer.ui.link.list>.item:hover {
    color: #00adee;
}

.upcomming-events-list {
    margin-top: 0;
    border-radius: 0;
}

.ui.selection.list>.item.upcomming-event-item {
    padding: 0;
    border: none;
    border-radius: 0;
}

.ui.selection.list>.item.upcomming-event-item>.content>a {
    display: flex;
    width: 100%;
    flex: 0 0 100%;
}

.upcomming-events-head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    border-top: 1px solid rgba(34, 36, 38, 0.1);
    padding: 10px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
}

.upcomming-events-head a {
    color: #666;
    font-weight: normal;
}

.upcomming-events-head a:hover {
    color: #00adee;
}

.upcomming-event-item .ui.card {
    border: none;
    border-radius: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0 5px 0 10px;
    /* background: transparent; */
    box-shadow: none;
    flex: 0 0 100%;
    max-width: 100%;
    border-top: 1px solid rgba(34, 36, 38, 0.1);
}

.upcomming-event-item .ui.card .image {
    width: 36px;
    height: 36px;
    margin-top: 10px;
    position: relative;
    overflow: hidden;
    border-radius: 0 !important;
    box-shadow: 0px 0px 0px 1px rgba(34, 36, 38, 0.1);
}

.upcomming-event-item .ui.card .image>img {
    position: absolute;
    left: 50%;
    top: 50%;
    height: auto;
    width: 100%;
    min-width: 100%;
    transform: translateX(-50%) translateY(-50%);
}

.upcomming-event-item .ui.card .content {
    padding: 10px 0 10px 10px;
    border: none;
    flex-basis: calc(100% - 36px);
    max-width: calc(100% - 36px);
}

.upcomming-event-item .ui.card .content .description:empty {
    display: none;
}

.upcomming-event-item .ui.card .content .description {
    font-size: 14px;
    word-break: break-all;
}

.upcomming-event-item .ui.card .content .header {
    font-size: 14px;
    font-weight: 600;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    white-space: nowrap;
}

.upcomming-event-item .ui.card .content .meta {
    font-size: 12px;
    line-height: 1.2;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    white-space: nowrap;
}

.ui.table td.toggle-cell-icon {
    max-width: 42px;
    width: 42px;
    text-align: center;
    padding-left: 8px;
    padding-right: 8px;
}

.ui.table td.toggle-cell-icon .ui.mini.button {
    padding: 0.398rem 0.5rem;
}

.ui.table td.toggle-cell-icon .ui.mini.button:hover,
.ui.table td.toggle-cell-icon .ui.mini.button.active {
    color: #fff !important;
    background: #00adee !important;
    box-shadow: 0 0 0 1px #00adee inset !important;
}

.toggle-star {
    color: #fec107;
    font-size: 14px;
    cursor: pointer;
}

.userImgUpload {
    position: relative;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 30px;
    user-select: none;
}

.userImgUpload .imgPreview {
    position: relative;
    width: 70px;
    height: 70px;
    border-radius: 100%;
    overflow: hidden;
    background: #231b56;
    border: 7px solid rgba(232, 230, 236, 1);
    box-shadow: 0 0 0 4px rgba(232, 230, 236, 0.3);
}

.userImgUpload .imgPreview img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: inline-block;
    min-height: 100%;
    max-width: 100%;
}

.userImgUpload .fileInputBtnWrapper {
    position: absolute;
    top: 50%;
    right: 0;
    cursor: pointer;
}

.userImgUpload .fileInputBtn {
    position: relative;
    background-color: #3681d0;
    border: 1px solid #fff;
    border-radius: 100px;
    overflow: hidden;
    width: 20px;
    height: 20px;
    color: #fff;
    font-size: 9px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
}

.userImgUpload .fileInputBtn:hover {
    background-color: #1678c2;
}

.userImgUpload .fileInput {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
}

.userImgUpload .fileInputBtn span {
    cursor: pointer;
}

.userInfo_tab {
    width: 100%;
    position: relative;
}

.tabWrapper {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.tabWrapper>.ui.segment.tab {
    border: none;
    padding: 0;
    margin: 0;
    box-shadow: none;
    width: 100%;
}

.tabWrapper>.ui.attached.menu {
    border: none;
    margin: -40px auto 0;
    align-self: center;
    display: flex;
    height: 40px;
    width: 40px;
    padding: 0 0 20px;
}

.tabWrapper>.ui.attached.menu .item {
    width: 10px;
    border-radius: 100px !important;
    font-size: 0;
    height: 10px;
    display: inline-flex;
    background: #e8e8e8 !important;
    margin: 0 5px;
}

.tabWrapper>.ui.attached.menu .item.active {
    background: #3681d0 !important;
}

.ui.grid>.row.pT-0 {
    padding-top: 0;
}

.validation-input {
    position: relative;
}

.validation-input>i {
    cursor: pointer;
    position: absolute;
    right: 13px;
    top: 31px;
}

.validation-input>i.valid {
    color: #07c704;
}

.validation-input>i.nonvalid {
    color: #ff0023;
}

.validation-input .ui.input input {
    padding-right: 25px !important;
}

.ui.page.dimmer+.ui.page.dimmer {
    background-color: rgba(0, 0, 0, 0.6);
    justify-content: center;
}

.ui.page.modals.dimmer.transition.visible.active {
    justify-content: center;
}

body .ui.basic.modal.login-modal,
body .modals.dimmer .ui.scrolling.modal.login-modal {
    display: inline-flex;
    max-width: 470px;
    width: 100%;
    position: static;
    margin: 0 auto !important;
    height: auto;
    align-self: center;
    justify-self: center;
}

.email-succes .svg {
    margin-top: 50px;
}

.email-succes .svg svg {
    width: 100%;
    max-width: 150px;
    margin: 0 auto;
    display: table;
    height: auto;
}

.email-succes-message {
    text-align: center;
    font-size: 20px;
    max-width: 520px;
    margin: 30px auto 0;
    color: #07ab07;
    padding: 0 30px;
}

.email-succes-message small {
    display: flex;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    margin-top: 10px;
}

.login-box {
    background-color: #1a1642;
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 40px 20px;
    max-width: 470px;
    margin: auto;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.login-box .ui.error.message {
    background-color: #ff0000;
    color: #ffffff;
    border: none !important;
    box-shadow: none !important;
    padding: 5px 10px;
    bottom: initial !important;
    margin-bottom: -15px;
    margin-top: 30px;
}

.login-box .ui.error.message .header {
    color: #ffffff;
}

.login-box .signinBtn.ui.primary.button.M0auto {
    margin: 0 auto !important;
    min-width: 110px;
}

.login-box .sinupInput.ui.input input {
    max-width: 253px;
    width: 100%;
    min-width: 253px;
}

.login-box a.color-white:hover {
    text-decoration: underline;
}

.login-box .forgot {
    max-width: 250px;
    width: 100%;
    text-align: right;
    margin: 0 auto;
    cursor: pointer;
}

.login-box>img {
    margin-bottom: 10px;
}

.login-box .linkedinBtn.mR-20 {
    margin-right: 20px !important;
}

.ui.button.linkedinBtn {
    background-color: #1f88be;
    color: #fff;
    text-shadow: none;
    border-radius: 25px;
    margin: 0;
    width: 210px;
}

.ui.button.linkedinBtn span.icon-linkedin-alt {
    font-size: 18px;
    line-height: 1px;
    transform: translateY(2px);
    display: inline-block;
}

.ui.button.linkedinBtn span:last-child {
    margin: 0 15px 0 18px;
    font-weight: 300;
    position: relative;
}

.ui.button.linkedinBtn span:last-child:after {
    content: '\E90A';
    font-family: 'mappes-icon' !important;
    position: absolute;
    right: -23px;
    font-size: 12px;
    top: 2px;
}

.ui.button.linkedinBtn:hover {
    background-color: #147baf;
}

.sinupInput.ui.input input {
    border-color: #999;
    border-radius: 25px;
    min-width: 203px;
}

.signinBtn.ui.primary.button {
    border-radius: 25px;
    margin-left: 25px;
}

.login-box .or {
    margin: 15px auto;
}

.login-box .linkedinBtn {
    margin: 0 auto !important;
}

body .visible.transition {
    display: flex !important;
    flex-direction: column;
}

.field.required label:after {
    content: '*';
    color: red;
    margin-left: 2px;
}

.ui.form.custom-input-form .field>label,
label,
.ui.form .field>label {
    color: #666;
}

.ui.dropdown:not(.button)>.default.text,
.ui.selection.dropdown>.text {
    min-height: 34px;
}

.ui.selection.dropdown {
    min-width: 100%;
}

.notification-page-list {
    padding: 15px !important;
}

.notification-page-list .item {
    display: flex !important;
    align-items: center;
    justify-content: flex-start;
}

.notification-page-list .item .content {
    margin-left: 10px;
    line-height: 1.3 !important;
}

.notification-page-list .item .notification-initials {
    width: 2em;
    height: 2em;
}

@media only screen and (min-width: 768px) {
    .extra-padding {
        padding: 15px !important;
    }

    .extra-padding-sides {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    .ui.grid.stackable>.column.main-content-area {
        margin-bottom: 50px;
    }

    .profile-info-text {
        margin-left: -90px;
        padding-left: 100px;
    }

    .left-content .no-auth {
        padding: 17px 0 1rem 21px;
    }

    .main-wrapper>.pushable>.pusher {
        display: flex;
        flex-direction: column;
        min-height: 100%;
        /* overflow: auto; */
    }
}

.ui.search.profile-company-search .empty-message-text {
    margin-bottom: 10px;
    color: #949799;
}

.ui.search.profile-company-search>.results .result:hover {
    background-color: #00adee;
}

.ui.search.profile-company-search>.results .result:hover>.ui.label {
    color: #fff;
}

.ui.search.profile-company-search>.results .result .ui.label {
    background-color: transparent;
    padding: 0;
    line-height: 1.5;
}

.ui.search>.results .result {
    overflow: unset;
    padding: 3px 5px;
    text-align: left;
}

.ui.search>.results .result>div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ui.search>.results .result>div:not(.ui.label) {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.slim-scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f54b;
}

.ui.animated.button .visible.content,
.ui.animated.button .hidden.content {
    padding: 0;
}

.slim-scroll::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f54b;
}

.slim-scroll::-webkit-scrollbar-thumb {
    background-color: #f5f5f54b;
    border-radius: 0;
}

.ui.selection.dropdown.selectprivacy-drop {
    border: 1px solid #D1D1D1;
    border-radius: 3px;
    font-size: 12px;
    height: 25px !important;
    min-height: 25px;
    line-height: 25px !important;
    padding-left: 6px;
    padding-right: 0;
    display: flex;
    align-items: center;
    max-width: 160px !important;
    min-width: 155px;
    color: #818181;
    background: #F8F8F8;
}

.ui.selection.dropdown.selectprivacy-drop .text span {
    display: flex;
    align-items: center;
}

.ui.selection.dropdown.selectprivacy-drop .text {
    min-height: 24px !important;
    line-height: 24px !important;
    color: #818181;
    font-weight: normal;
    padding-right: 20px;
}

.ui.selection.dropdown.selectprivacy-drop .text::after {
    font-size: 7px;
    font-weight: lighter;
    ;
}

.ui.selection.dropdown.selectprivacy-drop .icon {
    width: 12px;
    margin-right: 5px;
    fill: #818181;
}

.ui.selection.dropdown.selectprivacy-drop .menu {
    border: 0;
    box-shadow: 2px 2px 18px rgba(0, 0, 0, 0.15);
}

.ui.selection.dropdown.selectprivacy-drop .item {
    padding: 2px 10px !important;
    border: 0;
    font-size: 12px;
}

.ui.selection.dropdown.selectprivacy-drop .item .text {
    line-height: 19px !important;
    padding-right: 8px;
    display: flex;
    align-items: center;
}

.ui.selection.dropdown.selectprivacy-drop .item .icon {
    fill: #818181;
    width: 12px;
    margin-right: 6px;
}

.ui.selection.dropdown.selectprivacy-drop .item.active,
.ui.selection.dropdown.selectprivacy-drop .item.selected {
    font-weight: normal;
    color: #818181;
}

.search-companymodal {
    max-width: 230px;
    border-radius: 0;
}

.search-companymodal .content {
    border-radius: 0 !important;
}

.search-companymodal p {
    font-size: 10px;
    color: #818181;
}

.search-companymodal .description {
    min-height: 190px;
}

.feed-post-links {
    margin-right: 20px;
    color: #333;
    font-size: 12px;
}

.feed-post-links i {
    color: #333;
    margin-right: 8px;
}

.ui.dropdown.search-dropdown {
    border: 1px solid rgba(34, 36, 38, .15) !important;
    background: #fff !important;
    font-size: 13px !important;
    border-radius: .28571429rem;
    font-weight: normal;
    padding-left: 10px;
    height: 40px;
    line-height: 38px;
}

.ui.dropdown.search-dropdown input {
    text-align: left;
}

.ui.dropdown.search-dropdown .text .text-center {
    text-align: left !important;
}

.ui.dropdown.search-dropdown .text:after {
    display: none;
}

.ui.dropdown.search-dropdown .search {
    font-weight: lighter;
    height: 100%;
}

@media only screen and (min-width: 1200px) {
    .main-wrapper .ui.container {
        width: 100%;
        max-width: 1212px !important;
        height: inherit;
    }

    .profile-image {
        width: 135px;
        height: 135px;
        font-size: 80px;
    }

    .profile-info-text {
        margin-left: -135px;
        padding-left: 155px;
    }

    .ui.image.user-avtar-image {
        border-width: 5px;
    }

    .ui.form .two.fields>.field:first-child {
        padding-right: 20px;
    }

    .ui.form .two.fields>.field:last-child {
        padding-left: 20px;
    }
}

@media only screen and (max-width: 991px) {

    .ui.grid>.column.left-content,
    /* .topNav.ui.menu>.ui.container>.item.navbar-logo {
        max-width: 160px !important;
    } */

    .searchHolder {
        width: -webkit-calc(100% - 340px);
        width: calc(100% - 340px);
    }

    .ui.grid.stackable>.column.right-content {
        max-width: 180px;
    }

    .ui.grid.stackable>.column.main-content-area {
        padding: 0 180px 0 160px;
        margin-left: -160px;
        margin-right: -180px;
    }

    .ui.grid.stackable>.column.left-content>.column.left-content {
        max-width: 160px !important;
    }

    .ui.grid.stackable>.column.right-content>.column.right-content {
        max-width: 180px;
    }

    .field-brack-md.ui.form .fields {
        flex-wrap: wrap;
    }

    .field-brack-md.ui.form .two.fields>.field {
        width: 100%;
    }
}

@media only screen and (max-width: 767px) {

    /*.main-wrapper {
    height: 100vh;
    overflow: auto;
  }*/
    .ui .palm-nudge-sides {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .ui.modal.sign-in-reminder>.content {
        padding: 40px 15px !important;
        background-size: contain;
        background-position: center;
    }

    .topNav.ui.menu>.ui.container>.item.search-main {
        /* width: auto !important; */
    }

    .topNav.ui.menu>.ui.container>.item.searchHolder {
        width: calc(100% - 124px) !important;
    }

    .searchHolder>.ui.search .prompt {
        padding-left: 15px;
        padding-right: 20px !important;
    }

    .searchHolder>.ui.search .prompt~i.icon {
        width: 15px;
    }

    .searchHolder .searchWithScroll>div.results.transition.visible {
        width: 100vw !important;
        position: fixed;
        left: 0;
        right: 0;
        top: 54px;
    }

    .topNav.ui.menu>.ui.container>.item.navbar-logo {
        padding-left: 0 !important;
        display: flex;
        padding-right: 10px !important;
        width: 36px;
    }

    .topNav.ui.menu>.ui.container>.right.menu>.item.navbar-profile-pic {
        padding: 0 0px 0 10px !important;
    }

    .topNav.ui.menu>.ui.container>.right.menu>.item.navbar-profile-pic>img,
    .topNav.ui.menu>.ui.container>.right.menu>.item.navbar-profile-pic>a>img {
        width: 32px;
        height: 32px;
        border: 1px solid #fff;
    }

    .topNav.ui.menu>.ui.container>.Menu.navbar-profile-pic {
        padding-right: 0;
        padding-left: 0;
    }

    /* .navbar-logo__img {
        width: 124px;
    } */

    .navbar-logo__i {
        color: #fff;
        font-size: 26px;
    }

    .topNav.ui.menu {
        -ms-flex-direction: inherit;
        flex-direction: inherit;
        background: #1a1642;
        background: -webkit-linear-gradient(left, #08061c, #1a1642);
        background: -o-linear-gradient(right, #08061c, #1a1642);
        background: -moz-linear-gradient(right, #08061c, #1a1642);
        background: linear-gradient(to right, #08061c, #1a1642);
    }

    .topNav.ui.menu>.ui.container>.right.menu {
        margin-left: auto !important;
    }

    .topNav.ui.menu>.ui.container>.left.menu {
        margin-right: 0 !important;
    }

    .topNav.ui.menu>.ui.container>.right.menu>.item,
    .topNav.ui.menu>.ui.container>.left.menu>.item.profile-mobile-toggle {
        height: 100%;
    }

    .profile-mobile-drop-down {
        border-radius: 0;
        border: 0;
        border-bottom: 3px solid #e2e2e2;
        position: absolute;
        width: 100%;
        left: 0;
        background: #fff;
        padding: 0 !important;
        top: 60px;
        /*max-height: 258px;
    overflow: auto;*/
    }

    .profile-mobile-drop-down .left-sidebar .ui.list>.item>.content .hide-menu br {
        display: none;
    }

    .ui.mini.search {
        width: 100%;
    }

    .search-bar-mobile .ui.mini.search {
        max-width: 100%;
    }

    .ui.container>.ui.stackable.grid>.column.right-content {
        max-width: 100%;
        padding-right: 1rem !important;
        padding-left: 1rem !important;
        padding-top: 50px !important;
    }

    .ui.stackable.grid>.column.main-content-area {
        width: 100%;
        margin: 0 auto;
        padding: 20px 0 0 0 !important;
    }

    .stats-border,
    .main-content {
        border: none !important;
    }

    .mobile-menu-icon-tab .ui.pointing.secondary.menu a {
        font-size: 0;
        position: relative;
        line-height: 22px;
    }

    .mobile-menu-icon-tab .ui.pointing.secondary.menu a.active {
        color: #00adee;
        font-size: 0;
        line-height: 22px;
    }

    .mobile-menu-icon-tab .ui.pointing.secondary.menu a:after {
        display: inline-block;
    }

    .main-wrapper>.pushable>.pusher {
        padding-bottom: 40px;
    }

    .ui.grid.stackable>.column.right-content>.column.right-content {
        position: static;
        max-width: 100%;
    }

    .login-box .signup-alt-option .linkedinBtn.mR-20 {
        margin: 0 auto 20px !important;
    }

    .signup-alt-option {
        display: flex;
        flex-direction: column;
    }

    .footer {
        padding: 10px 15px 10px 15px;
        /* bottom: 40px;
    position: absolute; */
    }
}

@media only screen and (max-width: 480px) {

    body .ui.basic.modal.login-modal,
    body .modals.dimmer .ui.scrolling.modal.login-modal {
        max-width: 320px;
    }
}

.upload-company-control {
    width: 76px;
    height: 76px;
    border: dashed;
    border-radius: 100%;
    display: inline-block;
}

.upload-company-control label {
    position: relative;
    top: 4px;
    cursor: pointer;
    text-align: center;
}

.upload-company-control input[type='file'] {
    position: absolute;
    opacity: 0;
    width: 0px;
    left: 44px;
}

.notification-loadmore {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #fff;
    height: 50px;
    text-align: center;
}

.edit-btn {
    padding: 7px 20px !important;
}

.ui.basic.button.edit-btn:hover {
    background: #fff !important;
    box-shadow: 0 0 0 1px #00adee inset, 0 0 0 0 #00adee inset;
}

.ui.primary.button.follow-btn.company-follow-btn {
    padding: 7px 12px !important;
    line-height: 1 !important;
    margin-top: 8px !important;
}

#user-profile-image {
    position: relative;
}

#user-profile-image label {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: #2698fb95;
    color: #ffffff;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    transition: all ease-in 0.2;
    font-weight: 600;
    opacity: 0;
}

#user-profile-image:hover label {
    z-index: 2;
}

@media screen and (max-width:767px) {
    .create-event-button-right {
        display: none;
    }
}