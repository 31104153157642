@import '../../assets/scss/variables.scss';
 
.pricing-main{
    padding-top: 80px;
    .pricing-row{
        display: grid;
        width: 100%;
        grid-gap: 40px;
        grid-template: repeat(1, 1fr)/repeat(3, 1fr);
        @media screen and (max-width:767px){
            grid-template: repeat(1, 1fr)/repeat(1, 1fr);
        }
        .pricing-col{
            border:1px solid $primary-color;
            border-radius: 10px;
            padding: 30px;
            color: $secondary-color;
            background: $white;
            transition: all .2s ease-in-out;
            .pricing-image{
                text-align: center;
                height: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    max-height: 80px;
                }
            }
            h1{
                color: $primary-color;
                text-align: center;
                font-weight: 600;
                font-size: 2.5rem;
            }
            p{
                font-size: 1.3rem;
                text-align: center;
                font-weight: 600;
                padding-top: 10px;
            }
            ul{
                list-style-type: none;
                padding-left: 0;
                li{
                    display: flex;
                    justify-content: space-between;
                    font-weight: 700;
                    font-size: 14px;
                    padding: 6px 16px;
                    .icon{
                        width: 14px;
                    }
                }
            }
            .time-period{
                padding: 0 16px 30px;
                color: $secondary-color;
                font-style: italic;
                font-weight: 600;
                font-size: 14px;
                color: $primary-color;
            }
            .action{
                text-align: center;
                .button{
                    width: 100%;
                    background: $white;
                    border:2px solid $primary-color;
                    color: $primary-color;
                    font-weight: 600 !important;
                    font-size: 0.9rem;
                }
            }
            &:hover{
                transform: scale(1.1);
                .action{
                    text-align: center;
                    .button{
                        background: $primary-color;
                        border:2px solid $primary-color;
                        color: $white;
                    }
                }
            }
        }
    }
    .benefits-plan{
        border: 0;
        thead{
            tr{
                th{
                    font-size: 1.8rem;
                    color: $primary-color;
                    font-weight: 700;
                    background: $white;
                    text-align: center;
                    border-bottom: 0;
                    &:first-child{
                        text-align: left;
                        padding-left: 0;
                    }
                    small{
                        font-weight: normal;
                        display: block;
                        text-align: center;
                        font-style: italic;
                    }
                }
            }
        }
        tbody{
            tr{
                &:first-child{
                    th{
                        border-top: 0;
                    }
                    td{
                        border-top: 0;
                        border-top: 0;
                    }
                }
                th{
                    font-size: 1.8rem;
                    font-weight: 600;
                    color: $secondary-color;
                    border-top: 1px solid rgba(34,36,38,.1);
                    padding-top: 15px;
                    padding-bottom: 15px;
                }
                td{
                    padding: 10px 0;
                    text-align: center;
                    small{
                        font-size: 14px;
                        font-style: italic;
                    }
                    .icon{
                        fill: $white;
                        background: $primary-color;
                        width: 26px;
                        height: 26px;
                        border-radius: 26px;
                        padding: 6px;
                        &.circle{
                            padding: 0;
                            background: none;
                        }
                        &.noinplan{
                            background: $error-color;
                        }
                    }
                }
            }
        }
        &.mobile-view{
            tbody{
                tr{
                    display: flex !important;
                    align-items: center;
                    justify-content: space-between;
                    padding: 5px 0 !important;
                    box-shadow: none !important;
                    &:last-child{
                        border-bottom: 1px solid $border-color;
                    }
                    th{
                        color: $primary-color;
                        font-size: 19px;
                        padding: 2px 0 !important;
                    }
                    td{
                        padding: 0 !important;
                    }
                }
            }
        }
    }
    .planname-mobile{
        font-size: 20px;
        color: $secondary-color;
        font-weight: 600;  
        margin-top: 20px;
        margin-bottom: 10px;     
    }
    .bottom-shape{
        margin-top: -28em;
        @media screen and (min-width:768px) and (max-width:1080px){
            margin-top: -20em;
        }
        @media screen and (max-width:767px){
            margin-top: -8em;
        }
    }
    .accordion.faq-list{
        font-family: 'Arial';
        color: $secondary-color;
        .title{
            font-size: 2rem;
            font-weight: 600;
            display: flex;
            align-items: center;
            color: $secondary-color;
            @media screen and (max-width:991px){
                border-bottom: 1px solid $border-color;
                font-size: 1.6rem;
            }
            @media screen and (max-width:767px){
                border-bottom: 1px solid $border-color;
                font-size: 1.3rem;
            }
            .icon{
                transform: rotate(-90deg) !important;
                width: 15px !important;
                margin-right: 10px;
            }
            &.active{
                .icon{
                    transform: rotate(0deg) !important;
                } 
            }
        }
        .content{
            padding-left: 12px;
            p{
                font-size: 1.4rem;
            }
            ul{
                padding-left: 15px;
            }
        }
    }
}

.web-benefits-plan{
    @media screen and (max-width:767px){
        display: none;
    }
}
.mobile-benefits-plan{
    display: none;
    @media screen and (max-width:767px){
        display: block;
    }
}