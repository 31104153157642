@import '../../assets/scss/variables.scss';

.banner-section{
    min-height: calc(100vh - 60px);
    position: relative;
    @media screen and (max-width:991px){
        min-height: auto;
    }
    @media screen and (max-width:767px){
        display: flex;
        flex-direction: column-reverse;
    }
    .row{
        min-height: calc(100vh - 60px); 
        @media screen and (max-width:991px){
            min-height: calc(100vh - 300px); 
        }
        @media screen and (max-width:767px){
            min-height: auto;
        }
    }
    .button-section{
        @media screen and (max-width:767px){
            flex-direction: column-reverse;
            display: flex;
            margin-top: 10px !important;
        }
    }
    h1{
        font-size: 4.5rem;
        font-weight: bold;
        color: black;
        @media screen and (max-width:767px){
            font-size: 2.3rem;
            margin-top: -20px;
            line-height: 2.4rem;
        }
        @media screen and (max-width:374px){
            font-size: 2rem;
            margin-top: -20px;
            line-height: 2.1rem;
        }
    }
    .text-1{
        @media screen and (max-width:374px){
            font-size: 1em;
        }
    }
    .banner-img{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        z-index: -1;
        max-height: 90%;
        @media screen and (max-width:991px){
            width: 76%;
            top: -86%;
            max-height: inherit !important;
        }
        @media screen and (max-width:767px){
            position: inherit;
            width: 90%;
            margin-top: -60px;
            margin-right: 0;
        }
    }
    .button {
        @media screen and (max-width:767px){
            width: 100%;
            margin-bottom: 10px;
        }
        @media screen and (max-width:374px){
            height: 45px !important;
        }
    }
}
.homeproduct-section{
    position: relative;
    &::before{
        content: '';
        width: 100%;
        height: 70%;
        background: #F1F9FFA7;
        display: block;
        position: absolute;
        top: 0;
    }
    .homeproduct-section-wrapper{
        max-width: 95%;
        margin: auto;
        width: 1080px;
        .cards{
            @media screen and (max-width:991px){
                display: grid !important;
                grid-template-columns: auto auto auto;
                grid-gap: 8px;
                margin: 0;
            }
            @media screen and (max-width:640px){
                display: grid !important;
                grid-template-columns: auto auto;
                grid-gap: 4px;
            }
            .card{
                border: 0;
                box-shadow: none;
                margin-top: 0;
                @media screen and (max-width:991px){
                    width: 100% !important;
                    margin:0;
                    max-height: 250px;
                }
                @media screen and (max-width:767px){
                    max-height: 180px;
                }
                .image{
                    max-height: inherit;
                    border-radius: 9px !important;
                    overflow: hidden;
                    &:after{
                        content: '';
                        width: 100%;
                        height: 100%;
                        display: block;
                        background: linear-gradient(180deg, rgba(0,0,0,0.1), rgba(0,0,0,0.4));
                        position: absolute;
                        z-index: 1;
                        bottom: 0;
                        z-index: 0;
                    }
                }
                .content{
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    text-align: center;
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                    color: $white;
                    padding: 2em 2em;
                    border: 0;
                    z-index: 2;
                    bottom: 0;
                    .button{
                        margin-top: 30px;
                        padding: 0 25px;
                        @media screen and (max-width:767px){
                            padding: 0 15px;
                            margin-top: 10px;
                            font-size: 12px;
                            height: 33px;
                        }
                    }
                }
                .header{
                    font-size: 1.3rem;
                    margin-bottom: 15px;
                    font-weight: 600;
                    @media screen and (max-width:767px){
                        font-size: 1.1rem;
                    }
                }
            }
        }
    }
}
.productkey-section{
    .cards{
        .ui.card{
            text-align: center;
            border: 0;
            box-shadow: none;
            box-shadow: 2px 4px 11px #00000017;
            padding: 2.5rem 1.5rem !important;
            @media screen and (max-width:991px){
                margin-left: 0.5rem;
                margin-right: 0.5rem;
                padding: 2.5rem 1rem !important;
            }
            @media screen and (max-width:767px){
                margin:10px auto;
                max-width: 270px;
            }
            svg{
                width: 180px;
                margin: auto;
                @media screen and (max-width:991px){
                    width: 120px;
                }
            }
            img{
                width: 125px;
                margin: auto;
                background: none;
                @media screen and (max-width:991px){
                    width: 120px;
                }
            }
            .content{
                border: 0;
                min-height: inherit;
                padding-bottom: 0;
                .meta{
                    font-size: 1.8rem;
                    font-weight: bold;
                    color: $secondary-color;
                    @media screen and (max-width:991px){
                        font-size: 1.2rem;
                    }
                }
            }
        }
    }
}

.testimonial-section{
    .testimonial-wrapper{
        .slick-list{
            overflow: inherit;
            .slick-slide{
                &.slick-current{
                    transform: scale(1.1);
                }
            }
        }
        div{
            &:focus{
                outline: none;
            } 
        }
        .slick-dots{
            bottom: 25px;
            @media screen and (max-width:767px){
                bottom: -15px;
            }
            li{
                margin: 0;
                button{
                    &:before{
                        color: #2185D0;
                        opacity: 1;
                    }
                }
                &.slick-active{
                    button{
                        &:before{
                            color: $secondary-color;
                        }
                    } 
                }
            }
        }
        .card{
            padding: 0 60px;
            @media screen and (max-width:767px){
                padding:0 20px;
            }
            .card-inner{
                background: #CCE2FF;
                text-align: center;
                padding: 80px;
                @media screen and (max-width:767px){
                    padding: 40px;
                }
                p{
                    font-family: 'Georgia';
                    font-size: 1.6rem;
                    @media screen and (max-width:767px){
                        font-size: 1rem;
                    }
                }
                .person{
                    font-size: 1.3rem;
                }
                svg{
                    fill: #1A1642;
                    width: 20px;
                    margin-bottom: 20px;
                }
            }
        }
    }
}


.common-heading{
    padding-top: 30px;
    padding-bottom: 70px;
    @media screen and (max-width:991px){
        padding-bottom: 40px;
    }
    h2{
        font-size: 3.4rem;
        color: $text-color-primary;
        @media screen and (max-width:991px){
            font-size: 2.5rem;
            margin-bottom: 16px;
        }
    }h3{
        font-size: 3rem;
        color: $text-color-primary;
        @media screen and (max-width:991px){
            font-size: 2.5rem;
            margin-bottom: 16px;
        }
    }
    h4{
        font-size: 1.6rem;
        color: $primary-color;
        font-weight: bold;
        @media screen and (max-width:991px){
            font-size: 1.2rem;
        }
    }
    .blue-heading {
        font-size: 1.6rem;
        color: $primary-color;
        font-weight: bold;
        @media screen and (max-width:991px){
            font-size: 1.2rem;
        }
    }
    .h2-bold {
        font-size: 3.4rem;
        color: $text-color-primary;
        @media screen and (max-width:991px){
            font-size: 2.5rem;
            margin-bottom: 16px;
        }
    }
    .pricing-text{
        font-family: 'Georgia';
        font-style: italic;
        color:#7F7F7F;
        font-size: 1.4rem;
    }
}

.py-80{
    padding-top: 80px;
    padding-bottom: 80px;
}

.py-50{
    padding-top: 50px;
    padding-bottom: 50px;
}

.home-main{
    padding-top: 60px;
    @media only screen and(max-width:991px){
        padding-top: 60px;
    }
}

.invitation-action{
    width: 100%;
    max-width: 490px;
    @media screen and (max-width:767px) {
        margin-bottom: 20px;
        margin-top: 20px;
    }
    input{
        height: 55px;
        font-size: 15px;
        font-weight: 500;
        @media screen and (max-width:767px) {
            height: 45px;
        }
    }
    .ui.button{
        height: 55px !important;
        line-height: 55px !important;
        font-size: 15px;
        font-weight: 600 !important;
        padding: 0 20px;
        @media screen and (max-width:767px) {
            max-width: 120px;
            padding: 0 10px;
            font-size: 12px;
            height: 45px !important;
            line-height: 45px !important;
        }
    }
}

.snapshot-slider{
    border: 2px solid #999;
    border-radius: 8px;
    .slick-dots{
        bottom: 6px;
        li{
            margin: 0;
            width: 16px;
            button{
                width: 16px;
                padding: 0;
                height: 24px;
                line-height: 24px;
                &::before{
                    font-size: 9px;
                    opacity: 0.5;
                }
            }
            &.slick-active{
                button{
                    &::before{
                        color: $primary-color;
                        opacity: 1;
                    }
                }
            }
        }
    }
    .slick-slider{
        margin: 0;
    }
    .snapshot-slider-head{
        background: #e3e3e3;
        border-radius: 8px 8px 0 0;
        padding: 5px;
        height: 25px;
        border-bottom: 2px solid #999;
        display: flex;
        align-items: center;
        .icon{
            width: 8px;
            height: 30px;
            transform: rotate(90deg);
            fill: $white;
            stroke-width: 1px;
            stroke: #999;
            margin-left: 15px;
            opacity: 1;
        }
    }
}