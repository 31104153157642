@import '../../../../assets/scss//variables.scss';

.mainheader {
    background: $secondary-color !important;
    color: $white;
    margin: 0;
    position: fixed;
    width: 100%;
    border-radius: 0;
    z-index: 101;
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    align-items: center;
    top: 0;

    a {
        color: $white;
    }

    .left-menu {
        font-family: 'Arial';

        // width: 33.33%;
        .leftmenu-inner {
            .menu-icon {
                cursor: pointer;
                display: block;
                height: 40px;
                line-height: 40px;

                svg {
                    fill: $white;
                }
            }

            .menu-list {
                display: none;
                position: absolute;
                background: $white;
                width: 220px;
                padding: 10px 20px;
                box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);

                .title {
                    font-weight: bold;
                    color: $secondary-color;
                    font-size: 1.4rem;

                    .icon {
                        width: 11px;
                        float: right;
                        margin-top: 4px;
                    }

                    &.active {
                        color: $primary-color;

                        .icon {
                            transform: rotate(-90deg);
                        }
                    }
                }

                .content {
                    padding: 6px 20px !important;

                    .title {
                        font-weight: normal;
                        color: $secondary-color;
                        font-size: 1.2rem;
                    }
                }
            }

            &:hover,
            &:focus {
                .menu-list {
                    display: block;
                }
            }
        }
    }

    .right-action {
        // width: 33.33%;
        text-align: right;
        display: flex;

        .btn {
            font-size: 11px;
            text-transform: uppercase;
            font-weight: normal;
            line-height: 40px;
            height: 40px;
            text-align: center;
            padding: 0 20px;
            display: inline-block;
            border-radius: 4px;
        }

        .btn-signin {
            border: 1px solid $white;
            white-space: nowrap;

            @media screen and (max-width:767px) {
                padding: 0 12px;
                height: 30px;
                line-height: 30px;
                font-size: 12px;
            }
        }

        .btn-joinnow {
            background: $primary-color;

            &:hover {
                color: $white;
                background: rgba($primary-color, 0.8);
            }

            @media screen and (max-width:767px) {
                display: none;
            }
        }
    }

    .center-logo {
        // width: 33.33%;
        text-align: center;
        margin-right: 40px;

        @media screen and (max-width:767px) {
            margin: auto;
        }

        .icon {
            width: 190px;
            fill: $white;
            cursor: pointer;

            @media screen and (max-width:576px) {
                max-width: 120px;
            }
        }

        .small-device {
            display: none;

            @media screen and (max-width:767px) {
                display: block;
            }

            @media screen and (max-width:576px) {
                max-width: 140px;
            }
        }

        .lg-device {
            @media screen and (max-width:767px) {
                display: none;
            }
        }
    }

    .prelogged-centerheader {
        display: flex;
        align-items: center;
        width: 70%;

        @media screen and (max-width:767px) {
            justify-content: center;
        }

        .center-logo {
            a {
                display: flex;
                align-items: center;
            }

            img {
                max-width: 178px;
                width: 100%;
            }
        }

        .open-search {
            width: 100%;

            i {
                color: #707070;
            }

            input {
                border-radius: 8px;

                &::placeholder {
                    font-weight: 600;
                }

                &::-webkit-placeholder {
                    font-weight: 600;
                }

                &::-moz-placeholder {
                    font-weight: 600;
                }
            }
        }

        .landingSearchBarWrapper {
            width: 100%;

            @media screen and (max-width:767px) {
                display: none;
            }

            .ui.search .prompt {
                border: 2px solid #2699FB !important;
                border-radius: 8px;
            }

            .ui.selection.dropdown.selection {
                background: #2699FB !important;
                border-radius: 8px 0 0 8px;
                height: calc(100% - 2px);
                border: 0;

                &>.text {
                    color: $white;
                    padding-left: 14px;
                    font-weight: normal;
                }
            }

            .search.icon {
                background: #2699FB;
                opacity: 1;
                border-radius: 0 8px 8px 0;
            }
        }
    }
}

.what-would-popup {
    position: fixed;
    right: 30px;
    bottom: 4%;
    z-index: 2;
    max-width: 118px;
    background: $primary-color;
    color: $white;
    box-shadow: 0 4px 16px rgba($color: #000000, $alpha: 0.2);
    text-align: center;
    font-weight: 600;
    padding: 4px 8px;
    line-height: 1.4;
    cursor: pointer;
    border-radius: 3px;

    @media only screen and (max-width:1024px) {
        bottom: 60px;
    }

    &:after {
        content: '';
        position: absolute;
        right: 5%;
        top: 100%;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 10px solid $primary-color;
        clear: both;
    }

    &:hover {
        background: #2089E3;
    }

    @media only screen and (max-width:991px) {
        font-size: 0;

        i {
            font-size: 24px;
        }
    }
}

.popupupload-card {
    border: 1px dashed #E2E2E2;
    background: #FCFCFC;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 80px;

    .icon-cloud-uplod {
        margin-right: 8px !important;
    }

    input {
        height: 100% !important;
    }
}

.user-profile-drop {

    &>[aria-live="polite"] {
        display: none !important;
    }

    #navbar-menu {
        fill: $white;
        width: 20px;
        height: 20px;
    }

        border: 1px solid #D9D9D9;
        width: 280px;
        background-color: #fff;
        border-radius: 6px;
        box-shadow: -1px 4px 8px 0px #00000014;
        right: -15px !important;
        left: auto !important;
        top: 58px !important;
        margin-top: -0.6rem !important;

        &::before {
            width: 0.5em;
            height: 0.5em;
            content: "";
            background: $white;
            display: inline-block;
            position: absolute;
            top: -5px;
            right: 20px;
            transform: rotate(45deg);
        }

        .list {
            .item {
                display: flex !important;
                align-items: center !important;
                border-bottom: 1px solid #D9D9D9;
                padding: 12px !important;
                font-family: $inter-font;
                cursor: pointer;
                transition: all ease-in 0.1s;

                &:hover {
                    opacity: 0.8;
                }

                &:first-child {
                    border-bottom: 0;
                    padding-bottom: 6px !important;

                    .right-dtl {
                        p {
                            color: #2166EE;
                        }
                    }
                }

                &:last-child {
                    border-bottom: 0;
                }

                .left-icon {
                    width: 32px;
                    margin-right: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .icon {
                        width: 24px;
                        fill: #334E68;
                    }
                }

                .right-dtl {
                    b {
                        font-size: 14px;
                        font-weight: 600;
                        color: #243B53;
                    }

                    p {
                        font-size: 12px;
                        font-weight: 500;
                        color: #486581;
                    }
                }
            }
        }
}

.right-menu {
    .item {
        padding: 0.4em 0.8em !important;
    }
}

input.mobile-search {
    height: 38px;
    width: 100%;
    border-radius: 8px !important;
    padding: 0.5rem 0.8rem !important;
    font-family: $inter-font !important;
    font-size: 14px;
    border: 0;
    outline: none;
    box-shadow: none;
    background-image: url('data:image/svg+xml,<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M2 6.33333C2 3.9401 3.9401 2 6.33333 2C8.72657 2 10.6667 3.9401 10.6667 6.33333C10.6667 7.49279 10.2113 8.5459 9.46964 9.32356C9.44316 9.3451 9.41753 9.36821 9.39287 9.39287C9.36821 9.41753 9.3451 9.44316 9.32356 9.46964C8.5459 10.2113 7.49279 10.6667 6.33333 10.6667C3.9401 10.6667 2 8.72657 2 6.33333ZM10.0487 11.4629C9.00508 12.2202 7.72135 12.6667 6.33333 12.6667C2.83553 12.6667 0 9.83113 0 6.33333C0 2.83553 2.83553 0 6.33333 0C9.83113 0 12.6667 2.83553 12.6667 6.33333C12.6667 7.72135 12.2202 9.00508 11.4629 10.0487L13.7071 12.2929C14.0976 12.6834 14.0976 13.3166 13.7071 13.7071C13.3166 14.0976 12.6834 14.0976 12.2929 13.7071L10.0487 11.4629Z" fill="%23829AB1"/></svg>');
    background-repeat: no-repeat;
    background-position: 97% center;

    &.expaned-search {
        @media screen and (max-width:767px) {
            border: 1px solid #2166EE;
            padding-left: 32px !important;
        }
    }

    @media screen and (max-width:480px) {
        background-position: 95% center;
    }

    .search.icon {
        &::before {
            color: #829AB1;
        }
    }
}

.navbar-links {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        background: $white;
        height: 3px;
        width: 40px;
        opacity: 0;
        border-radius: 5px;
    }

    .icon-row {
        height: 20px;
    }

    .icon {
        fill: #CCCBD5;
        width: 20px;
        height: 20px;
    }

    label {
        color: #CCCBD5;
        font-size: 11px;
        margin-top: 4px;
        margin-bottom: 0;
        font-family: $inter-font;
        font-weight: 500;
    }

    &.active {
        .icon {
            fill: $white;
        }

        label {
            color: $white;
        }

        &::after {
            opacity: 1;
        }
    }
}

.navbar-notification {
    position: relative;
    .notify .heartbit {
        top: -10px;
        right: 5px;
    }
    .point {
        right: 14px;
        top: 0px;
    }
    &::after {
        bottom: -12px;
    }
}

.profile-avatar-top {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;

    img {
        width: 22px !important;
        height: 22px !important;
        margin-bottom: 0.3rem;
    }

    label {
        color: #CCCBD5;
        font-size: 11px;
        margin-top: 0;
        margin-bottom: 0;
        font-family: $inter-font;
    }
}


.item.raq-btn {
    padding-left: 15px !important;
    padding-right: 5px !important;

    button.button {
        height: 40px;
        font-size: 14px !important;
        font-weight: 600;
        font-family: $inter-font;
        padding: 0.5rem 1.2rem;

        &:hover {
            background: rgba($primary-color, 10) !important;
        }
    }
}

.search-main {
    position: relative;
    width: 100%;
    max-width: 491px;

    @media screen and (max-width:767px) {
        width: 78% !important;
        height: 100%;
    }

    &.expaned-search {
        @media screen and (max-width:767px) {
            position: fixed !important;
            top: 0;
            width: 100vw !important;
            max-width: 100vw !important;
            height: auto !important;
            background: #F6F8FC !important;
            left: 0;
            right: 0;
            z-index: 9;
        }
    }

    .back-arrow {
        background: $white;
        border: 0;
        outline: none;
        height: 35px;
        position: absolute;
        left: 9px;
        border-radius: 8px;
        display: none;
        cursor: pointer;
        width: 30px;
        padding: 0 !important;

        .icon {
            fill: #19163F;
            width: 13px;
            margin-top: 5px;
        }
    }

    &.expaned-search {
        .back-arrow {
            display: block;
        }
    }

    .search-drop-mappes {
        background: $white;
        border-radius: 6px;
        border: 1px solid #DBE2EB;
        position: absolute;
        top: 52px;
        width: calc(100% - 1rem);
        left: 0.5rem;
        font-family: $inter-font;

        .search-drop-mappes-inner {
            padding: 15px;
        }

        &.expaned-search {
            @media screen and (max-width:767px) {
                width: 100% !important;
                left: 0;
                right: 0;
                border-radius: 0;
                background: #F6F8FC !important;
                height: calc(100vh - 55px);
            }
        }

        label {
            font-size: 12px;
            font-weight: 500;
            color: #829AB1;
            display: block;
            margin-bottom: 10px;
        }

        .btn-row {
            display: flex;
            align-items: center;
            margin-bottom: 1.4rem;
            grid-gap: 0.6rem;

            button {
                border: 1px solid #DBE2EB;
                border-radius: 15px;
                height: 32px;
                background: $white;
                color: #486581;
                font-weight: 600;
                padding: 0 13px;
                font-family: $inter-font;
                font-size: 14px;
                cursor: pointer;

                &.active {
                    border-color: $primary-color;
                    color: $primary-color;
                }
            }
        }

        ul {
            list-style-type: none;
            padding-left: 0;
            margin: 0;
            max-height: 450px;
            overflow-y: auto;

            &::-webkit-scrollbar {
                width: 5px;
                border-radius: 5px;
                height: 5px;
            }

            &::-webkit-scrollbar-track {
                background: #f1f1f1;
            }

            &::-webkit-scrollbar-thumb {
                background: #888;
                border-radius: 5px;
            }

            @media screen and (max-width:767px) {
                background: $white;
                border-radius: 14px;
                padding: 10px 15px;
                border: 1px solid #DBE2EB;
                max-height: calc(100vh - 220px);
                overflow-y: auto;
            }

            a {
                display: flex;
                align-items: center;
                font-family: $inter-font;
                line-height: 1;
                padding: 0.55rem 0;

                .searchicon {
                    margin-right: 12px;

                    .icon {
                        fill: #829AB1;
                        width: 14px;
                    }
                }

                p {
                    margin-bottom: 0;
                    font-size: 14px;
                    color: #334E68;
                    font-weight: 500;
                }

                small {
                    font-size: 12px;
                    color: #829AB1;
                    font-weight: 500;
                }

                &:hover {
                    p {
                        color: #1a1642;
                    }

                    small {
                        color: #334E68;
                    }
                }
            }

            li:not(:last-child) {
                border-bottom: 1px solid #ccc;
                margin-bottom: 2px;
                margin-top: 2px;
            }
        }
    }
}