// -------- Colors -----------
// @primary-color: @blue-6;
// @info-color: @blue-6;
// @success-color: @green-6;
// @processing-color: @blue-6;
// @error-color: @red-6;
// @highlight-color: @red-6;
// @warning-color: @gold-6;
// @normal-color: #d9d9d9;
$white: #fff;
$black: #000;


$primary-color: #2166EE; // primary color for all components
$primary-color-hover: #5A8FF8;
$blue-dark: #1665A8;
$secondary-color: #1A1642;
$success-color: #03c281; // success state color
$warning-color: #faad14; // warning state color
$error-color: #ff0050; // error state color

$text-red: #d90a00; // Red text color
$text-white: $white; // Red text color
$link-color: $primary-color; // link color
$link-color-blue: #0078CC;
$color-purple: #20014F;
$border-color:#EBEBEB;

$body-background: $white;

$font-family: 'Asap', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji',
'Segoe UI Emoji', 'Segoe UI Symbol';
$inter-font: 'Inter', sans-serif;
$font-size-base: 14px;
$font-size-xlg: $font-size-base + 4px; //18px
$font-size-lg: $font-size-base + 2px; //16px
$font-size-sm: $font-size-base - 2px; //12px

$heading-color: rgba(0, 0, 0, 0.85); // heading text color
$text-color: rgba(0, 0, 0, 0.65); // major text color
$text-color-primary: #1A1642; // major text color
$text-color-secondary: #333; // secondary text color
$text-gray:#666;
$disabled-color: rgba(0, 0, 0, 0.25); // disable state color
$border-radius-base: 4px; // major border radius
$border-color-base: #d9d9d9; // major border color
$box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15); // major shadow for layers


//Menu
$menu-bg : $black;
$menu-submenu-bg:$black;
$menu-arrow-color:$white;
$menu-color:$white;
$menu-item-color:$menu-color;

//Footer
$footer-bg: $white;
$footer-text-color: $text-gray;

//Button

$btn-border-radius-base: 4px;
$btn-border-radius-sm: $border-radius-base;
$btn-border-color-light:#ddd;
//Tabs
$tabs-green: #04B478;
