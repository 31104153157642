@import '../../../../assets/scss/variables.scss';

// .leftbar-following-list{
//     max-height: calc(100vh - 342px);
//     overflow-y:auto;
// }

.latest-news {
    padding-top: 10px;
    overflow: hidden;

    * {
        font-family: $inter-font;
    }

    .title {
        font-size: 14px;
        color: #243B53;
        font-weight: 600;
    }

    .leftuserbox {
        ul {
            list-style-type: none;
            padding-left: 0;
            margin: 0;

            li {
                padding-bottom: 15px;

                h4 {
                    font-size: 14px !important;
                    font-weight: 600;
                    color: #1A1642;
                    margin-bottom: 4px;

                    br {
                        display: none;
                    }
                }

                p {
                    color: #707070;
                    font-size: 14px !important;

                    .tag {
                        background: #F3F7FF;
                        color: #5A8FF8;
                        font-weight: 600;
                        font-size: 12px;
                        padding: 3px 5px;
                        border-radius: 6px;
                        display: inline-block;
                    }

                    .time-span {
                        color: #829AB1;
                        position: relative;
                      /*   padding-left: 12px; */
                        font-size: 12px;

                        /* &::before {
                            width: 5px;
                            height: 5px;
                            border-radius: 5px;
                            background: #829AB1;
                            display: inline-block;
                            position: absolute;
                            content: ' ';
                            left: 0;
                            top: 5px;
                        } */
                    }
                }
            }
        }

        .showmore-btn {
            padding: 5px 0;
            background: none;
            color: #2166EE;
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: 600;
            margin-top: 0.5rem;
            font-family: $inter-font;

            &:hover {
                opacity: 0.8;
            }

            .icon {
                fill: #2166EE;
                opacity: 1;
                width: 11px;
                margin-left: 6px !important;
            }
        }
    }
}

.group-card {
    &.connect-left-card {
        margin-bottom: 0 !important;
        border-radius: 6px 6px 0 0;
        border-bottom: 0;
        padding: 16px 12px;

        .header.header2 {
            margin-bottom: 0 !important;
            color: #486581 !important;
            font-family: $inter-font !important;
            font-weight: 600 !important;
            display: flex;
            justify-content: space-between;
            background: none;

            .icon {
                fill: #829AB1;
                width: 12px;
            }
        }

        .ui.items .item {
            background: none !important;

            .content {
                .my-product-list {
                    margin-top: 10px;

                    .item a {
                        padding: 5px 0 5px 15px;
                        font-size: 12px;
                        color: #486581;
                        font-weight: 500;
                        font-family: $inter-font !important;

                        &:hover {
                            background: none;
                            color: #2166EE;
                        }

                        span {
                            font-size: 13px;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }

    &.follow-product-card {
        border-radius: 0 0 6px 6px;
        padding: 16px 12px;

        .header.header2 {
            margin-bottom: 0 !important;
            color: #486581 !important;
            font-family: $inter-font !important;
            font-weight: 600 !important;
            display: flex;
            justify-content: space-between;
            background: none;

            .icon {
                fill: #829AB1;
                width: 12px;
            }
        }

        .ui.items .item {
            background: none !important;

            .content {
                .my-product-list {
                    margin-top: 10px;

                    .item a {
                        padding: 8px 0 8px 15px;
                        font-size: 12px;
                        color: #486581;
                        font-weight: 500;
                        font-family: $inter-font !important;

                        &:hover {
                            background: none;
                            color: #2166EE;
                        }
                    }
                }
            }
        }
    }
}

.leftuserbox {
    &.user-info-card {
        padding: 0;
        border: 1px solid #DBE2EB;
        border-radius: 6px;

        .completeprofile-title {
            font-size: 14px;
            font-weight: 700;
            color: #243B53;
            font-family: $inter-font;
            padding: 15px 0 0 15px;
            margin: 0;
        }

        .card-inner {
            .image {
                margin-top: 15px;

                #user-profile-image {
                    text-align: center;

                    img {
                        width: 74px;
                        height: 74px;
                        border-radius: 74px;
                        object-fit: cover;
                    }

                    .user-initials {
                        background: #E9EEF3;
                        width: 74px;
                        height: 74px;
                        border-radius: 74px;
                        margin: auto;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .user-initials-inner {
                            min-width: 32px;
                            width: 32px;
                            height: 32px;
                            border-radius: 32px;
                            background: $white;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }

                        .icon {
                            width: 15px;
                            height: 15px;
                            fill: #829AB1;
                        }
                    }
                }
            }

            .header {
                margin-top: 15px;

                .names {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    a {
                        color: #243B53;
                        font-size: 18px;
                        font-weight: 700;
                        font-family: $inter-font;
                    }

                    .icon {
                        fill: #2166EE;
                        width: 14px;
                        margin-left: 10px;
                    }
                }

                .meta {
                    text-align: center;

                    p {
                        a {
                            font-size: 12px;
                            color: #486581;
                            margin-bottom: 0;
                            font-family: $inter-font;
                        }
                    }
                }
            }

            .extra {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px 15px;
                border-top: 1px solid #DBE2EB;
                border-bottom: 1px solid #DBE2EB;
                margin-top: 15px;
                font-family: $inter-font;

                a {
                    font-size: 12px;
                    font-weight: 600;
                    color: #486581;
                }

                span {
                    b {
                        font-size: 13px;
                        font-weight: 600;
                        color: #2166EE;
                    }
                }
            }

            .profile-progress {
                display: flex;
                align-items: center;
                padding: 12px 15px 15px 15px;

                .CircularProgressbar-text {
                    font-weight: 600;
                    font-size: 24x;
                }

                .progreesbar {
                    min-width: 46px;
                    max-width: 46px;
                }

                .right-content {
                    margin-left: 10px;
                    color: #486581;
                    font-size: 12px;
                    font-family: $inter-font;
                    font-weight: 500;

                    a {
                        color: #2166EE;
                        font-weight: 600;
                    }
                }
            }
        }
    }
}

.leftbar-inner {
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
        background: #888;
    }
}

.right-content {
    &.slim-scroll {
        &::-webkit-scrollbar {
            width: 0;
        }
    
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }
    
        &::-webkit-scrollbar-thumb {
            background: #888;
        }
    }
}

.ui.inverted.popup {
    .content {
        font-family: $inter-font;
    }
}