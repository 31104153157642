@import '../../../assets/scss/variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&display=swap');

.article-details {
  padding-top: 4.5rem;
  *{
    font-family: 'Inter', sans-serif !important;
  }
  .article-title {
    margin-top: 15px;
    h2 {
      color: #243B53;
      font-weight: 700;
    }
  }
  .article-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1.8rem;
    margin-bottom: 1.8rem;
    .left-col {
      display: flex;
      align-items: center;
      .user-image {
        width: 52px;
        height: 52px;
        border-radius: 52px;
        background: #E9EEF3;
        margin-right: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        font-weight: 600;
        color: #486581;
      }
      h4 {
        color: #243B53;
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 3px;
      }
      p{
        font-size: 14px;
        color: #829AB1;
        font-weight: 400;
        a.link-color {
            color: #829AB1 !important;
          }
      }
    }
    .right-col {
      .btn-following {
        color: #486581;
        font-family: 'Inter', sans-serif;
        .icon {
          fill: #486581;
        }
      }
    }
  }
  .article-information {
    font-size: 18px;
    display: flex;
    word-wrap: break-word;
    width: 100%;
    flex-direction: column;
    .article-image {
      width: 100%;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    .quote-pre {
      font-style: italic;
      display: flex;
      align-items: flex-start;
      font-weight: 700;
      .icon {
        fill: #DBE2EB;
        width: 40px;
        margin-right: 10px;
        margin-top: 7px;
      }
    }
    .art-body-text {
      color: #486581;
      font-weight: 500;
      line-height: 1.7;
    }
    p {
      color: #486581;
      font-weight: 500;
      line-height: 1.7;
      i {
        font-weight: 700;
      }
      a.link-color{
        color: #829AB1 !important;
      }
    }
    ol, ul {
      color: #486581;
      padding-left: 15px;
      li {
        padding: 5px 0;
      }
    }
    .comments-box {
      padding-top: 1.5rem;
      .comment-initials-img {
        background: #2166EE;
      }
      .reply {
        .button.primary {
          font-family: 'Inter', sans-serif;
          font-weight: 600;
          border-radius: 6px !important;
          padding: 10px 20px !important;
        }
      }
    }
    .bottom-actions {
      margin-top: 3rem;
      display: flex;
      align-items: center;
      padding-top: 2rem;
      border-top: 1px solid #DBE2EB;
      .event-footer {
        display: flex;
        align-items: center;
        .event-footer-left {
          display: flex;
          align-items: center;
          a {
            &.active {
              color: #243B53 !important;
              .icon {
                fill: #243B53;
              }
            }
            &:nth-child(1) {
              min-width: 100px;
            }
          }
        }
        a {
          background: none;
          padding: 0;
          color: #486581;
          min-width: 120px;
          font-weight: 600;
          display: flex;
          align-items: center;
          font-family: 'Inter', sans-serif;
          font-size: 16px;
          line-height: 1;
          &.active {
            color: #243B53 !important;
            .icon {
              fill: #243B53;
            }
          }
          .icon {
            fill: #334E68;
            width: 20px;
            height: 16px;
            margin-right: 4px;
          }
        }
      }
    }
  }
  .more-articles {
    padding-top: 3rem;
    border-top: 1px solid #DBE2EB;
    margin-top: 6rem;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: #243B53;
    }
    ul {
      list-style-type: none;
      padding-left: 0;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 1.2rem;
      margin-top: 2rem;
      @media screen and (max-width:767px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media screen and (max-width:480px) {
        grid-template-columns: repeat(1, 1fr);
      }
      li {
        .card {
          border-radius: 15px;
          overflow: hidden;
          border: 1px solid #E5E8F0;
          box-shadow: none;
          width: 100%;
          .image {
            border-radius: 15px;
            overflow: hidden;
          }
          .content {
            .header {
              color: #0D1A35;
              font-weight: 700;
              padding: 12px 0 16px 0;
              font-size: 18px;
              font-family: 'Inter', sans-serif;
            }
            .description {
              color: #7C8CAB;
              letter-spacing: 2px;
              text-transform: uppercase;
              font-size: 12px;
              font-weight: 600;
              font-family: 'Inter', sans-serif;
            }
            .meta {
              font-size: 12px;
              color: #7C8CAB;
              font-weight: 500;
              font-family: 'Inter', sans-serif;
              .time {
                position: relative;
                padding-left: 10px;
                margin-left: 8px;
                &::before {
                  width: 3px;
                  height: 3px;
                  border-radius: 3px;
                  background: #7C8CAB;
                  content: ' ';
                  position: absolute;
                  left: 0;
                  top: 50%;
                  transform: translateY(-50%);
                }
              }
            }
          }
        }
      }
    }
  }
}