@import '../../assets/scss/variables.scss';

.productpage-wip{
    font-weight: 500;
    color: #486581;
    *{
        font-family: $inter-font !important;
    }
    p, h1, h2, h3, h4, h5, h6{
        font-family: $inter-font !important;
    }
    .invite-card-body{
        background: none;
        display: flex;
        flex-direction: column;
        position: relative;
    }
    .text-secondary {
        color: $secondary-color !important;
    }
    .back-link {
        background-color: rgba(241, 241, 242, 0.5);
        border: solid 1px #ccc;
        margin-bottom: 0;
        padding-bottom: 10px;
        border-bottom: 0;
    }
    .invite-card-body {
        border-top: 0;
    }
    .product-upper{
        background: $white;
        border: 1px solid #B7B7B771;
        border-radius: 14px 14px 0 0;
        .company-coverpic{
            border: 5px 5px 0 0;
            overflow: hidden;
            width: 100%;
            height: 140px;
            img{
                width: 100%;
                border-radius: 14px 14px 0 0;
            }
        }
        .product-upper-inner{
            padding: 20px;
            display: flex;
            .productupper-left{
                width: 160px;
                min-width: 160px;
                margin-right: 20px;
                .productimage-main{
                    width: 100%;
                    height: 160px;
                    border-radius: 9px;
                    overflow: hidden;
                    background: #ccc;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    z-index: 2;
                    &.companyImage{
                        margin-top: -68px;
                        position: relative;
                        .nocompany-image{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-direction: column;
                            background: #E9EEF3;
                            width: 100%;
                            height: 100%;
                            img{
                                width: 70px;
                            }
                            p{
                                color: #2699FB;
                                margin-bottom: 0;
                                font-weight: 600;
                                font-size: 15px;
                            }
                        }
                        .uploadphoto-button{
                            position: absolute;
                            z-index: 2;
                            width: 100%;
                            height: 100%;
                            background: rgba(25, 22, 65, 0.4);
                            backdrop-filter: blur(4px);
                            border-radius: 9px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            transition: all ease-in 0.15s;
                            top: -100%;
                            .button{
                                width: 44px;
                                height: 44px;
                                border-radius: 44px;
                                background: $white;
                                padding: 0;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border: 0;
                                box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
                                &:hover, &:focus{
                                    background: $white !important;
                                    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
                                }
                                .icon{
                                    width: 28px;
                                    height: 17px;
                                    margin: auto;
                                }
                            }
                        }
                        &:hover{
                            .uploadphoto-button{
                                top: 0;
                            }
                        }
                    }
                    img{
                        width: 100%;
                        cursor: pointer;
                    }
                }
                .productimage-thumbs{
                    margin-top: 8px;
                    ul{
                        display: flex;
                        grid-gap: 6px;
                        list-style-type: none;
                        padding-left: 0;
                        margin-top: 5px;
                        margin-bottom: 5px;
                        width: 100%;
                        min-width: 160px;
                        position: relative;
                        li{
                            border-radius: 3px;
                            background: #E9EEF3;
                            max-height: 38px;
                            max-width: 38px;
                            min-width: 38px;
                            min-height: 38px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: #486581;
                            position: absolute;
                            &:first-child {
                                margin-left: 0;
                                position: inherit;
                            }
                            &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6){
                                margin-left: -21px;
                                position: inherit;
                            }
                            &:nth-child(even) {
                                background: #C4CDD6;
                            }
                            .icon{
                                fill: #486581;
                                width: 12px;
                            }
                            img{
                                width: 100%;
                            }
                            &#product-image-upload {
                                right: 0;
                            }
                        }
                    }
                }
                .viewll-media-link{
                    font-size: $font-size-base - 2;
                }
            }
            .productupper-right{
                display: flex;
                flex-direction: column;
                .actionbtns{
                    text-align: left;
                    margin-top: 2px;
                    margin-bottom: 18px;
                    button.ui {
                        margin-left: 0;
                        margin-right: 10px;
                        height: 36px;
                        font-weight: 600;
                        padding: 6px 14px !important;
                        font-size: 14px;
                        border-radius: 6px !important;
                        .icon {
                            margin-right: 6px !important;
                        }
                    }
                }
                .namerow{
                    display: flex;
                    justify-content: space-between;
                    padding-top: 6px;
                    h2{
                        color: #102A43;
                        font-size: 22px;
                    }
                    .menu-icon{
                        margin-right: 13px;
                        margin-top: 4px;
                        .icon{
                            fill: $secondary-color;
                            width: 4px;
                        }
                    }
                    .connection-subhead {
                        padding-left: 0;
                        margin-bottom: 10px;
                        li {
                            font-size: $font-size-base;
                            border-right: 1px solid $border-color-base;
                            font-weight: 500;
                            color: #486581;
                            a, span{
                                color: #486581;
                                font-size: $font-size-base;
                            }
                            &:last-child {
                                border-right: 0;
                            }
                        }
                    }
                    .invitemore-btn{
                        display: flex;
                        align-items: center;
                        .icon{
                            margin-right: 2px;
                            width: 15px;
                            height: 13px;
                        }
                    }
                }
                .product-topright-action{
                    .toogle-icon{
                        width: 15px;
                        text-align: center;
                        .ellipsis-action{
                            width: 4px;
                            fill: #334E68;
                            &:hover{
                                fill: rgba(#334E68, 0.7);
                            }
                        }
                    }
                        padding: 0 !important;
                        border-radius: 6px !important;
                        box-shadow: -1px 4px 8px rgba(0, 0, 0, 0.08);
                        border-color: #CBCBCB !important;
                        overflow: inherit !important;
                        margin-top: 5px !important;
                        left: auto;
                        right: 0;
                        .item {
                            border-bottom: 1px solid rgba($border-color-base, 0.5) !important;
                            display: flex !important;
                            align-items: center;
                            font-weight: 600 !important;
                            color: #1A1642 !important;
                            font-size: 14px;
                            padding: 8px 15px;
                            cursor: pointer;
                            .icon{
                                width: 14px;
                                height: 14px;
                                margin-right: 5px !important;
                                fill: #1A1642 !important;
                            }
                            &:hover{
                                opacity: 0.8;
                                .icon{
                                    fill: $primary-color;
                                }
                            }
                        }
                }
                .details-view{
                    padding-right: 5px;
                    .icon{
                        fill: #C6C6C6;
                    }
                    .button{
                        padding: 0 10px;
                        height: 26px;
                        margin-left: 15px;
                        color: $primary-color;
                        border-color: $primary-color;
                    }
                }
            }
        }
        .productupper-dtl{
            padding: 0 20px;
            .detail-text{
                font-size: $font-size-base - 1;
                .ui.button{
                    padding: 0 !important;
                    border-radius: 0;
                    border: 0 !important;
                    box-shadow: none !important;
                    color: #2166EE !important;
                    font-weight: 600;
                    font-size: 14px;
                }
                p{
                    color: #486581;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 1.8;
                    margin-bottom: 10px;
                }
            }
        }
    }
    .product-bottom{
      /*   background: $white;
        border: 1px solid #B7B7B771; */
        border-radius: 5px;
        margin-top: 0;
        // position: -webkit-sticky;
        // position: sticky;
        top: 0;
        border-top: 0;
        .product-bottom-inner{
            .product-tabes{
                .menu{
                    background: $white;
                    border: 1px solid #B7B7B771;
                    border-width: 1px;
                    padding: 10px 6px 10px 6px;
                    margin-bottom: 15px;
                    border-radius: 0 0 14px 14px;
                    border-top: 0;
                    overflow-x: auto;
                    &::-webkit-scrollbar {
                        display: none !important;
                        height: 0;
                    }
                    &::-webkit-scrollbar-track {
                        background: transparent;
                        height: 0;
                    }
                    &::-webkit-scrollbar-thumb{
                        background: transparent;
                        height: 0;
                    }
                    &::-moz-scrollbar-track {
                        background: transparent;
                        height: 0;
                    }
                    &::-moz-scrollbar-thumb{
                        background: transparent;
                        height: 0;
                    }
                    @media screen and (max-width: 768px) {
                        overflow-x: auto;
                        overflow-y: hidden;
                    }
                    a.item{
                        font-size: $font-size-base !important;
                        margin-right: 0 !important;
                        border-bottom: 2px solid transparent;
                        margin-bottom: 0;
                        position: relative;
                        border: 0;
                        padding: 8px 8px !important;
                        color: #243B53;
                        font-weight: 600;
                        font-family: $inter-font !important;
                        font-size: 12px !important;
                        &.active{
                            color: $primary-color !important;
                            &::after {
                                content: ' ';
                                position: absolute;
                                bottom: -16px;
                                height: 3px;
                                width: calc(100% - 26px);
                                background: $primary-color;
                                border-radius: 3px;
                                display: inline-block;
                                transform: none;
                                left: 0;
                                right: 0;
                                margin: auto;
                                border: 0;
                            }
                        }
                    }
                }
                .feeds-top-section{
                    border: 1px solid #D9D9D9;
                    border-radius: 14px;
                    .post-add{
                        textarea{
                            background: #F6F8FC;
                            border: 1px solid #DBE2EB;
                            border-radius: 8px;
                            font-size: $font-size-sm;
                            color: #829AB1;
                            padding: 15px;
                            overflow: hidden;
                            &::placeholder {
                                color: #829AB1;
                            }
                        }
                    }
                }
                .tab{
                    border: 0;
                    padding: 0;
                    box-shadow: none;
                }
                & .feed-top-section{
                    margin-bottom: 15px;
                    padding:10px 10px 15px 10px;
                    border-bottom: 1px solid $border-color-base;
                    .post-btn{
                        margin-left: 18px;
                        .ui.button{
                            padding: 9px 20px;
                        }
                    }
                    .user-avtar-image{
                        background: #E0E0E0;
                        img{
                            min-width: 104%;
                            min-height: 104%;
                        }
                    }
                    .selectprivacy-drop{
                        .menu{
                            padding: 0;
                            margin: 0;
                        }
                    }
                    .uploads-section{
                        margin-top: 6px;
                        .uploads-btns{
                            display: flex;
                            width: 100%;
                            .btn-sm{
                                color: #2C2C2C;
                                border: 0;
                                box-shadow: none;
                                display: flex;
                                align-items: center;
                                .icon{
                                    color: #2C2C2C;
                                    font-size: 1.2em;
                                }
                            }
                        }
                    }
                }
                & .article{
                    padding: 10px;
                }
                & .supplierslist-wrapper{
                    padding: 10px;
                    background: $white;
                    border-radius: 14px;
                    border-color: #DBE2EB;
                    .supplier-head{
                        padding: 15px 15px 15px 20px;
                        display: flex;
                        justify-content: space-between;
                        background: #F1F9FF;
                        border: 1px solid #2699FB;
                        border-radius: 6px;
                        color: $secondary-color;
                        h3{
                            margin-bottom: 0;
                            color: $secondary-color;
                            font-size: $font-size-base + 2;
                            font-weight: 600;
                        }
                        p{
                            margin-bottom: 0;
                            font-size: $font-size-base - 1;
                        }
                        .right{
                            display: flex;
                            align-items: center;
                        }
                    }
                    .no-suppliers{
                        text-align: center;
                        padding:60px 30px;
                        b{
                            color: $text-color-secondary;
                        }
                        img{
                            max-width: 220px;
                            display: block;
                            margin:15px auto 30px auto;
                        }
                    }
                    .filter-select{
                        color: #818181;
                        background: #F8F8F8;
                        display: flex;
                        border: 1px solid #D1D1D1;
                        border-radius: 3px;
                        font-size: 12px;
                        align-items: center;
                        margin-left: 15px;
                        width: 100%;
                        max-width: 125px;
                        position: relative;
                        .filter {
                            position: absolute;
                            left: 5px;
                        }
                        .ui.selection.dropdown{
                            background: none;
                            appearance: none;
                            -webkit-appearance: none;
                            -moz-appearance: none;
                            border: 0;
                            font-weight: normal;
                            font-size: 12px;
                            height: 22px;
                            min-height: 22px;
                            max-height: 22px;
                            padding-left: 25px;
                            .text{
                                min-height: inherit;
                                line-height: 20px;
                                &::after{
                                    display: none;
                                }
                            }
                            .menu {
                                padding: 0;
                                .item{
                                    margin: 0;
                                    font-weight: normal;
                                    padding: 8px 10px !important;
                                }
                            }
                        }
                    }
                    .supplier-list{
                        .accordion {
                            .title{
                                display: flex;
                                align-items: center;
                                .dropdown{
                                    &.icon{
                                        position: absolute;
                                        right: 10px;
                                        &::before{
                                            content: " ";
                                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13.503' height='23.616' viewBox='0 0 13.503 23.616'%3E%3Cpath id='Icon_ionic-ios-arrow-down' data-name='Icon ionic-ios-arrow-down' d='M17.991,20.679l-8.93-8.937a1.681,1.681,0,0,0-2.384,0,1.7,1.7,0,0,0,0,2.391L16.8,24.258a1.685,1.685,0,0,0,2.327.049L29.312,14.14a1.688,1.688,0,0,0-2.384-2.391Z' transform='translate(-11.247 29.804) rotate(-90)' fill='%23c8c8c8'/%3E%3C/svg%3E") !important;
                                            width: 15px;
                                            height: 15px;
                                            background-repeat: no-repeat no-repeat !important;
                                            background-position: center center !important;
                                            background-size: 7px !important;
                                        }
                                    }
                                }
                            }
                        }
                        .table{
                            tr{
                                td{
                                    padding:8px 3px;
                                }
                                &:nth-child(10n+1){
                                    .supplier-details{
                                        .supplierdetails-circle{
                                            background: #296FB4;
                                        }
                                    }
                                }
                                &:nth-child(10n+2){
                                    .supplier-details{
                                        .supplierdetails-circle{
                                            background: #D08015;
                                        }
                                    }
                                }
                                &:nth-child(10n+3){
                                    .supplier-details{
                                        .supplierdetails-circle{
                                            background: #CF4343;
                                        }
                                    }
                                }
                                &:nth-child(10n+4){
                                    .supplier-details{
                                        .supplierdetails-circle{
                                            background: #DA1F77;
                                        }
                                    }
                                }
                                &:nth-child(10n+5){
                                    .supplier-details{
                                        .supplierdetails-circle{
                                            background: #C0399F;
                                        }
                                    }
                                }
                                &:nth-child(10n+6){
                                    .supplier-details{
                                        .supplierdetails-circle{
                                            background: #6267DE;
                                        }
                                    }
                                }
                                &:nth-child(10n+7){
                                    .supplier-details{
                                        .supplierdetails-circle{
                                            background: #10828F;
                                        }
                                    }
                                }
                                &:nth-child(10n+8){
                                    .supplier-details{
                                        .supplierdetails-circle{
                                            background: #7CA00D;
                                        }
                                    }
                                }
                                &:nth-child(10n+9){
                                    .supplier-details{
                                        .supplierdetails-circle{
                                            background: #925ACF;
                                        }
                                    }
                                }
                                &:nth-child(10n+10){
                                    .supplier-details{
                                        .supplierdetails-circle{
                                            background: #637A88;
                                        }
                                    }
                                }
                            }
                            .supplier-details{
                                line-height: 1;
                                .logo-img{
                                    width: 50px;
                                }
                                .addresses{
                                    font-size: $font-size-base;
                                    color: #486581;
                                    font-weight: 400;
                                }
                                .companyproduct-listname{
                                    display: flex;
                                    align-items: center;
                                }
                                .post-links{
                                    font-style: italic;
                                    padding-left: 15px;
                                    text-decoration: underline;
                                    .icon{
                                        width: 12px;
                                        margin-left: 5px;
                                    }
                                }
                            }
                            .suppliername{
                                margin-bottom: 0;
                                font-size: $font-size-base + 3;
                                color: #334E68;
                                font-weight: 600;
                                &:hover{
                                    color: darken(#334E68, 5);
                                }
                            }
                            .contactlist{
                                display: flex;
                                align-items: center;
                                padding-left: 0;
                                position: relative;
                                min-width: 70px;
                                li{
                                    width: 32px;
                                    height: 32px;
                                    border-radius: 42px;
                                    overflow: hidden;
                                    position: absolute;
                                    border: 1px solid $white;
                                    &:nth-child(1){
                                        right: 0;
                                        z-index: 3;
                                    }
                                    &:nth-child(2){
                                        right: 18px;
                                        z-index: 2;
                                    }
                                    &:nth-child(3){
                                        right: 36px;
                                        z-index: 1;
                                    }
                                    img{
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                    .supplier-head-add{
                        padding: 25px;
                        background: #F1F9FF;
                        border-radius: 6px;
                        color: $secondary-color;
                        position: relative;
                        &::after {
                            content: ' ';
                            position: absolute;
                            right: 0;
                            top: 0;
                            background: #D6EBFF;
                            border-radius: 0 14px 50px 160px;
                            width: 250px;
                            height: 140px;
                            z-index: 0;
                        }
                        .supplier-head-add-inner {
                            position: relative;
                            z-index: 1;
                            .button.primary  {
                                white-space: nowrap;
                                margin-left: 8px;
                            }
                        }
                        h3{
                            margin-bottom: 6px;
                            color: #243B53;
                            font-weight: 700;
                            font-size: $font-size-base + 2;
                        }
                        p{
                            margin-bottom: 0;
                            font-size: $font-size-base - 1;
                            color: #486581;
                        }
                        .addnewcompany-link{
                            font-weight: 500;
                        }
                        .selectsupplier-drop{
                            background: #FAFAFA !important;
                            border: 1px solid #DBE2EB !important;
                            font-size: 12px;
                            height: 37px;
                            border-radius: 6px;
                            max-width: calc(100% - 130px);
                            min-width: auto;
                            input ~ .text{
                                line-height: 33px;
                            }
                            .text{
                                padding-left: 10px;
                                &::after{
                                    display: none;
                                }
                            }
                            .search.icon{
                                padding: 9px;
                                color: #829AB1;
                            }
                            .menu{
                                .item{
                                    border: 0;
                                    padding: 7px !important;
                                }
                            }
                        }
                        .selectedsupplier{
                            padding-top: 6px;
                            .badge{
                                border: 1px solid $primary-color;
                                border-radius: 15px;
                                background: rgba($color: $primary-color, $alpha: 0.2);
                                font-size: 10px;
                                line-height: 10px;
                                cursor: pointer;
                                margin-right: 5px;
                                display: inline-block;
                                margin-bottom: 2px;
                                .badge-inner{
                                    display: block;
                                    padding: 3px 6px;
                                }
                                .icon{
                                    width: 8px;
                                    fill: $primary-color;
                                    margin-left: 4px;
                                }
                                &:hover{
                                    background: rgba($color: $primary-color, $alpha: 0.4);
                                }
                            }
                        }
                    }
                }
                & .rawmaterials-wrapper{
                    padding: 10px;
                    .no-rawmaterials{
                        text-align: center;
                        padding:60px 30px;
                        b{
                            color: $text-color-secondary;
                        }
                        img{
                            max-width: 220px;
                            display: block;
                            margin:15px auto 30px auto;
                        }
                    }
                    .rawmaterials-head{
                        padding: 15px 15px 15px 20px;
                        display: flex;
                        justify-content: space-between;
                        background: #F1F9FF;
                        border: 1px solid #2699FB;
                        border-radius: 6px;
                        color: $secondary-color;
                        h3{
                            margin-bottom: 0;
                            color: $secondary-color;
                            font-size: $font-size-base + 2;
                            font-weight: 600;
                        }
                        p{
                            margin-bottom: 0;
                            font-size: $font-size-base - 1;
                        }
                        .right{
                            display: flex;
                            align-items: center;
                        }
                    }
                    .no-suppliers{
                        text-align: center;
                        padding:60px 30px;
                        b{
                            color: $text-color-secondary;
                        }
                        img{
                            max-width: 220px;
                            display: block;
                            margin:15px auto 30px auto;
                        }
                    }
                }
            }
        }
    }
}

.ui.button{
    &.follow-btn-new{
        background: #BCE0FD;
        color: $secondary-color;
        padding: 4px 10px !important;
        &:hover, &:focus{
            background: #BCE0FD;
            color: $text-color-secondary;
            opacity: 1;
        }
        .icon{
            margin-right: 2px !important;
            fill: $text-color-secondary;
            width: 9px;
        }
    }
    &.btn-follow{
        color: $white;
        display: flex;
        margin-left: auto;
        align-items: center;
        .icon{
            fill: $white;
            margin-right: 2px !important;
        }
    }
    &.btn-following{
        border: 1.5px solid #D9D9D9;
        background: $white;
        color: #707070;
        display: flex;
        align-items: center;
        margin-left: auto;
        .icon{
            fill: #707070;
            height: 14px;
            margin-right: 3px !important;
        }
        &:hover, &:focus{
            border-color: #2699FB;
            color: #2699FB;
            background: $white;
            .icon{
                fill: #2699FB;
            }
        }
    }
}

.createa-supplier{
    .modal-close{
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
    }
    .imgviewer-uploaded{
        text-align: center;
        img{
            max-height: 100px;
            width: auto;
            margin: auto;
        }
    }
    .fileupdated-view{
        position: relative;
        .remove-btn{
            width: 25px;
            height: 25px;
            padding: 0;
        }
    }
    .content{
        padding: 1.95rem !important;
        label{
            color: #818181 !important;
        }
        input{
            border-radius: 4px !important;
            &:focus{
                border-color: $primary-color !important;
            }
        }
        textarea{
            border-radius: 4px !important;
            resize: none !important;
            appearance: none !important;
            &:focus{
                border-color: $primary-color !important;
            }
        }
        .ui.selection.dropdown{
            border-radius: 4px !important;
            border: 1px solid #e4e7ea!important;
            font-weight: normal;
            background-image: none;
            padding: 0 0 0 10px;
            line-height: 33px;
        }
    }
}

.companypeople-list{
    .item{
        padding: 10px 14px !important;
        display: flex !important;
        align-items: center !important;
        justify-content: space-between !important;
        width: 100%;
        border-bottom: 1px solid #DBE2EB;
        .companypeople-left{
            display: flex;
            align-items: center;
            width: 100%;
            h4{
                margin-bottom: 0;
                font-size: 1.25rem;
                color: #334E68;
                font-weight: 600;
            }
            p {
                color: #486581;
                font-weight: 400;
                font-size: 16px;
            }
            .images{
                margin-right: 10px;
                border-radius: 50px;
                overflow: hidden;
                img{
                    width: 48px;
                    height: 48px;
                    margin-right: 8px;
                }
                .circular.label{
                    width: 48px;
                    height: 48px;
                    margin-right: 20x;
                    margin-left: 0 !important;
                    font-size: 1.1rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    &.grey {
                        background: #DBE2EB !important;
                        color: #334E68 !important;
                    }
                }
            }
        }
        .right {
            font-size: 1.15rem;
            padding-top: 15px;
            margin-right: 0;
            span{
                color: #2166EE;
                font-size: 16px;
                font-weight: 600;
            }
        }
        &:last-child{
            border-bottom: 0;
        }
    }
}

.right-action-product-list{
    padding-right: 28px;
    .ui.button{
        display: flex;
    }
    .dropdown.icon{
        right: 0 !important;
        width: 30px !important;
        text-align: right;
        height: 30px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        &::before {
            width: 30px !important;
            height: 30px !important;
        }
    }
}