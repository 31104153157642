@import '../../../assets/scss/variables.scss';

html,
body {
  overscroll-behavior: none;
}

.add-post-modal {
  width: 420px !important;

  .post-elements-row {
    a {
      display: flex;
      align-items: center;
      font-size: 11px;
      font-weight: 600;

      .icon {
        max-height: 12px;
        margin-right: 4px;
      }
    }
  }
}

.feeds-wrapper {
  .feeds-top-section {
    background: $white;
    border: 1px solid #CBCBCB;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 10px 7px;
    justify-content: space-around;

    .user-avatar {
      width: 48px;
      height: 48px;
      min-width: 48px;
      border-radius: 48px;
      overflow: hidden;
      display: flex;
      align-items: center;

      img {
        width: 100%;
      }
    }

    .post-add {
      width: 75%;

      @media screen and (max-width:480px) {
        margin-right: 10px;
        margin-left: 8px;
      }

      textarea {
        height: 46px;
        resize: none;
        background: #F8F8F8;
      }
    }
  }

  .feeds-container {
    .event {
      background: $white;
      border: 1px solid #DBE2EB !important;
      border-radius: 14px !important;
      margin-top: 15px !important;
      padding: 15px !important;
      display: block;

      .event-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;

        .event-head-left {
          display: flex;

          .label {
            width: 48px;
            height: 48px;
            border: 48px;
            line-height: 48px;
            min-width: 48px;
            overflow: hidden;
            margin-right: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #19153F;
            color: #FFFFFF;
            font-size: 1.2rem;
            font-weight: 500;
            font-family: $inter-font;

            img {
              border-radius: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .header-dtl {
            b {
              font-weight: 600;
              font-size: 18px;
              margin-bottom: 5px;

              @media screen and (max-width:640px) {
                font-size: 17px;
              }

              a {
                color: #243B53;
              }
            }

            p {
              font-size: 13px;

              @media screen and (max-width:640px) {
                font-size: 12px;
              }

              .icon {
                width: 13px;
                margin-left: 5px;
              }
            }
          }
        }
      }

      .content {
        .summary {
          font-weight: normal;
          margin-top: 8px;
        }

        .images.extra {
          img {
            width: 100%;
          }
        }

        .meta {
          width: 100%;
        }

        .event-footer {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-top: 1px solid $border-color-base;
          padding-top: 15px;

          .event-footer-left {}

          .event-footer-right {}
        }
      }
    }
  }
}

.addarticle-top {
  background: $white;
  border: 1px solid #DBE2EB;
  padding: 16px;
  font-family: $inter-font;
  border-radius: 14px;
  margin-bottom: 0;

  @media screen and (max-width:576px) {
    padding: 15px;
  }

  .upper-section {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    .left-avatar {
      width: 52px;
      height: 52px;
      min-width: 52px;
      border-radius: 52px;
      background: #E9EEF3;
    }

    textarea {
      border: 1px solid #DBE2EB;
      border-radius: 8px;
      background: #F6F8FC;
      color: #829AB1;
      width: 100%;
      margin-left: 0;
      resize: none;
      outline: none;
      font-weight: 500;
      font-family: $inter-font;
      font-size: 14px;
      padding: 15px;
      height: 52px;

      &::placeholder {
        color: #829AB1;
      }

      @media screen and (max-width:767px) {
        font-size: 12px;
        padding: 10px;
        line-height: 2;
      }
    }
  }

  .bottom-section {
    padding-left: 68px;
    display: flex;
    align-items: center;

    @media screen and (max-width:767px) {
      padding-left: 0;
      width: 100%;
    }

    .button {
      background: none;
      font-family: $inter-font;
      font-weight: 600;
      color: #243B53;
      font-size: 13px;
      display: flex;
      align-items: center;
      padding: 0;
      margin-right: 3rem;

      @media screen and (max-width:767px) {
        font-size: 11px;
      }

      @media screen and (max-width:576px) {
        margin-right: 10px;
      }

      &:last-child {
        margin-right: 0;
      }

      .icon {
        fill: #243B53;
        margin-right: 7px;
        width: 15px;
        height: 15px;

        @media screen and (max-width:576px) {
          margin-right: 5px;
          width: 14px;
          height: 14px;
        }
      }
    }
  }
}

.add-post-modal-new {
  border: 1px solid #DBE2EB;
  border-radius: 14px !important;
  max-width: 660 !important;

  * {
    font-family: $inter-font !important;
  }

  .header {
    border-bottom: 1px solid #DBE2EB;
    font-size: 20px;
    border-top-left-radius: 14px !important;
    border-top-right-radius: 14px !important;
    color: #243B53 !important;
    padding: 1.45rem 1.5rem;
    display: flex !important;
    align-items: center;
    justify-content: space-between;

    .close-icon {
      fill: #334E68;
      width: 14px;
      cursor: pointer;
      margin: 0 !important;
      opacity: 1;
    }
  }

  .description {
    .user-avtar-image {
      width: 52px;
      height: 52px;
      border-radius: 52px;
      margin-right: 12px !important;

      img {
        min-width: 100%;
        min-height: 100%;
        object-fit: cover;
      }
    }

    .profile-name {
      margin-bottom: 0;
      font-size: 18px;
      font-weight: 600;
      color: #243B53;
    }
  }

  .fileupdated-view {
    position: relative;
    margin-top: 1rem;

    .input-type-file i {
      font-family: 'mappes-icon' !important;
    }

    .remove-btn {
      width: 30px;
      height: 30px;
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      text-align: center;
      position: absolute;
      z-index: 3;
      right: 18px;
      top: 18px;
      background: $primary-color;
      transition: all ease-in 0.1s;

      &:hover {
        opacity: 0.8;
      }

      .icon {
        margin: auto !important;
        fill: $white;
        opacity: 1;
      }
    }

    .imgviewer-uploaded {
      max-height: 230px;
      overflow: hidden;

      img {
        max-height: 230px;
        max-width: 100%;
        width: auto;
        margin: auto;
        object-fit: fill;
      }
    }

    .pdfviewer-uploaded {
      max-height: 230px;
      overflow-y: scroll;
      overflow-x: hidden;

      .PDFView__container__document {
        max-height: initial;
      }
    }
  }

  .content {
    border-bottom-left-radius: 14px !important;
    border-bottom-right-radius: 14px !important;
    padding: 0 !important;

    .modal-upper {
      padding: 24px 24px 20px 24px;
    }

    .field {
      .wrapper {
        .mentionable {
          color: #829AB1;
          border: 0;
          outline: none;
          box-shadow: none;
          padding: 0;

          &::before {
            color: #829AB1;
            font-size: 16px;
            font-weight: 500;
          }
        }

      }
    }

    .bottom-text {
      font-size: 12px;
      color: #829AB1;
      font-weight: 400;
      margin-bottom: 0;
    }

    .footer {
      border-top: 1px solid #DBE2EB;
      padding: 12px 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left-footer {
        display: flex;
        align-items: center;

        .button {
          padding: 5px 10px;
          background: none;

          .icon {
            fill: #334E68;
            width: 25px;
            height: 20px;
            margin: 0;
            opacity: 1;
          }
        }

        .smile-icon {
          border-right: 1px solid #DBE2EB;
          padding-right: 15px;
          margin-right: 15px;
          display: flex;
          align-items: center;

          .button {
            padding: 0;
          }

          .icon {
            width: 23px;
            height: 23px;
            fill: #334E68;
          }
        }
      }

      .post-btn {
        border-radius: 6px;
        font-weight: 600;
        padding: 14px 26px;
        margin: 0;
      }
    }
  }
}

.modals {
  &.inverted {
    overflow: hidden !important;
  }
}

.ui.modals.dimmer .ui.modal.addpost-mobile {
  min-height: 100vh;
  min-width: 100vw;
  top: 0;
  position: fixed !important;
  margin: 0 !important;
  left: 0;

  .header.modal-header {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    min-height: 60px;
    border-bottom: 1px solid #E5E8F0;
    padding: 5px 12px !important;

    .header-left {
      color: #0D1A35;
      display: flex;
      align-items: center;
      font-family: $inter-font;

      .icon {
        fill: #334E68;
        margin-right: 10px;
        width: 14px;
      }
    }

    .header-right {
      display: flex;
      align-items: center;

      .menu {
        right: 0;
        left: auto;
        background: #FFFFFF;
        border: 1px solid #DBE2EB;
        box-shadow: -1px 4px 8px rgba(0, 0, 0, 0.08);
        border-radius: 7px;

        .item {
          color: #334E68;
          display: flex;
          align-items: center;
          font-family: $inter-font;
          border-bottom: 1px solid #DBE2EB;
          padding: 14px !important;
          font-size: 15px;
          font-weight: 600;

          &:last-child {
            border-bottom: 0;
          }

          .icon {
            fill: #334E68;
            width: 18px;
          }
        }
      }

      .dropdown {
        display: flex;
        align-items: center;
      }

      .headercamera-icon {
        width: 26px;
        fill: #334E68;
        margin-right: 14px;
      }

      .post-btn {
        background: $white;
        border: 1px solid #DBE2EB;
        color: #486581;
        border-radius: 5px;
        height: 32px;
        padding: 0 15px;

        &.active {
          border-color: #2166EE;
          color: #2166EE;
        }
      }
    }
  }

  .content {
    overflow-y: auto;

    .modal-upper {
      .field {
        .mentionable {
          background: #F6F8FC;
          border: 1px solid #DBE2EB;
          border-radius: 6px;
          padding: 10px;
          font-size: 14px;
          min-height: 140px;

          &::before {
            color: #829AB1;
            font-size: 14px;
          }
        }
      }

      .bottom-text {
        color: #829AB1;
        font-family: $inter-font;
        font-size: 13px;

        span {
          color: #334E68;
          font-weight: 700;
        }
      }

      .files-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 15px;

        &.onlyone-item {
          grid-template-columns: repeat(1, 1fr);
        }

        &.onlytwo-item {
          grid-template-columns: repeat(2, 1fr);
        }

        .filelist-item {
          border-radius: 4px;
          overflow: hidden;
          position: relative;
          margin-top: 0.4rem;
          box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.11);

          .close-btn {
            background: $white;
            width: 24px;
            height: 24px;
            border-radius: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 6px;
            right: 6px;
            z-index: 1;
            border: 0;
            cursor: pointer;

            .icon {
              fill: #334E68;
              width: 9px;
            }

            &:hover {
              background: $error-color;

              .icon {
                fill: $white;
              }
            }
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: fill;
          }
        }

        .uploading-row {
          font-size: 12px;
          text-align: center;
          font-family: $inter-font;
          color: #486581;

          .progress {
            margin: 0.2rem auto 0;

            .bar {
              background: #2166EE;
            }
          }
        }
      }
    }
  }


}