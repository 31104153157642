@import '../../../assets/scss/variables.scss';

.signup-wrapper {
    display: flex;
    min-height: 100vh;

    // margin-top: -60px;
    @media screen and (max-width:767px) {
        display: block;
    }

    .signup-left {
        width: 48%;
        position: relative;
        background: url("../../../assets/images/signupbg.jpg");
        background-size: cover;

        @media screen and (max-width:767px) {
            width: 100%;
        }

        .signupleft-inner {
            padding: 15%;

            .icon {
                width: 280px;
                fill: $secondary-color;

                @media screen and (max-width:767px) {
                    width: 230px;
                }
            }

            h2 {
                transform: translateY(40vh);
                color: $blue-dark;
                font-size: 2.5rem;
                font-weight: 600;

                @media screen and (max-width:767px) {
                    transform: translateY(5vh);
                    font-size: 1.8rem;
                }
            }
        }
    }

    .signup-right {
        width: 52%;
        display: flex;

        @media screen and (max-width:767px) {
            width: 100%;
        }

        .signupright-inner {
            max-width: 440px;
            width: 100%;
            margin: auto;
            padding-top: 6%;
            padding-bottom: 3%;

            @media screen and (max-width:1377px) {
                padding-top: 4%;
            }

            @media screen and (max-width:767px) {
                padding: 15px;
            }

            .emailinfo-text {
                font-size: 13px;
                margin-bottom: 25px;
                font-weight: 500;
                text-align: center;
            }

            .by-clickingtext {
                text-align: center;
                font-size: 14px;
                max-width: 320px;
                margin: auto auto 15px auto;
            }

            &.messge-thanku {
                padding-top: 0;
                padding-bottom: 0;
                height: 100%;
                display: flex;
                align-items: center;
                max-width: 455px;

                .title {
                    h3 {
                        font-size: 32px;
                    }

                    p {
                        font-size: 22px;
                    }
                }

                .button {
                    font-size: 16px;
                }

                @media screen and (max-width:767px) {
                    padding-top: 30px;
                }
            }

            .title {
                h3 {
                    color: black;
                    font-size: 1.68rem;
                    font-weight: 600;

                    @media screen and (max-width:1377px) {
                        font-size: 1.28rem;
                    }
                }

                p {
                    font-size: 1.35rem;

                    @media screen and (max-width:1377px) {
                        font-size: 1.08rem;
                    }
                }
            }

            .password-strength {
                display: grid;
                grid-template-columns: auto auto auto auto auto;
                list-style-type: none;
                grid-gap: 0 6px;
                padding-left: 0;
                margin-bottom: 5px;

                li {
                    height: 5px;
                    background: #e6e6e6;
                }
            }

            .yellow_warning {
                color: #C18A1C !important;
                text-align: center;
                margin-bottom: 5px;

            }
        }
    }
}